import { Box, Grid, Typography } from "@mui/material";
import React from "react";

// Define a type for the data prop
interface PanelData {
  id: string;
  icon: React.ReactNode;
  title: string;
  value: string;
  description: string;
}

interface OverviewPanelProps {
  data: PanelData[]; // Use the defined type for the data prop
}

export const OverviewPanel: React.FC<OverviewPanelProps> = ({ data }) => {
  return (
    <Box sx={{ display: "flex", flexGrow: 1, marginBottom: "30px" }}>
      <Grid container spacing={4} margin={0}>
        {data.map((panelData, index) => (
          <Grid key={panelData.id} item xs={3}>
            <Box className="overViewPanel" id={panelData.id}>
              <Typography marginBottom={"40px"} variant="h6" component={"h4"}>
                {panelData.icon} {panelData.title}
              </Typography>
              <Typography
                margin="10"
                variant="h5"
                component={"h3"}
                sx={{
                  fontWeight: "bold",
                }}
              >
                {panelData.value}
              </Typography>
              <Typography
                variant="body2"
                component={"p"}
                sx={{
                  color: "#757575",
                  fontSize: "12px",
                }}
              >
                {panelData.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
