import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Paper, Grid, } from '@mui/material';
import ResponsiveDrawer from '../../SharedComponent/Sidebar/ResponsiveDrawer';
import Header from '../../SharedComponent/Header/Header';
import { ProductHistoryOverview } from './ProductHistoryOverview';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const ProductHistory = () => {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <ResponsiveDrawer />
          </Grid>
          <Grid item xs={10}>
            <Header />
            <Box className={"MainBoxComponent"}>
              <ProductHistoryOverview />
              
            </Box>
          </Grid>

        </Grid>
      </Box>
    </>
  )
}
