import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  FormGroup,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import { Container, Grid, Typography } from "@mui/material";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import SharedButton from "../../SharedComponent/Button/SharedButton";
import hero_img from "../../Assets/assets/dashboard.png";
import hero_svg from "../../Assets/assets/hero-svg.png";
import hero_gif from "../../Assets/assets/join-image.png";
import { moveToTop } from "../../utils/utils";

const JoinThePlychain = () => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate("/request-demo");
    moveToTop();
  };
  return (
    <Box
      sx={{
        position: "relative",
        marginBottom: { xs: "0", sm: "50px", md: "100px", lg: "250px" },
        paddingX: "24px",
        border: "1px solid transparent",
        marginTop: { xs: "100px" },
      }}
    >
      <Grid
        container
        spacing={0}
        // spacing={10}
        sx={{
          alignItems: "start",
          marginTop: {
            xs: "60px",
            sm: "",
            md: "60px",
          },
          // border: "1px solid red",
          maxWidth: "1440px",
          marginX: "auto",
        }}
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            textAlign: "left",
            // border: "1px solid red",
            p: "0",
            marginTop: {
              xs: "600px",
              md: "100px",
            },
          }}
        >
          <Typography
            variant="h1"
            component={"h2"}
            sx={{
              fontFamily: "Nexa",
              color: "#0B1E5B",
              textAlign: {
                xs: "center",
                sm: "left",
                md: "left",
              },
              fontSize: {
                xs: "36px",
                sm: "48px",
                md: "3.5rem",
              },
              // border: "1px solid blue",
            }}
          >
            Join the PlyChain Revolution
          </Typography>
          <Typography
            // textAlign={"left"}
            variant="body1"
            component={"h5"}
            marginTop={2}
            sx={{
              color: "#0B1E5B",
              width: {
                xs: "100%",
                sm: "75%",
                md: "75%",
              },
              fontSize: { xs: "16px", sm: "18px", md: "20px" },
              textAlign: {
                xs: "center",
                sm: "left",
              },
            }}
          >
            Are you ready to transform your supply chain into a beacon of
            transparency and trust? Join PlyChain today and take the first step
            towards a brighter, more sustainable future.
          </Typography>

          <SharedButton
            onClick={handleOnClick}
            className="LandingBtnHover"
            value={"Request for demo"}
            variant="contained"
            sx={{
              textAlign: "left",
              marginTop: {
                xs: "30px",
                md: 2,
              },
              width: {
                xs: "100%",
                sm: "50%",
                md: "50%",
              },
              maxWidth: "400px",
              fontFamily: "Inter",
              fontSize: { xs: "14px", md: "16px" },
              textTransform: "none",
              // marginX: "auto",
            }}
          />
        </Grid>
        <Box
          sx={{
            // border: "1px solid green",
            position: "absolute",

            width: { xs: "90%", sm: "80%", md: "45%" },
            height: "500px",
            right: 0,
            // right: { xs: "0", xl: "1%" },
          }}
        >
          <Box
            sx={{
              // border: "1px solid brown",
              width: "100%",
              height: "100%",
              position: "relative",
              zIndex: "2",
            }}
          >
            <img
              src={hero_img}
              alt=""
              style={{
                width: "100%",
                objectFit: "cover",
                height: "100%",
                borderRadius: "24px 0 0 0",
              }}
            ></img>
          </Box>
          {/* <Box
            sx={{
              // border: "1px solid black",
              position: "absolute",
              right: "0",
              top: "0",
              height: "100%",
              marginLeft: { xs: "5%", xl: "3%" },
              paddingTop: { xs: "5%", xl: "3%" },
              zIndex: "2",
              width: { xs: "95%", xl: "97%" },
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "12px 0 0 0",
              }}
              src={hero_gif}
              alt=""
            ></img>
          </Box> */}
          <Box
            sx={{
              // border: "1px solid green",
              position: "absolute",
              bottom: "-15%",
              left: "-10%",
              zIndex: "1",
              width: "70%",
            }}
          >
            <img
              src={hero_svg}
              alt=""
              style={{
                width: "100%",
              }}
            ></img>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default JoinThePlychain;
