import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  FormGroup,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import { Container, Grid, Typography } from "@mui/material";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import purple_top from "../../Assets/assets/purple-top.svg";
import purple_bottom from "../../Assets/assets/purple-bottom.svg";

const LoadingPage = () => {
  const [show, setShow] = useState(false);
  const [percent, setPercent] = useState(0);

  // setTimeout(() => {
  //   setShow(true);
  //   console.log(show);
  // }, 5000);
  let percentage = 0;
  useEffect(() => {
    // Store the interval ID to be able to clear it later
    const intervalId = setInterval(() => {
      setPercent((x) => x + 20);
      // percentage = percentage + 20;
      // setPercent(percentage);
      console.log(percent); // You can do something with the updated percentage here
    }, 1000);

    // If you want to stop the interval after a certain time (e.g., 5 seconds)
    setTimeout(() => {
      clearInterval(intervalId);
    }, 5500);

    // Cleanup the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <Box
      sx={{
        position: "relative",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingX: "16px",
      }}
    >
      {/* images */}
      <Box
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          height: { xs: "50%", sm: "60%", md: "70%" },
        }}
      >
        <img
          src={purple_top}
          alt=""
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        ></img>
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: "0",
          right: "0",
          height: { xs: "50%", sm: "70%", md: "100%" },
        }}
      >
        <img
          src={purple_bottom}
          alt=""
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        ></img>
      </Box>

      {/* main */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "51px",
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* logo */}
        <Box
          sx={{
            width: {
              xs: "250px",
              sm: "348px",
            },
            // marginX: "auto",

            // border: "1px solid blue",
          }}
        >
          <img
            src="./Assets/Image/MainLogo.png"
            alt=""
            className="footerLogo"
            style={{
              width: "100%",
              objectFit: "cover",
            }}
          />
        </Box>

        {/* loader */}
        <Box
          sx={{
            background: "#E3DDFB",
            borderRadius: "100px",
            height: "8px",
            width: "100%",
            maxWidth: { sm: "500px" },
          }}
        >
          {percent === 0 && (
            <Box
              sx={{
                height: "100%",
                background: "#8E75F0",
                width: "0%",
                borderRadius: "100px",
              }}
            ></Box>
          )}
          {percent === 20 && (
            <Box
              sx={{
                height: "100%",
                background: "#8E75F0",
                width: "20%",
                borderRadius: "100px",
              }}
            ></Box>
          )}
          {percent === 40 && (
            <Box
              sx={{
                height: "100%",
                background: "#8E75F0",
                width: "40%",
                borderRadius: "100px",
              }}
            ></Box>
          )}
          {percent === 60 && (
            <Box
              sx={{
                height: "100%",
                background: "#8E75F0",
                width: "60%",
                borderRadius: "100px",
              }}
            ></Box>
          )}
          {percent === 80 && (
            <Box
              sx={{
                height: "100%",
                background: "#8E75F0",
                width: "80%",
                borderRadius: "100px",
              }}
            ></Box>
          )}
          {percent === 100 && (
            <Box
              sx={{
                height: "100%",
                background: "#8E75F0",
                width: "100%",
                borderRadius: "100px",
              }}
            ></Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LoadingPage;
