import React, { useState } from "react";
import { Container, Grid, Paper } from "@mui/material";

interface ImageSliderProps {
  imagesUrl: string[];
}

const ImageSlider = (props: ImageSliderProps) => {
  const [selectedImage, setSelectedImage] = useState(0);

  const handleImageClick = (index) => {
    setSelectedImage(index);
  };

  return (
    <Container>
      <Paper elevation={0} sx={{
        borderRadius:'20px', marginBottom:1
      }}>
        <img
          src={props.imagesUrl[selectedImage]} // Replace with your image paths
          alt="Big Image"
          style={{ width: "100%", maxHeight: 400, objectFit: "cover" }}
        />
      </Paper>
      <Grid container spacing={2}>
        {props.imagesUrl.map((v, index) => (
          <Grid item key={index} xs={6} sm={3}>
            <Paper
              elevation={0}
              onClick={() => handleImageClick(index)}
              style={{ cursor: "pointer" }}
              sx={{
                borderRadius:'10px', marginBottom:1
              }}
            >
              <img
                src={v} // Replace with your image paths
                alt={`Small Image ${index + 1}`}
                style={{ width: "100%", maxHeight: 200, objectFit: "cover" }}
              />
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ImageSlider;
