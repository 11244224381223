import { Reducer, Slice, createSlice } from "@reduxjs/toolkit";
import { AuthModel } from "../models/authModel";

const initialState: {
  isLoggedIn: boolean;
  authData: AuthModel;
} = {
  isLoggedIn: localStorage.getItem("login") === "1",
  authData: localStorage.getItem("authData")
    ? JSON.parse(localStorage.getItem("authData"))
    : undefined,
};

const AuthSlice: Slice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    setLoginState(state, action) {
      state.isLoggedIn = action.payload;
    },
    setAuthData(state, action) {
      state.authData = action.payload;
    },
  },
});

const authActions = AuthSlice.actions;
const authReducer: Reducer<any> = AuthSlice.reducer;

export { authActions, authReducer };
