import React, { useState } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import "./App.css";
// import "./Style.scss";
import "./Assets/Css/Style.css";
import { SignUp } from "./PublicPage/SignUp/SignUp";
import { Dashboard } from "./PrivatePage/DashboardPages/Dashboard";
import { Products } from "./PrivatePage/ProductPage/Products";
import { CreateUpdateProduct } from "./PrivatePage/ProductPage/CreateUpdateProduct";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "./store/auth";
import { AuthModel } from "./models/authModel";
import { ProductDetail } from "./PrivatePage/ProductPage/ProductDetail";
import { ProductHistoryOverview } from "./PrivatePage/ProductPage/ProductHistoryOverview";
import { ProductHistory } from "./PrivatePage/ProductPage/ProductHistory";
import { Shipments } from "./PrivatePage/ShipmentsPage/Shipments";
import { CreateUpdateShipment } from "./PrivatePage/ShipmentsPage/CreateUpdateShipment";
import { ShipmentDetailPage } from "./PrivatePage/ShipmentsPage/ShipmentDetailPage";
import { Profile } from "./PrivatePage/ProfilePage/Profile";
import { LandingPage } from "./PublicPage/LandingPage/LandingPage";
import FileViewer from "./PrivatePage/FileViewerPage/FileViewer";
import Product from "./PublicPage/ProductDetail/Product";
import { AboutUs } from "./PublicPage/LandingPage/AboutUs";
import { GetInTouch } from "./PublicPage/LandingPage/GetInTouch";
import { ChoosePlan } from "./PublicPage/ChoosePlan/ChoosePlan";
import { Settings } from "./PrivatePage/Settings/Settings";
import { RequestDemo } from "./PublicPage/LandingPage/RequestDemo";
import TermsOfUse from "./PublicPage/LandingPage/TermsOfUse";
import TermsAndConditions from "./PublicPage/LandingPage/TermsAndConditions";
import PrivacyPolicy from "./PublicPage/LandingPage/PrivacyPolicy";
import LoadingPage from "./PublicPage/LandingPage/LoadingPage";
// import { useSDK } from "@metamask/sdk-react";
// import Dashboard from './PlayChain/PrivatePage/DashboardPages/Dashboard';

const App = () => {
  const isFirstTime = localStorage.getItem("isFirstTime");
  const [loading, setLoading] = useState(isFirstTime ? false : true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // @ts-ignore
  const isLoggedIn: boolean = useSelector((state) => state.auth.isLoggedIn);

  // @ts-ignore
  const authData: AuthModel = useSelector((state) => state.auth.authData);

  // const { connected } = useSDK();
  useEffect(() => {
    if (loading)
      setTimeout(() => {
        setLoading(false);
        localStorage.setItem("isFirstTime", "true");
      }, 5500);
  }, []);
  useEffect(() => {}, [loading]);

  useEffect(() => {
    const handleAccountChange = (accounts) => {
      // @ts-ignore
      if (accounts?.length === 0) {
        // MetaMask is locked or the user has not connected any accounts.
        if (isLoggedIn === true) {
          // logout & navigate to connect wallet screen
          logoutUser();
        }
      } else if (
        authData &&
        authData.wallet_address &&
        accounts?.[0] !== authData.wallet_address
      ) {
        // logout & navigate to connect wallet screen
        logoutUser();
      }
    };

    const handleChainChange = async (chainId) => {
      // window.location.reload();

      if (isLoggedIn === true) {
        await getCurrentNetworkId();
      }
    };

    const addPolygonMainnet = async () => {
      try {
        const response = await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: "0x89",
              chainName: "Polygon Mainnet",
              rpcUrls: ["https://polygon-rpc.com/"],
              nativeCurrency: {
                symbol: "MATIC",
                decimals: 18,
              },
              blockExplorerUrls: ["https://polygonscan.com/"],
            },
          ],
        });

        if (response === "null") {
          // user successfully added polygon network
          // switch to polygon network
          await switchToPolygonMainnet();
        }
      } catch (error) {
        // console.log(`ERROR :: addPolygonMainnet :: ${error}`);
      }
    };

    const switchToPolygonMainnet = async () => {
      try {
        const response = await window?.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: "0x89",
            },
          ],
        });

        // if (typeof response === "object") {
        //   if (Object.hasOwn(response, "code") && response["code"] === "4902") {
        //     // user does not have polygon mainnet in metamask wallet
        //     // request to add network
        //     await addPolygonMainnet();
        //   }
        // }
      } catch (error) {
        // console.log(
        //   `ERROR :: switchToPolygonMainnet :: ${JSON.stringify(error)}`
        // );

        if (Object.hasOwn(error, "code") && error["code"] == 4902) {
          // user does not have polygon mainnet in metamask wallet
          // request to add network
          await addPolygonMainnet();
        }
      }
    };

    const getCurrentNetworkId = async () => {
      try {
        const response = await window?.ethereum.request({
          method: "eth_chainId",
          params: [],
        });

        if (response !== "0x89") {
          if (isLoggedIn === true) {
            // logout & navigate to connect wallet screen
            logoutUser();
          }
          // user is not on polygon mainnet
          // switch network
          await switchToPolygonMainnet();
        }
      } catch (error) {
        // console.log(`ERROR :: getCurrentNetworkId :: ${error}`);
      }
    };

    if (window.ethereum) {
      getCurrentNetworkId();
      window?.ethereum?.on("accountsChanged", handleAccountChange);
      window?.ethereum?.on("disconnect", () => {
        logoutUser();
      });
      window?.ethereum?.on("chainChanged", handleChainChange);

      return () => {
        window?.ethereum?.removeListener(
          "accountsChanged",
          handleAccountChange
        );
        window?.ethereum?.removeListener("disconnect", logoutUser);
        window?.ethereum?.removeListener("chainChanged", handleChainChange);
      };
    }
  }, [authData, isLoggedIn]);

  const logoutUser = () => {
    localStorage.clear();
    localStorage.setItem("login", "0");

    // save login state and data in redux store
    dispatch(authActions.setLoginState(false));
    const authData: AuthModel = undefined;
    dispatch(authActions.setAuthData(authData));

    navigate("/", { replace: true });
  };

  return (
    <div className="App">
      {loading ? (
        <LoadingPage />
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              isLoggedIn === true ? (
                <Navigate to={"/dashboard"} />
              ) : (
                <LandingPage />
              )
            }
          />
          <Route
            path="/signup"
            element={
              isLoggedIn === true ? <Navigate to={"/dashboard"} /> : <SignUp />
            }
          />
          <Route path="/product" element={<Product />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contactus" element={<GetInTouch />} />
          <Route path="/chooseplan" element={<ChoosePlan />} />
          <Route path="/settings" element={<Settings />} />
          <Route
            path="/dashboard"
            element={
              isLoggedIn === true ? (
                <Dashboard />
              ) : (
                <Navigate to={"/"}></Navigate>
              )
            }
          />
          <Route
            path="/products"
            element={
              isLoggedIn === true ? (
                <Products />
              ) : (
                <Navigate to={"/"}></Navigate>
              )
            }
          />
          <Route
            path="/createProduct"
            element={
              isLoggedIn === true ? (
                <CreateUpdateProduct />
              ) : (
                <Navigate to={"/"}></Navigate>
              )
            }
          />
          <Route
            path="/updateProduct"
            element={
              isLoggedIn === true ? (
                <CreateUpdateProduct />
              ) : (
                <Navigate to={"/"}></Navigate>
              )
            }
          />
          <Route path="/productDetail" element={<ProductDetail />} />
          <Route path="/producthistory" element={<ProductHistory />} />
          <Route
            path="/producthistoryoverview"
            element={<ProductHistoryOverview />}
          />
          <Route
            path="/shipments"
            element={
              isLoggedIn === true ? (
                <Shipments />
              ) : (
                <Navigate to={"/"}></Navigate>
              )
            }
          />
          <Route
            path="/createShipment"
            element={
              isLoggedIn === true ? (
                <CreateUpdateShipment />
              ) : (
                <Navigate to={"/"}></Navigate>
              )
            }
          />
          <Route
            path="/updateShipment"
            element={
              isLoggedIn === true ? (
                <CreateUpdateShipment />
              ) : (
                <Navigate to={"/"}></Navigate>
              )
            }
          />
          <Route
            path="/shipmentDetail"
            element={
              isLoggedIn === true ? (
                <ShipmentDetailPage />
              ) : (
                <Navigate to={"/"}></Navigate>
              )
            }
          />
          <Route
            path="/profile"
            element={
              isLoggedIn === true ? <Profile /> : <Navigate to={"/"}></Navigate>
            }
          />
          <Route
            path="/fileViewer"
            element={
              isLoggedIn === true ? (
                <FileViewer />
              ) : (
                <Navigate to={"/"}></Navigate>
              )
            }
          />
          <Route path="/request-demo" element={<RequestDemo />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      )}
      {/* <LoadingPage /> */}
    </div>
  );
};

export default App;
