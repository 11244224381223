import React, { useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  FormGroup,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import { Container, Grid, Typography } from "@mui/material";
import SharedButton from "../../SharedComponent/Button/SharedButton";
import InstallNumbers from "./InstallNumbers";
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';

const HowToGetStarted = (props: any) => {
  const { onClick } = props;
  const ref = useRef<HTMLDivElement>(null);
  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  const screenWidth = window.screen.width;

  var top = ref.current ? ref.current.offsetTop : 1094;

  console.log("screen width", window.screen.width);

  // get windows onscroll event
  window.addEventListener("scroll", (e) => {
    if (screenWidth > 600) {
      // console.log("top", top);
      // console.log("scroll-y", window.scrollY);
      if (ref1.current && top && ref2.current) {
        if (window.scrollY >= top - 200) {
          let count = window.scrollY - (top - 200);
          // console.log("count", count);
          if (count > 0 && count < 100) {
            ref1.current.style.height = `${count}%`;
            ref2.current.style.height = `0%`;
          } else if (count > 100 && count < 200) {
            ref2.current.style.height = `${count - 100}%`;
            ref1.current.style.height = `100%`;
          } else if (count > 200) {
            ref2.current.style.height = `100%`;
            ref1.current.style.height = `100%`;
          }
        }
      }
    } else {
      if (ref1.current && top && ref2.current) {
        if (window.scrollY >= top + 200) {
          let count = window.scrollY - (top + 200);
          console.log("count", count);
          if (count > 0 && count < 100) {
            ref1.current.style.height = `${count}%`;
            ref2.current.style.height = `0%`;
          } else if (count > 100 && count < 200) {
            ref2.current.style.height = `${count - 100}%`;
            ref1.current.style.height = `100%`;
          } else if (count > 200) {
            ref2.current.style.height = `100%`;
            ref1.current.style.height = `100%`;
          }
        }
      }
    }
  });
  return (
    <div className="pt-5"   id="howToGetStartSection" style={{
      paddingTop:'30px'
    }}>
    <Box
      marginTop={10}
    
      sx={{
        // border: "1px solid red",
        paddingX: "24px",
      }}
    >
      <Grid
        container
        // columnSpacing={1}
        // spacing={1}
        alignItems={"start"}
        sx={{
          // border: "1px solid blue",
          maxWidth: "1440px",
          marginX: "auto",
          // gap: "10px",
          // columnGap: "10px",
        }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          md={6}
          lg={6}
          sx={{
            textAlign: { xs: "center", sm: "start" },
            // alignItems:'start'
            // border: "1px solid green",
          }}
        >
          <Typography
            variant="h2"
            component={"h2"}
            sx={{
              color: "#0B1E5B",
              fontWeight: "bold",
              fontSize: {
                xs: "30px",
                sm: "48px",
                md: "60px",
                // lg:"60px"
              },
              maxWidth: "520px",
              marginBottom: "32px",
              fontFamily: "Nexa",
            }}
          >
            How to Get Started
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          lg={6}
          sx={{
            // border: "1px solid blue",
            paddingLeft: "10px ",
            display: "flex",
            gap: {
              xs: "12px",
              sm: "28px",
            },
          }}
        >
          <InstallNumbers ref1={ref1} ref2={ref2} />
          <Grid
            container
            direction={"column"}
            // spacing={{
            //   xs: 1,
            //   sm: 5,
            //   md: 5,
            // }}
            textAlign={"left"}
            sx={{
              // border: "1px solid red",
              paddingTop: "24px",
              color: "#0b1e5b",
            }}
          >
            <Grid
              item
              component={"div"}
              sx={{
                marginLeft: "0",
                // border: "1px solid green",
              }}
            >
              <img src="./Assets/Image/Metamask2logo.png" alt="" />
              <Typography
                variant="h4"
                component={"h2"}
                marginTop={1}
                sx={{
                  fontWeight: "bold",
                  color: "#0B1E5B",
                  fontSize: {
                    xs: "20px",

                    sm: "24px",
                  },
                  fontFamily: "Inter",
                }}
              >
                Download and Install Metamask
              </Typography>
              <Typography
                variant="body2"
                marginTop={1}
                marginBottom={2}
                component={"p"}
                sx={{
                  width: {
                    xs: "100%",
                    // sm: "70%",
                    // md: "70%",
                  },
                  fontSize: {
                    xs: "14px",
                    sm: "16px",
                  },
                  fontFamily: "Inter",
                }}
              >
                Download and install a Chrome browser plugin called MetaMask.
                This will allow websites (that you authorize) access to your
                blockchain account.
              </Typography>
              <SharedButton
                onClick={onClick}
                className="LandingBtnHover"
                value={"Login with Metamask"}
                variant="contained"
                startIcon={
                  <img
                    src="./Assets/Image/Metamasklogo.png"
                    alt="Image"
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "0px 10px",
                    }}
                  />
                }
                sx={{
                  textAlign: "left",
                  width: {
                    xs: "100%",
                    sm: "100%",
                    // md: "50%",
                  },
                  maxWidth: "400px",
                  fontFamily: "Inter",
                  textTransform: "none",
                  fontWeight: "400",
                  fontSize: { xs: "14px", md: "16px" },
                }}
              />
            </Grid>
            <Grid
              item
              component={"div"}
              sx={{
                marginLeft: "0",
                marginTop: "40px",
                marginBottom: {
                  xs: "50px",
                  sm: "110px",
                },
                // border: "1px solid blue",
              }}
            >
              <img src="./Assets/Image/Info.png" alt="" />
              <Typography
                variant="h4"
                component={"h2"}
                marginTop={1}
                sx={{
                  fontWeight: "bold",
                  color: "#0B1E5B",
                  fontSize: {
                    xs: "20px",

                    sm: "24px",
                  },
                  fontFamily: "Inter",
                }}
              >
                Fill in Your Business Information
              </Typography>
              <Typography
                variant="body2"
                marginTop={1}
                marginBottom={2}
                component={"p"}
                sx={{
                  width: {
                    xs: "100%",
                    // sm: "70%",
                    // md: "70%",
                  },
                  fontSize: {
                    xs: "14px",
                    sm: "16px",
                  },
                  fontFamily: "Inter",
                }}
              >
                Once you create your Metamask account, you’d be required to give
                us some information about your business for verification.
              </Typography>
            </Grid>
            <Grid
              item
              component={"div"}
              sx={{
                marginLeft: "0",
                // border: "1px solid green",
              }}
            >
              <Typography
                variant="h4"
                component={"h2"}
                marginTop={1}
                sx={{
                  fontWeight: "bold",
                  color: "#0B1E5B",
                  fontSize: {
                    xs: "20px",

                    sm: "24px",
                  },
                  fontFamily: "Inter",
                }}
              >
                Start Using PlyChain
              </Typography>
              <Typography
                variant="body2"
                marginTop={1}
                marginBottom={2}
                component={"p"}
                sx={{
                  width: {
                    xs: "100%",
                    // sm: "70%",
                    // md: "70%",
                  },
                  fontSize: {
                    xs: "14px",
                    sm: "16px",
                  },
                  fontFamily: "Inter",
                }}
              >
                After successful account creation, you can now enjoy the
                benefits of PlyChain.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
    </div>
  );
};

export default HowToGetStarted;
