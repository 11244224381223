import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { HiOutlinePresentationChartLine } from "react-icons/hi";
import { LuShip } from "react-icons/lu";
import { AiOutlineCodeSandbox } from "react-icons/ai";
import { VscSettingsGear } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { reloadWebsite } from "../../utils/utils";

const drawerWidth = "15%";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const menuOptions = [
  {
    text: "Dashboard",
    icon: <HiOutlinePresentationChartLine className="SideBarIcon" />,
    to: "/dashboard",
  },
  {
    text: "Products",
    icon: <AiOutlineCodeSandbox className="SideBarIcon" />,
    to: "/products",
  },
  {
    text: "Shipments",
    icon: <LuShip className="SideBarIcon" />,
    to: "/shipments",
  },
  // {
  //   text: "Settings",
  //   icon: <VscSettingsGear className="SideBarIcon" />,
  //   to: "/settings",
  // },
];

export default function ResponsiveDrawer(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          "& .MuiDrawer-paper": { background: "transparent" },
        }}
      >
        <img
          src="./Assets/Image/MainLogo.png"
          alt=""
          className="img-fluid MainLogo"
          onClick={(e) => reloadWebsite()}
        />
      </Box>
      <Divider />
      <List>
        {menuOptions.map((option, index) => (
          <ListItem key={option.text} disablePadding>
            <Link className="sideBarLink" to={option.to}>
              <ListItemButton>
                <ListItemIcon>{option.icon}</ListItemIcon>
                <ListItemText primary={option.text} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }} className="SideBar">
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          className="Sidebar"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "#f7f8f3",
              borderRight: "0px solid #fff",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "#f7f8f3",
              borderRight: "0px solid #fff",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
