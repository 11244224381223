import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Container, Typography } from "@mui/material";
import ResponsiveAppBar from "../../SharedComponent/AppBar/ResponsiveAppBar";
import Footer from "./Footer";
import { moveToTop } from "../../utils/utils";

const TermsAndConditions = () => {
  useEffect(()=>{
    moveToTop()
    },[])
  return (
    <>
      <ResponsiveAppBar />
      <Container>
        <Box
          sx={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="h1"
            variant="h3"
            color="#0b1e5b"
            sx={{
              fontFamily: "nexa",
              width: "100%",

              fontSize: {
                xs: "28px",
                md: "36px",
                lg: "48px",
              },
              padding: {
                xs: "0",
              },
            }}
          >
            Terms and Conditions
          </Typography>
          <Typography
            color="#0b1e5b"
            component="p"
            sx={{
              width: {
                xs: "100%",
                sm: "50%",
                md: "30%",
                lg: "30%",
                xl: "30%",
              },
              borderRadius: 1,
              padding: 1,
              marginBottom: 4,
              marginTop: 2,
              border: "1px solid #0b1e5b",
            }}
          >
            Effective from November 1, 2023
          </Typography>
          <Typography
            variant="body1"
            color="#0b1e5b"
            sx={{
              fontFamily: "inter",
              fontWeight: "400",
              textAlign: "left",
              fontSize: "20px",
              width: "100%",
              marginBottom: 1,
              listStyleType: "none",
            }}
          >
            Welcome to Plychain. Please read on to learn the rules and
            restrictions that govern your use of our website(s), mobile
            application(s), and other websites and applications where these
            Terms and Conditions (the “Terms”) appear or are linked and that are
            owned or operated by or on behalf of Plychain or any of its
            affiliates (the “Services”). If you have any questions, comments, or
            concerns regarding these Terms or the Services, please contact us
            at: Email: contact@plychain.io These Terms are a binding contract
            between you and Plychain Inc. (“Plychain,” “we,” “our”, and “us”).
            Your use of the Services in any way means that you agree to all of
            these Terms, and these Terms will remain in effect while you use the
            Services. These Terms include the provisions in this document as
            well as those in the Privacy Policy. Your use of or participation in
            certain Services may also be subject to additional policies, rules
            and/or conditions (“Additional Terms”), which are incorporated
            herein by reference, and you understand and agree that by using or
            participating in any such Services, you agree to also comply with
            these Additional Terms. Please read these Terms carefully. They
            cover important information about Services provided to you and any
            charges, taxes, and fees we bill you. These Terms include
            information about future changes to these Terms, limitations of
            liability, a class action waiver and resolution of disputes by
            arbitration instead of in court. PLEASE NOTE THAT YOUR USE OF AND
            ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO
            NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE
            SERVICES IN ANY MANNER.
          </Typography>

          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            Arbitration Notice and Class Action Waiver
          </Typography>
          <ul>
            <Typography
              color="#0b1e5b"
              component="li"
              sx={{
                fontFamily: "inter",
                fontWeight: "400",
                textAlign: "left",
                fontSize: "20px",
                width: "100%",
                marginBottom: 1,
                listStyleType: "none",
              }}
            >
              Except for certain types of disputes described in the arbitration
              agreement section below, you agree that disputes between you and
              us will be resolved by binding, individual arbitration, and you
              waive your right to participate in a class-action lawsuit or
              class-wide arbitration.
            </Typography>

            <Typography
              variant="body1"
              color="#0b1e5b"
              component="h2"
              sx={{
                lineHeight: 1.6,
                textAlign: "left",
                marginTop: 2.8,
                fontFamily: "nexa",
                width: "100%",
                fontSize: {
                  xs: "24px",
                  sm: "28px",
                  md: "32px",
                  lg: "32px",
                },
              }}
            >
              ‍Will these terms ever change?
              <ul>
                <Typography
                  color="#0b1e5b"
                  component="li"
                  sx={{
                    fontFamily: "inter",
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: "20px",
                    width: "100%",
                    marginBottom: 1,
                    listStyleType: "none",
                  }}
                >
                  We are constantly trying to improve our Services, so these
                  Terms may need to change along with our Services. We reserve
                  the right to change the Terms at any time, but if we do make
                  any material changes to these Terms, we will place a notice on
                  our site located at https://www.plychain.io/, send you an
                  email, and/or notify you by some other means. Notice of
                  immaterial changes may be given by any means, including via
                  posting of the updated version of these Terms. If you don’t
                  agree with the new Terms, you are free to reject them;
                  unfortunately, that means you will no longer be able to use
                  the Services. If you use the Services in any way after a
                  change to the Terms is effective, that means you agree to all
                  of the changes. Except for changes by us as described here, no
                  other amendment or modification of these Terms will be
                  effective unless in writing and signed by both you and us.
                </Typography>
              </ul>
            </Typography>
          </ul>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            ‍What about my privacy?
          </Typography>
          <ul>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              sx={{
                fontFamily: "inter",
                fontWeight: "400",
                textAlign: "left",
                fontSize: "20px",
                width: "100%",
                marginBottom: 1,
                listStyleType: "none",
              }}
            >
              ‍Plychain takes the privacy of its users very seriously.
              ‍Children’s Online Privacy Protection Act ‍The Children’s Online
              Privacy Protection Act (“COPPA”) requires that online service
              providers obtain parental consent before they knowingly collect
              personally identifiable information online from children who are
              under 13 years of age. We do not knowingly collect or solicit
              personally identifiable information from children under 16 years
              of age; if you are a child under 16 years of age, please do not
              attempt to register for or otherwise use the Services or send us
              any personal information. If we learn we have collected personal
              information from a child under 16 years of age, we will delete
              that information as quickly as possible. If you believe that a
              child under 16 years of age may have provided us personal
              information, please contact us at contact@plychain.io.
            </Typography>
          </ul>

          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            ‍What are the basics of using Plychain?
          </Typography>

          <ul>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              sx={{
                fontFamily: "inter",
                fontWeight: "400",
                textAlign: "left",
                fontSize: "20px",
                width: "100%",
                marginBottom: 1,
                listStyleType: "none",
              }}
            >
              ‍You represent and warrant that you are an individual of legal age
              to form a binding contract (or if not, you’ve received your
              parent’s or guardian’s permission to use the Services and have
              gotten your parent or guardian to agree to these Terms on your
              behalf). You will only use the Services for your own personal use,
              and not on behalf of or for the benefit of any third party, and
              only in a manner that complies with all laws that apply to you. If
              your use of the Services is prohibited by applicable laws, then
              you aren’t authorized to use the Services. We can’t and won’t be
              responsible for your using the Services in a way that breaks the
              law.
            </Typography>
          </ul>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            ‍What about messaging?
          </Typography>
          <ul>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              sx={{
                fontFamily: "inter",
                fontWeight: "400",
                textAlign: "left",
                fontSize: "20px",
                width: "100%",
                marginBottom: 1,
                listStyleType: "none",
                marginLeft: 0,
              }}
            >
              ‍As part of the Services, you may receive communications through
              the Services, including messages that Plychain sends you (for
              example, via email).
            </Typography>
          </ul>

          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            ‍Are there restrictions in how I can use the Services?
          </Typography>
          <ul>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              sx={{
                fontFamily: "inter",
                fontWeight: "400",
                textAlign: "left",
                fontSize: "20px",
                width: "100%",
                marginBottom: 1,
                listStyleType: "none",
              }}
            >
              ‍You represent, warrant, and agree that you will not use or
              interact with any Content (as defined below) or Services in a
              manner that: ‍ (a) infringes or violates the intellectual property
              rights or any other rights of anyone else (including Plychain);
              (b) violates any law or regulation, including, without limitation,
              any applicable export control laws, privacy laws or any other
              purpose not reasonably intended by Plychain; (c) is dangerous,
              harmful, fraudulent, deceptive, threatening, harassing,
              defamatory, obscene, or otherwise objectionable; (d) attempts, in
              any manner, to obtain the password, account, or other security
              information from any other user; (e) violates the security of any
              computer network, or cracks any passwords or security encryption
              codes; (f) runs Maillist, Listserv, any form of auto-responder or
              “spam” on the Services, or any processes that run or are activated
              while you are not logged into the Services, or that otherwise
              interfere with the proper working of the Services (including by
              placing an unreasonable load on the Services’ infrastructure); (g)
              “crawls,” “scrapes,” or “spiders” any page, data, or portion of or
              relating to the Services or Content (through use of manual or
              automated means); (h) copies or stores any significant portion of
              the Content; or (i) decompiles, reverse engineers, or otherwise
              attempts to obtain the source code or underlying ideas or
              information of or relating to the Services. A violation of any of
              the foregoing is grounds for termination of your right to use or
              access the Services.
            </Typography>
          </ul>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            ‍What are my rights in the Services?
          </Typography>

          <ul>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              sx={{
                fontFamily: "inter",
                fontWeight: "400",
                textAlign: "left",
                fontSize: "20px",
                width: "100%",
                marginBottom: 1,
                listStyleType: "none",
              }}
            >
              ‍The materials displayed or performed or available on or through
              the Services, including, but not limited to, text, graphics, data,
              articles, photos, images, illustrations, and so forth (all of the
              foregoing, the “Content”) are protected by copyright and/or other
              intellectual property laws. Except as expressly permitted herein,
              you shall not use, copy, reproduce, modify, translate, publish,
              broadcast, transmit, distribute, perform, upload, display,
              license, sell, commercialize or otherwise exploit for any purpose
              any Content. Subject to these Terms, we grant each user of the
              Services a worldwide, non-exclusive, non-sublicensable and
              non-transferable license to use (i.e., to download and display
              locally) Content solely for purposes of using the Services. Use,
              reproduction, modification, distribution or storage of any Content
              for any purpose other than using the Services is expressly
              prohibited without prior written permission from us. You
              understand that Plychain owns the Services and Content. Except as
              expressly permitted herein, you won’t modify, publish, transmit,
              participate in the transfer or sale of, reproduce, create
              derivative works based on, or otherwise exploit any of the
              Services. The Services may allow you to copy or download certain
              Content, but please remember that even where these functionalities
              exist, all the restrictions in this section still apply.
            </Typography>
          </ul>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            ‍Who is responsible for what I see and do on the Services?
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="p"
              sx={{
                fontFamily: "inter",
                fontWeight: "400",
                textAlign: "left",
                fontSize: "20px",
                width: "100%",
                marginBottom: 1,
              }}
            >
              ‍You hereby agree that you access, view and use all Content and
              Services at your own risk, and we aren’t liable for any errors or
              omissions in the Content or Services or for any damages or loss
              you might suffer in connection with any of the foregoing. The
              Services may contain links or connections to third-party websites
              or services that are not owned or controlled by Plychain. When you
              access third-party websites or use third-party services, you
              accept that there are risks in doing so, and that Plychain is not
              responsible for such risks. Plychain has no control over, and
              assumes no responsibility for, the content, accuracy, privacy
              policies, or practices of or opinions expressed in any third-party
              websites or by any third party that you interact with through the
              Services. In addition, Plychain will not and cannot monitor,
              verify, censor or edit the content of any third-party site or
              service. We encourage you to be aware when you leave the Services
              and to read the terms and conditions and privacy policy of each
              third-party website or service that you visit or utilize. By using
              the Services, you release and hold us harmless from any and all
              liability arising from your use of any third-party website or
              service. Your interactions with organizations and/or individuals
              found on or through the Services, including payment and delivery
              of goods or services, and any other terms, conditions, warranties
              or representations associated with such dealings, are solely
              between you and such organizations and/or individuals. You should
              make whatever investigation you feel necessary or appropriate
              before proceeding with any online or offline transaction with any
              of these third parties. You agree that Plychain shall not be
              responsible or liable for any loss or damage of any sort incurred
              as the result of any such dealings. If there is a dispute between
              participants on this site or Services, or between users and any
              third party, you agree that Plychain is under no obligation to
              become involved. In the event that you have a dispute with one or
              more other users, you release Plychain, its directors, officers,
              employees, agents, and successors from claims, demands, and
              damages of every kind or nature, known or unknown, suspected or
              unsuspected, disclosed or undisclosed, arising out of or in any
              way related to such disputes and/or our Services. You shall and
              hereby do waive California Civil Code Section 1542 or any similar
              law of any jurisdiction, which says in substance: “A general
              release does not extend to claims that the creditor or releasing
              party does not know or suspect to exist in his or her favor at the
              time of executing the release and that, if known by him or her,
              would have materially affected his or her settlement with the
              debtor or released party.”
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            ‍Will Plychain ever change the Services?
            <ul>
              <Typography
                variant="body1"
                color="#0b1e5b"
                component="li"
                sx={{
                  fontFamily: "inter",
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: "20px",
                  width: "100%",
                  marginBottom: 1,
                  listStyleType: "none",
                }}
              >
                ‍We’re always trying to improve our Services, so they may change
                over time. We may suspend or discontinue any part of the
                Services, or we may introduce new features or impose limits on
                certain features or restrict access to parts or all of the
                Services. We’ll try to give you notice when we make a material
                change to the Services that would adversely affect you, but this
                isn’t always practical. We reserve the right to remove any
                Content from the Services at any time, for any reason
                (including, but not limited to, if someone alleges you
                contributed that Content in violation of these Terms), in our
                sole discretion, and without notice.
              </Typography>
            </ul>
          </Typography>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            ‍What if I want to stop using the Services?
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="p"
              sx={{
                fontFamily: "inter",
                fontWeight: "400",
                textAlign: "left",
                fontSize: "20px",
                width: "100%",
                marginBottom: 1,
              }}
            >
              ‍You’re free to do that at any time; please refer to our Privacy
              Policy, as well as the licenses above, to understand how we treat
              information you provide to us after you have stopped using our
              Services. Plychain is also free to terminate (or suspend access
              to) your use of the Services for any reason in our discretion,
              including your breach of these Terms. Plychain has the sole right
              to decide whether you are in violation of any of the restrictions
              set forth in these Terms. Provisions that, by their nature, should
              survive termination of these Terms shall survive termination. By
              way of example, all of the following will survive termination: any
              obligation you have to pay us or indemnify us, any limitations on
              our liability, any terms regarding ownership or intellectual
              property rights, and terms regarding disputes between us,
              including without limitation the arbitration agreement.
            </Typography>
          </Typography>

          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            ‍What else do I need to know?
          </Typography>

          <Typography
            variant="body1"
            color="#0b1e5b"
            component="p"
            sx={{
              fontFamily: "inter",
              fontWeight: "400",
              textAlign: "left",
              fontSize: "20px",
              width: "100%",
              marginBottom: 1,
            }}
          >
            ‍‍Warranty Disclaimer. Plychain and its licensors, suppliers,
            partners, parent, subsidiaries or affiliated entities, and each of
            their respective officers, directors, members, employees,
            consultants, contract employees, representatives and agents, and
            each of their respective successors and assigns (Plychain and all
            such parties together, the “Plychain Parties”) make no
            representations or warranties concerning the Services, including
            without limitation regarding any Content contained in or accessed
            through the Services, and the Plychain Parties will not be
            responsible or liable for the accuracy, copyright compliance,
            legality, or decency of material contained in or accessed through
            the Services or any claims, actions, suits procedures, costs,
            expenses, damages or liabilities arising out of use of, or in any
            way related to your participation in, the Services. The Plychain
            Parties make no representations or warranties regarding suggestions
            or recommendations of services or products offered or purchased
            through or in connection with the Services.
            <br />
            <br />
            THE SERVICES AND CONTENT ARE PROVIDED BY Plychain (AND ITS LICENSORS
            AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND,
            EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED
            OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN
            IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO
            YOU. ‍Limitation of Liability. TO THE FULLEST EXTENT ALLOWED BY
            APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY
            (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR
            OTHERWISE) SHALL ANY OF THE PLYCHAIN PARTIES BE LIABLE TO YOU OR TO
            ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE
            OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST
            PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF GOODWILL, WORK
            STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION,
            (B) ANY SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN
            THE AGGREGATE, IN EXCESS OF ONE HUNDRED DOLLARS ($100), OR (D) ANY
            MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE
            EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN
            OTHER DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY
            TO YOU.
            <br />
            <br />
            ‍Indemnity. To the fullest extent allowed by applicable law, You
            agree to indemnify and hold the Plychain Parties harmless from and
            against any and all claims, suits, actions, liabilities, damages
            (actual and consequential), losses, costs and expenses (including
            attorneys’ fees) arising from or in any way related to (a) your use
            of the Services, (b) your violation of any applicable law, rule or
            regulation or of any third-party rights, (c) your violation of these
            Terms or (d) your negligence or willful misconduct. ‍Assignment. You
            may not assign, delegate or transfer these Terms or your rights or
            obligations hereunder, or your Services account, in any way (by
            operation of law or otherwise) without Plychain's prior written
            consent. We may transfer, assign, or delegate these Terms and our
            rights and obligations without consent. ‍Choice of Law. These Terms
            are governed by and will be construed under the Federal Arbitration
            Act, applicable federal law, and the laws of the State of Delaware,
            without regard to the conflicts of laws provisions thereof.
            ‍Arbitration Agreement. Please read the following ARBITRATION
            AGREEMENT carefully because it requires you to arbitrate certain
            disputes and claims with Plychain and limits the manner in which you
            can seek relief from Plychain. Both you and Plychain acknowledge and
            agree that for the purposes of any dispute arising out of or
            relating to the subject matter of these Terms, Plychain's officers,
            directors, employees and independent contractors (“Personnel”) are
            third-party beneficiaries of these Terms, and that upon your
            acceptance of these Terms, Personnel will have the right (and will
            be deemed to have accepted the right) to enforce these Terms against
            you as the third-party beneficiary hereof. ‍(a) Arbitration Rules;
            Applicability of Arbitration Agreement.
            <br />
            <br />
            The parties shall use their best efforts to settle any dispute,
            claim, question, or disagreement arising out of or relating to the
            subject matter of these Terms directly through good-faith
            negotiations, which shall be a precondition to either party
            initiating arbitration. If such negotiations do not resolve the
            dispute, it shall be finally settled by binding arbitration in New
            York, New York. The arbitration will proceed in the English
            language, in accordance with the JAMS Streamlined Arbitration Rules
            and Procedures (the “Rules”) then in effect, by one commercial
            arbitrator with substantial experience in resolving intellectual
            property and commercial contract disputes. The arbitrator shall be
            selected from the appropriate list of JAMS arbitrators in accordance
            with such Rules. Judgment upon the award rendered by such arbitrator
            may be entered in any court of competent jurisdiction. (b) Costs of
            Arbitration. The Rules will govern payment of all arbitration fees.
            Parties will bear their own costs. Plychain will not seek its
            attorneys’ fees and costs in arbitration unless the arbitrator
            determines that your claim is frivolous. (c) Small Claims Court;
            Infringement. Either you or Plychain may assert claims, if they
            qualify, in small claims court in New York, New York or any United
            States county where you live or work. Furthermore, notwithstanding
            the foregoing obligation to arbitrate disputes, each party shall
            have the right to pursue injunctive or other equitable relief at any
            time, from any court of competent jurisdiction, to prevent the
            actual or threatened infringement, misappropriation or violation of
            a party's copyrights, trademarks, trade secrets, patents or other
            intellectual property rights. (d) Waiver of Jury Trial. YOU AND
            PLYCHAIN WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO
            COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. You and Plychain
            are instead choosing to have claims and disputes resolved by
            arbitration. Arbitration procedures are typically more limited, more
            efficient, and less costly than rules applicable in court and are
            subject to very limited review by a court. In any litigation between
            you and Plychain over whether to vacate or enforce an arbitration
            award, YOU AND PLYCHAIN WAIVE ALL RIGHTS TO A JURY TRIAL, and elect
            instead to have the dispute be resolved by a judge. (e) Waiver of
            Class or Consolidated Actions.
            <br />
            <br />
            ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION
            AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND
            NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER
            CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE
            OF ANY OTHER CUSTOMER OR USER.
            <br />
            <br />
            If however, this waiver of class or consolidated actions is deemed
            invalid or unenforceable, neither you nor Plychain is entitled to
            arbitration; instead all claims and disputes will be resolved in a
            court as set forth in (g) below. ‍(f) Opt-out. You have the right to
            opt out of the provisions of this Section by sending written notice
            of your decision to opt out to contact@plychain.io within thirty
            (30) days of first accepting these Terms. You must include (i) your
            name and residence address, (ii) the email address and/or telephone
            number associated with your account, and (iii) a clear statement
            that you want to opt out of these Terms’ arbitration agreement. (g)
            Exclusive Venue. If you send the opt-out notice in (f), and/or in
            any circumstances where the foregoing arbitration agreement permits
            either you or Plychain to litigate any dispute arising out of or
            relating to the subject matter of these Terms in court, then the
            foregoing arbitration agreement will not apply to either party, and
            both you and Plychain agree that any judicial proceeding (other than
            small claims actions) will be brought in the state or federal courts
            located in, respectively, New York, New York, or the federal
            district in which that county falls. (h) Severability. If the
            prohibition against class actions and other claims brought on behalf
            of third parties contained above is found to be unenforceable, then
            all of the preceding language in this Arbitration Agreement section
            will be null and void. This arbitration agreement will survive the
            termination of your relationship with Plychain. ‍Notice to
            California Consumers. Under California Civil Code Section 1789.3,
            California users are entitled to the following consumer rights
            notice: the Complaint Assistance Unit of the Division of Consumer
            Services of the California Department of Consumer Affairs may be
            contacted in writing at 1625 North Market Blvd., Suite N 112,
            Sacramento, CA 95834, or by telephone at (800) 952-5210.
            ‍Miscellaneous. You will be responsible for paying, withholding,
            filing, and reporting all taxes, duties, and other governmental
            assessments associated with your activity in connection with the
            Services, provided that Plychain may, in its sole discretion, do any
            of the foregoing on your behalf or for itself as it sees fit.
            <br />
            <br />
            The failure of either you or us to exercise, in any way, any right
            herein shall not be deemed a waiver of any further rights hereunder.
            If any provision of these Terms are found to be unenforceable or
            invalid, that provision will be limited or eliminated, to the
            minimum extent necessary, so that these Terms shall otherwise remain
            in full force and effect and enforceable. You and Plychain agree
            that these Terms are the complete and exclusive statement of the
            mutual understanding between you and Plychain, and that these Terms
            supersede and cancel all previous written and oral agreements,
            communications and other understandings relating to the subject
            matter of these Terms. You hereby acknowledge and agree that you are
            not an employee, agent, partner, or joint venture of Plychain, and
            you do not have any authority of any kind to bind Plychain in any
            respect whatsoever. Except as expressly set forth in the section
            above regarding the arbitration agreement, you and Plychain agree
            there are no third-party beneficiaries intended under these Terms.
          </Typography>
        </Box>
        <br />
        <br />
        <br />
      </Container>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
