import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";
import { Box, ButtonBase, Grid } from "@mui/material";
import SharedButton from "../Button/SharedButton";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { useSDK } from "@metamask/sdk-react";
import { useDispatch } from "react-redux";
import { AuthModel } from "../../models/authModel";
import { login } from "../../networking/networkCalls";
import { authActions } from "../../store/auth";
import { moveToTop, reloadWebsite } from "../../utils/utils";
import { getCurrentNetworkId } from "../../utils/web3";

// images
import hamburger from "../../Assets/assets/hamburger.svg";
import cancel from "../../Assets/assets/cancel.svg";
import purple_top from "../../Assets/assets/purple-top.svg";
import purple_bottom from "../../Assets/assets/purple-bottom.svg";
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';

const Navigation = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const { sdk } = useSDK();
  const navigate: NavigateFunction = useNavigate();
  const dispatch = useDispatch();

  const connect = async () => {
    try {
      const isCorrectNetworkId: boolean = await getCurrentNetworkId();
      // console.log("isCorrectNetworkId --> ",isCorrectNetworkId);
      if (isCorrectNetworkId !== true) {
        return;
      }

      const accounts = await sdk?.connect();
      // @ts-ignore
      if (accounts?.length > 0) {
        // hit login api and check if user/business is already registered

        const res = await login({
          wallet_address: accounts?.[0],
        });

        if (res) {
          if (res && res.status === 200 && res.data && res.data.success) {
            const { data } = res.data;
            if (res.data.data.isBusinessRegistered) {
              // business is already registered
              // navigate to dashboard
              navigate("/howtogetstart");
              // save login state and data in local storage
              localStorage.setItem("login", "1");
              localStorage.setItem("authData", JSON.stringify(data));

              // save login state and data in redux store
              dispatch(authActions.setLoginState(true));
              const authData: AuthModel = data;
              dispatch(authActions.setAuthData(authData));

              navigate("/dashboard", { replace: true });
            } else {
              // business is not registered
              // navigate to signup

              // save login state and data in local storage
              localStorage.setItem("authData", JSON.stringify(data));

              // save login state and data in redux store
              const authData: AuthModel = data;
              dispatch(authActions.setAuthData(authData));

              navigate("/signup");
            }
          }
        }
      }
    } catch (err) {
      console.warn(`failed to connect..`, err);
    }
  };



  const handleToggleMobileNavbar = () => {
    setMobileOpen(!mobileOpen);
  };

  const closeMobileNavbar = () => {
    setMobileOpen(false);
  };

  const moveToTop = () => {
    // Implement your moveToTop logic
  };

  // const handleDrawerToggle = () => {
  //   console.log("is working....");
  //   setMobileOpen(!mobileOpen);
  // };

  return (
    <div>
      <AppBar
        position="static"
        sx={{
          width: "100%",
          borderBottom: "1px solid #E0E0E0",
          // border: "1px solid green",
          position: "fixed",
          top: "0",
          zIndex: "5",
          // backgroundColor: "red",
        }}
      >
        <Toolbar
          sx={{
            display: "block",
            // border: "1px solid red",
            zIndex: "5",
            position: "relative",
            backgroundColor: "#F7F8F2",
            borderBottom: { xs: "1px solid #E0E0E0", md: "none" },
          }}
        >
          <Box
            sx={{
              // marginTop: 1,
              // border: "1px solid yellow",

              marginTop: {
                xs: "32px",
                sm: "24px",
              },
              marginBottom: {
                xs: "12px",
                sm: "24px",
              },

              display: { xs: "flex", md: "none" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "90px",
                  sm: "120px",
                },
                // border: "1px solid blue",
              }}
            >
              <img
                src="./Assets/Image/MainLogo.png"
                alt=""
                className="footerLogo"
                onClick={(e) => reloadWebsite()}
              />
            </Box>

            {!mobileOpen ? (
              <Box
                onClick={() => {
                  console.log("is working.... open");
                  setMobileOpen(true);
                }}
                // sx={{ border: "1px solid red" }}
              >
                <img src={hamburger} alt=""></img>
                {/* <MenuIcon /> */}
              </Box>
            ) : (
              <Box
                onClick={() => {
                  console.log("is working.... close");
                  setMobileOpen(false);
                }}
                // sx={{ border: "1px solid blue" }}
              >
                <img src={cancel} alt=""></img>
                {/* <MenuIcon /> */}
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: { xs: "none", md: "block" },
            }}
          >
            <Box
              sx={{
                pt: 3,
                pb: 3,
                // margin: "",
                maxWidth: "1440px",
                alignItems: "center",
                // border: "1px solid red",
                marginX: "auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* logo and nav flex box */}
              <Box
                sx={{
                  // border: "1px solid green",
                  display: "flex",
                  gap: {
                    md: "20px",
                    lg: "54px",
                  },
                  alignItems: "center",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      width: {
                        xs: "90px",
                        sm: "120px",
                      },
                      cursor: "pointer",
                      // border: "1px solid blue",
                    }}
                  >
                    <img
                      src="./Assets/Image/MainLogo.png"
                      alt=""
                      className="footerLogo"
                      onClick={(e) => reloadWebsite()}
                    />
                  </Box>
                </Box>
                <Box
                  // item
                  // sm
                  // md={6}
                  textAlign={"left"}
                  // spacing={3}
                  // alignItems={"center"}
                  sx={{
                    // border: "1px solid black",
                    fontSize: "14px",
                    fontWeight: "500",
                    display: "flex",
                    gap: {
                      md: "24px",

                      lg: "94px",
                    },
                  }}
                >
                  <Link
                    to={"/aboutus"}
                    color="inherit"
                    className="header__navlink"
                    onClick={() => {
                      moveToTop();
                    }}
                  >
                    ABOUT
                  </Link>
                  <Link to={"#"} color="inherit" className="header__navlink">
                  <ScrollLink to="howToGetStartSection" smooth={true}>HOW TO GET STARTED</ScrollLink>
                  </Link>
                  <Link
                    to={"/request-demo"}
                    color="inherit"
                    className="header__navlink"
                    onClick={() => {
                      moveToTop();
                    }}
                  >
                    REQUEST DEMO
                  </Link>
                </Box>
              </Box>
              {/* login and get in touch button */}
              <Box
                // item
                // sm
                // md={4}
                sx={{
                  // border: "1px solid brown",
                  display: "flex",
                  alignItems: "center",
                  // gap: "8px",
                  // width: "100%",
                }}
              >
                <SharedButton
                  onClick={connect}
                  className="LandingBtnHoverNav"
                  value={"Login with Metamask"}
                  variant="contained"
                  startIcon={
                    <img
                      src="./Assets/Image/Metamasklogo.png"
                      alt="Image"
                      style={{
                        width: "20px",
                        height: "20px",
                        // margin: "0px 5px",
                      }}
                    />
                  }
                  sx={{
                    // border: "1px solid blue",
                    textAlign: "center",
                    width: {
                      xs: "200px",
                      sm: "200px",
                      md: "230px",
                      lg: "244px",
                    },
                    margin: {
                      sx: "20px 10px 0px 0px",
                      // sm: "0px 10px 0px 0px",
                      md: "0px 8px 0px 0px",
                    },
                    fontSize: "14px",
                    fontFamily: "Inter",

                    height: "48px",
                    textTransform: "none",
                  }}
                />
                <Link
                  to={"/contactus"}
                  color="inherit"
                  onClick={() => {
                    moveToTop();
                  }}
                >
                  <SharedButton
                    className="TextBtn2"
                    value={"Get In Touch"}
                    variant="text"
                    sx={{
                      // border: "1px solid black",
                      textAlign: "left",
                      width: {
                        xs: "100%",
                        // sm: "30%",
                        // md: "30%",
                      },
                      fontSize: "16px",
                      fontWeight: "600",
                      fontFamily: "Inter",
                      textTransform: "none",
                    }}
                  />
                </Link>
              </Box>
            </Box>
          </Box>
        </Toolbar>
        {mobileOpen && (
          <Box
            sx={{
              display: { xs: "block", md: "none" },
              background: "#F7F8F2",
              position: "relative",
              height: "100vh",
              top: "-2px",
              // border: "1px solid red",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "0",
                left: "0",
                height: "50%",
                display: { xs: "none", sm: "block" },
              }}
            >
              <img
                src={purple_top}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              ></img>
            </Box>

            <Box
              sx={{
                position: "absolute",
                bottom: "0",
                right: "0",
                height: { xs: "50%", sm: "70%" },
                // display: { xs: "none", sm: "block" },
              }}
            >
              <img
                src={purple_bottom}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              ></img>
            </Box>
            <Grid
              container
              direction={"column"}
              spacing={5}
              textAlign={"center"}
              sx={{
                paddingY: "20px",
                paddingX: "16px",
                // padding: 5,
                // border: "1px solid red",
              }}
            >
              <Grid item sm component={"div"}>
                <Link
                  to={"/aboutus"}
                  color="inherit"
                  className="NavLink"
                  onClick={() => {
                    moveToTop();
                  }}
                >
                  ABOUT
                </Link>
              </Grid>
              <Grid item sm component={"div"}>
                <Link
                  to={"/"}
                  color="inherit"
                  className="NavLink"
                 
                >
                    <ScrollLink to="howToGetStartSection" smooth={true}  onClick={() => {
                    moveToTop();
                    closeMobileNavbar(); // Close mobile navbar when clicking on the link
                  }}>HOW TO GET STARTED</ScrollLink>
                </Link>
              
              </Grid>
              <Grid item sm component={"div"}>
                <Link
                  to={"/request-demo"}
                  color="inherit"
                  className="NavLink"
                  onClick={() => {
                    moveToTop();
                  }}
                >
                  REQUEST DEMO
                </Link>
              </Grid>
              <Grid
                item
                sm
                sx={{
                  alignItems: "center",
                }}
              >
                <SharedButton
                  onClick={connect}
                  className="LandingBtnHoverNav"
                  value={"Login with Metamask"}
                  variant="contained"
                  startIcon={
                    <img
                      src="./Assets/Image/Metamasklogo.png"
                      alt="Image"
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "0px 10px",
                      }}
                    />
                  }
                  sx={{
                    textAlign: "center",
                    width: {
                      xs: "100%",
                      sm: "50%",
                      md: "50%",
                    },
                  }}
                />
                <Link
                  to={"/contactus"}
                  color="inherit"
                  onClick={() => {
                    moveToTop();
                  }}
                >
                  <SharedButton
                    // className="TextBtn"
                    value={"Get In Touch"}
                    variant="text"
                    sx={{
                      marginTop: { xs: "10px", sm: "0" },
                      fontSize: "14px",
                      textAlign: "left",
                      color: "#0b1e5b;",
                      width: {
                        xs: "100%",
                        sm: "30%",
                        md: "30%",
                      },
                    }}
                  />
                </Link>
              </Grid>
            </Grid>
          </Box>
        )}
      </AppBar>
    </div>
  );
};

export default Navigation;
