import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  FormGroup,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import { Container, Grid, Typography } from "@mui/material";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import SharedButton from "../../SharedComponent/Button/SharedButton";

const NumberComp = (props: any) => {
  const { number, active } = props;
  return (
    <Typography
      variant="h6"
      component="h6"
      sx={{
        padding: "0",
        background: active && `#AA97F4`,
        borderRadius: 10,
        width: "20px",
        height: "20px",
        textAlign: "center",
        cursor: "pointer",
        margin: "17px 0px",
        border: "1px solid #AA97F4",
        fontSize: "12px",
        color: active ? "#F4F1FD" : "#AA97F4",
      }}
    >
      {number}
    </Typography>
  );
};

export default NumberComp;
