import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import React from "react";

const FileViewer = () => {
  const docs = [
    {
      uri: "https://samplelib.com/lib/preview/jpeg/sample-clouds-400x300.jpg",
    },
  ];

  return (
    <DocViewer
      documents={docs}
      initialActiveDocument={docs[0]}
      pluginRenderers={DocViewerRenderers}
    />
  );
};

export default FileViewer;
