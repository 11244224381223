import axios, { AxiosResponse } from "axios";
import FormData from "form-data";
import { BASE_URL, productConditionType } from "../constants/constants";
import store from "../store";
import { AuthModel } from "../models/authModel";

const postRequest = async (
  path: string,
  data: any
): Promise<AxiosResponse<any, any> | undefined> => {
  let response: AxiosResponse<any, any> = undefined;

  const state = store.getState();
  const authData: AuthModel = state.auth.authData;

  try {
    response = await axios.post(`${BASE_URL}${path}`, data, {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    });
  } catch (err) {
    // console.log(`ERROR :: postRequest :: ${path} :: ${err}`);
    response = err.response;
  } finally {
    return response;
  }
};

const patchRequest = async (
  path: string,
  data: any
): Promise<AxiosResponse<any, any> | undefined> => {
  let response: AxiosResponse<any, any> = undefined;

  const state = store.getState();
  const authData: AuthModel = state.auth.authData;

  try {
    response = await axios.patch(`${BASE_URL}${path}`, data, {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    });
  } catch (err) {
    // console.log(`ERROR :: postRequest :: ${path} :: ${err}`);
    response = err.response;
  } finally {
    return response;
  }
};

const getRequest = async (
  path: string,
  data = {}
): Promise<AxiosResponse<any, any> | undefined> => {
  let response: AxiosResponse<any, any> = undefined;

  const state = store.getState();
  const authData: AuthModel = state.auth.authData;

  try {
    response = await axios.get(`${BASE_URL}${path}`, {
      params: data,
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    });
  } catch (err) {
    // console.log(`ERROR :: getRequest :: ${path} :: ${err}`);
    response = err.response;
  } finally {
    return response;
  }
};

const deleteRequest = async (
  path: string
): Promise<AxiosResponse<any, any> | undefined> => {
  let response: AxiosResponse<any, any> = undefined;

  const state = store.getState();
  const authData: AuthModel = state.auth.authData;

  try {
    response = await axios.delete(`${BASE_URL}${path}`, {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    });
  } catch (err) {
    // console.log(`ERROR :: postRequest :: ${path} :: ${err}`);
    response = err.response;
  } finally {
    return response;
  }
};

const login = async (data: { wallet_address: string }) => {
  const path = "/user/login";
  return postRequest(path, data);
};

const signup = async (data: {
  name: string;
  email: string;
  business_category: string;
  phone_number: string;
  country: string;
  address: string;
  document_of_incorporation: File;
  business_logo: File;
}) => {
  const formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }

  const path = "/user/signup";
  return postRequest(path, formData);
};

const updateProfile = async (
  data: {
    first_name?: string;
    last_name?: string;
    email?: string;
    dob?: string;
    business_logo: File;
  },
  id: number
) => {
  const formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }

  const path = `/user/updateProfile/${id}`;
  return patchRequest(path, formData);
};

const createBlockTransaction = async (
  logId: number,
  data: {
    hash_data: string;
    trnx_data: JSON;
  }
) => {
  const path = `/blockchainTransaction/createBlockchainTransaction/${logId}`;
  return postRequest(path, data);
};

const createProduct = async (data: {
  user_id: number;
  generated_product_id: string;
  name?: string | null;
  quantity?: number | null;
  quantity_unit?: string | null;
  condition?: productConditionType | null;
  description?: string | null;
  product_story?: string | null;
  reuse_recycle_info?: string | null;
  sku?: string | null;
  weight?: number | null;
  weight_unit?: string | null;
  color?: string | null;
  length?: number | null;
  length_unit?: string | null;
  width?: number | null;
  width_unit?: string | null;
  height?: number | null;
  height_unit?: string | null;
  thumbnails?: File[];
  status: string;
}) => {
  const formData = new FormData();
  for (let key in data) {
    if (key === "thumbnails") {
      if (data[key].length > 0) {
        for (const file of data[key]) {
          formData.append(key, file);
        }
      }
    } else {
      formData.append(key, data[key]);
    }
  }

  const path = "/product/createProduct";
  return postRequest(path, formData);
};

const updateProduct = async (
  data: {
    user_id: number;
    generated_product_id: string;
    name?: string | null;
    quantity?: number | null;
    quantity_unit?: string | null;
    condition?: productConditionType | null;
    description?: string | null;
    product_story?: string | null;
    reuse_recycle_info?: string | null;
    sku?: string | null;
    weight?: number | null;
    weight_unit?: string | null;
    color?: string | null;
    length?: number | null;
    length_unit?: string | null;
    width?: number | null;
    width_unit?: string | null;
    height?: number | null;
    height_unit?: string | null;
    thumbnails?: File[];
    status: string;
    existing_thumbnails_url?: string;
  },
  id: number
) => {
  const formData = new FormData();
  for (let key in data) {
    if (key === "thumbnails") {
      if (data[key].length > 0) {
        for (const file of data[key]) {
          formData.append(key, file);
        }
      }
    } else {
      formData.append(key, data[key]);
    }
  }

  const path = `/product/updateProduct/${id}`;
  return patchRequest(path, formData);
};

const getAllProducts = async (data: {
  itemsPerPage?: number;
  page?: number;
  search?: string;
  skipPagination?: boolean;
}) => {
  const path = "/product/getAllProducts";
  return getRequest(path, data);
};

const getProduct = async (data: { id: number; source?: string }) => {
  const path = "/product/getProduct";
  return getRequest(path, data);
};

const deleteProduct = async (id: number) => {
  const path = `/product/deleteProduct/${id}`;
  return deleteRequest(path);
};

const createShipment = async (data: {
  generated_shipment_id: string;
  recipient_company_name?: string;
  recipient_address?: string;
  recipient_country?: string;
  recipient_city?: string;
  expected_arrival_date?: string;
  expected_arrival_time?: string;
  product_id?: number;
  quantity?: number;
  quantity_unit?: string;
  weight?: number;
  weight_unit?: string;
  labels?: string;
  further_information?: string;
  shipment_documents?: File[];
  status: string;
}) => {
  const formData = new FormData();
  for (let key in data) {
    if (key === "shipment_documents") {
      if (data[key].length > 0) {
        for (const file of data[key]) {
          formData.append(key, file);
        }
      }
    } else {
      formData.append(key, data[key]);
    }
  }

  const path = "/shipment/createShipment";
  return postRequest(path, formData);
};

const updateShipment = async (
  data: {
    generated_shipment_id: string;
    recipient_company_name?: string;
    recipient_address?: string;
    recipient_country?: string;
    recipient_city?: string;
    expected_arrival_date?: string;
    expected_arrival_time?: string;
    product_id?: number;
    quantity?: number;
    quantity_unit?: string;
    weight?: number;
    weight_unit?: string;
    labels?: string;
    further_information?: string;
    shipment_documents?: File[];
    status: string;
  },
  id: number
) => {
  const formData = new FormData();
  for (let key in data) {
    if (key === "shipment_documents") {
      if (data[key].length > 0) {
        for (const file of data[key]) {
          formData.append(key, file);
        }
      }
    } else {
      formData.append(key, data[key]);
    }
  }

  const path = `/shipment/updateShipment/${id}`;
  return patchRequest(path, formData);
};

const getAllShipments = async (data: {
  itemsPerPage: number;
  page: number;
}) => {
  const path = "/shipment/getAllShipments";
  return getRequest(path, data);
};

const getShipment = async (data: { id: number; source?: string }) => {
  const path = "/shipment/getShipment";
  return getRequest(path, data);
};

const deleteShipment = async (id: number) => {
  const path = `/shipment/deleteShipment/${id}`;
  return deleteRequest(path);
};

const createCheckpoint = async (data: {
  shipment_id: number;
  country: string;
  city: string;
  address: string;
  date: string;
  time: string;
}) => {
  const path = "/checkpoint/createCheckpoint";
  return postRequest(path, data);
};

const getDashboardOverview = async () => {
  const path = "/user/getDashboardOverview";
  return getRequest(path);
};

const getShipmentOverview = async () => {
  const path = "/shipment/getShipmentOverview";
  return getRequest(path);
};

export {
  login,
  signup,
  updateProfile,
  createBlockTransaction,
  createProduct,
  updateProduct,
  getAllProducts,
  getProduct,
  deleteProduct,
  createShipment,
  updateShipment,
  getAllShipments,
  getShipment,
  deleteShipment,
  createCheckpoint,
  getDashboardOverview,
  getShipmentOverview,
};
