import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface InputFileUploadProps {
  text: string;
  icon: any;
  className: string;
  component: any;
  variant: any;
  accept?: string;
  onChange?: (
    e: React.SyntheticEvent<HTMLInputElement, Event>
  ) => void | undefined;
}

export default function InputFileUpload(props: InputFileUploadProps) {
  return (
    <Button
      className={props.className}
      component={props.component}
      variant={props.variant}
      startIcon={props.icon}
      style={{
        borderRadius: "8px",
        padding: "32px 12px",
        backgroundColor: "#fff",
        color: "#9E9E9E !important",
        fontSize: "14px",
        textTransform: "capitalize",
      }}
    >
      {props.text}
      <VisuallyHiddenInput
        type="file"
        accept={props.accept}
        onChange={props.onChange}
      />
    </Button>
  );
}
