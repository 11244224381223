import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ResponsiveDrawer from "../../SharedComponent/Sidebar/ResponsiveDrawer";
import Header from "../../SharedComponent/Header/Header";
import {
    Alert,
    AlertColor,
    CircularProgress,
    FormGroup,
    Snackbar,
    Stack,
    Switch,
    Typography,
} from "@mui/material";
import SharedButton from "../../SharedComponent/Button/SharedButton";
import { useDispatch, useSelector } from "react-redux";
import { AuthModel } from "../../models/authModel";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

export const Settings = () => {
    const [loading, setLoading] = React.useState(false);




    const [snackBarState, setSnackBarState] = React.useState<{
        show: boolean;
        msg: string;
        severity: AlertColor;
    }>({
        show: false,
        msg: "",
        severity: "success",
    });

    // @ts-ignore
    const authData: AuthModel = useSelector((state) => state.auth.authData);

    const dispatch = useDispatch();




    const onSaveHandler = async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {

        setLoading(true);





        setLoading(false);
    };

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackBarState({ ...snackBarState, show: false });
    };

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Snackbar
                    open={snackBarState.show}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    <Alert
                        onClose={handleClose}
                        severity={snackBarState.severity}
                        sx={{ width: "100%" }}
                    >
                        {snackBarState.msg}
                    </Alert>
                </Snackbar>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <ResponsiveDrawer />
                    </Grid>
                    <Grid item xs={10}>
                        <Header />
                        <Box className={"MainBoxComponent"}>
                            <Grid container spacing={0}>
                                <Grid item xs>
                                    <Typography
                                        marginLeft={"10px"}
                                        textAlign={"left"}
                                        variant="h5"
                                        component={"h4"}
                                    >
                                        Settings
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} marginTop={3}>
                                <Grid item xs>
                                    <Typography
                                        marginLeft={"10px"}
                                        textAlign={"left"}
                                        variant="body2"
                                        component={"h4"}
                                        sx={{
                                            borderBottom: "1px solid #dcdcdc",
                                        }}
                                    >
                                        Subscription Plans
                                    </Typography>
                                </Grid>
                            </Grid>
        
                            <Box
                                sx={{
                                    marginTop: 2,
                                }}
                            >
                                <Typography component="h2" variant="h4" sx={{
                                    color: "#0B1E5B",
                                    marginTop: 0,
                                    fontWeight: 'bold'
                                }}>
                                    Choose the plan that’s right for <br /> your business
                                </Typography>
                                <Grid container>
                                    <Grid item sm md={4} textAlign={"center"}></Grid>

                                    <Grid item sm md={3} textAlign={"center"}>
                                        <FormGroup>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                alignItems="center"
                                                justifyContent={"center"}
                                            >
                                                <Typography>Bill Monthly</Typography>
                                                <Switch
                                                    defaultChecked
                                                    inputProps={{ "aria-label": "ant design" }}
                                                />
                                                <Typography>Bill Annually</Typography>
                                            </Stack>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item sm md={5} textAlign={"left"}>
                                        <img
                                            src="/Assets/Image/sale15.png"
                                            className="sale"
                                            alt="Example"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction={"row"}
                                    spacing={{
                                        xs: 2,
                                        sm: 5,
                                        md: 5,
                                    }}
                                    p={{
                                        xs: 2,
                                        sm: 5,
                                        md: 5,
                                    }}
                                >
                                    <Grid item sm>
                                        <Box
                                            sx={{
                                                background: "#E3DDFB",
                                                padding: 2,
                                                borderRadius: 5,
                                            }}
                                            className='PremiumCard'
                                        >
                                            <Grid container spacing={5} direction={"column"}>
                                                <Grid item>
                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="h3"
                                                        component={"h2"}
                                                        className="customHeading"
                                                        sx={{
                                                            color: "#0B1E5B",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        $29{" "}
                                                        <Typography
                                                            textAlign={"left"}
                                                            variant="body1"
                                                            component={"span"}
                                                            style={{
                                                                fontWeight: "lighter",
                                                                fontSize: "1.2rem",
                                                                position: "relative",
                                                                bottom: "10px",
                                                            }}
                                                        >
                                                            {" "}
                                                            /month
                                                        </Typography>
                                                    </Typography>
                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="h5"
                                                        component={"h2"}
                                                        sx={{
                                                            color: "#0B1E5B",
                                                            fontWeight: "bold",
                                                            marginTop: 2,
                                                        }}
                                                    >
                                                        Tier 1 - Starter
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            background: "#F4F1FD",
                                                            padding: 2,
                                                            borderRadius: 2,
                                                            marginTop: 2,
                                                            marginBottom: 2,
                                                        }}
                                                    >
                                                        <Typography
                                                            textAlign={"left"}
                                                            variant="h6"
                                                            component={"h2"}
                                                            sx={{
                                                                color: "#9E9E9E",
                                                                fontWeight: "normal",
                                                                marginTop: 0,
                                                            }}
                                                        >
                                                            Materials Tagged
                                                        </Typography>
                                                        <Typography
                                                            textAlign={"left"}
                                                            variant="body1"
                                                            component={"h2"}
                                                            sx={{
                                                                color: "#616161",
                                                                fontWeight: "normal",
                                                                marginTop: 1,
                                                            }}
                                                        >
                                                            Up to 100 materials per month
                                                        </Typography>
                                                    </Box>
                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="body1"
                                                        component={"p"}
                                                        sx={{
                                                            color: "#616161",
                                                            fontWeight: "normal",
                                                            marginTop: 1,
                                                            fontSize:'0.7rem'
                                                        }}
                                                    >
                                                        <img src="./Assets/Image/check.png" alt="" />
                                                        Material tagging for tracking and transparency
                                                    </Typography>
                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="body1"
                                                        component={"p"}
                                                        sx={{
                                                            color: "#616161",
                                                            fontWeight: "normal",
                                                            marginTop: 1,
                                                            fontSize:'0.7rem'
                                                        }}
                                                    >
                                                        <img src="./Assets/Image/check.png" alt="" />
                                                        Ability to tag and trace materials and components in the
                                                        supply chain
                                                    </Typography>
                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="body1"
                                                        component={"p"}
                                                        sx={{
                                                            color: "#616161",
                                                            fontWeight: "normal",
                                                            marginTop: 1,
                                                            fontSize:'0.7rem'
                                                        }}
                                                    >
                                                        <img src="./Assets/Image/check.png" alt="" />
                                                        Real-time visibility into the journey of each tagged
                                                        material
                                                    </Typography>
                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="body1"
                                                        component={"p"}
                                                        sx={{
                                                            color: "#616161",
                                                            fontWeight: "normal",
                                                            marginTop: 1,
                                                            fontSize:'0.7rem'
                                                        }}
                                                    >
                                                        <img src="./Assets/Image/check.png" alt="" />
                                                        Basic reporting on material movement
                                                    </Typography>
                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="body1"
                                                        component={"p"}
                                                        sx={{
                                                            color: "#616161",
                                                            fontWeight: "normal",
                                                            marginTop: 1,
                                                            fontSize:'0.7rem'
                                                        }}
                                                    >
                                                        <img src="./Assets/Image/check.png" alt="" />
                                                        Email support
                                                    </Typography>
                                                    <SharedButton
                                                        // onClick={() => {
                                                        //     window.open(PlanOneUrl);
                                                        // }}
                                                        className="LandingBtnHover"
                                                        value={"Get Started"}
                                                        variant="contained"
                                                        sx={{
                                                            textAlign: "left",
                                                            marginTop: 5,
                                                            width: {
                                                                xs: "100%",
                                                                sm: "100%",
                                                                md: "100%",
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item sm>
                                        <Box
                                            sx={{
                                                background: "#E3DDFB",
                                                padding: 2,
                                                borderRadius: 5,
                                            }}
                                            className='PremiumCard'
                                        >
                                            <Grid container spacing={5} direction={"column"}>
                                                <Grid item>
                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="h3"
                                                        component={"h2"}
                                                        sx={{
                                                            color: "#0B1E5B",
                                                            fontWeight: "bold",
                                                        }}
                                                        className="customHeading"
                                                    >
                                                        $59{" "}
                                                        <Typography
                                                            textAlign={"left"}
                                                            variant="body1"
                                                            component={"span"}
                                                            style={{
                                                                fontWeight: "lighter",
                                                                fontSize: "1.2rem",
                                                                position: "relative",
                                                                bottom: "10px",
                                                            }}
                                                        >
                                                            {" "}
                                                            /month
                                                        </Typography>
                                                    </Typography>
                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="h5"
                                                        component={"h2"}
                                                        sx={{
                                                            color: "#0B1E5B",
                                                            fontWeight: "bold",
                                                            marginTop: 2,
                                                        }}
                                                    >
                                                        Tier 2 - Growth
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            background: "#F4F1FD",
                                                            padding: 2,
                                                            borderRadius: 2,
                                                            marginTop: 2,
                                                            marginBottom: 2,
                                                        }}
                                                    >
                                                        <Typography
                                                            textAlign={"left"}
                                                            variant="h6"
                                                            component={"h2"}
                                                            sx={{
                                                                color: "#9E9E9E",
                                                                fontWeight: "normal",
                                                                marginTop: 0,
                                                            }}
                                                        >
                                                            Materials Tagged
                                                        </Typography>
                                                        <Typography
                                                            textAlign={"left"}
                                                            variant="body1"
                                                            component={"h2"}
                                                            sx={{
                                                                color: "#616161",
                                                                fontWeight: "normal",
                                                                marginTop: 1,
                                                            }}
                                                        >
                                                            Up to 500 materials per month
                                                        </Typography>
                                                        <Typography
                                                            textAlign={"left"}
                                                            variant="body2"
                                                            component={"p"}
                                                            sx={{
                                                                color: "#616161",
                                                                fontWeight: "normal",
                                                                marginTop: 1,
                                                            }}
                                                        >
                                                            Includes Tier 1 features, plus
                                                        </Typography>
                                                    </Box>
                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="body1"
                                                        component={"p"}
                                                        sx={{
                                                            color: "#616161",
                                                            fontWeight: "normal",
                                                            marginTop: 1,
                                                            fontSize:'0.7rem'
                                                        }}
                                                    >
                                                        <img src="./Assets/Image/check.png" alt="" />
                                                        Blockchain integration for added security and
                                                        transparency.
                                                    </Typography>

                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="body1"
                                                        component={"p"}
                                                        sx={{
                                                            color: "#616161",
                                                            fontWeight: "normal",
                                                            marginTop: 1,
                                                            fontSize:'0.7rem'
                                                        }}
                                                    >
                                                        <img src="./Assets/Image/check.png" alt="" />
                                                        Advanced reporting and analytics for supply chain
                                                        optimization.
                                                    </Typography>
                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="body1"
                                                        component={"p"}
                                                        sx={{
                                                            color: "#616161",
                                                            fontWeight: "normal",
                                                            marginTop: 1,
                                                            fontSize:'0.7rem'
                                                        }}
                                                    >
                                                        <img src="./Assets/Image/check.png" alt="" />
                                                        The ability to create and manage smart contracts for
                                                        automated compliance.
                                                    </Typography>
                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="body1"
                                                        component={"p"}
                                                        sx={{
                                                            color: "#616161",
                                                            fontWeight: "normal",
                                                            marginTop: 1,
                                                            fontSize:'0.7rem'
                                                        }}
                                                    >
                                                        <img src="./Assets/Image/check.png" alt="" />
                                                        Priority email and chat support.
                                                    </Typography>
                                                    <SharedButton
                                                        // onClick={() => {
                                                        //     window.open(PlanTwoUrl);
                                                        // }}
                                                        className="LandingBtnHover"
                                                        value={"Get Started"}
                                                        variant="contained"
                                                        sx={{
                                                            textAlign: "left",
                                                            marginTop: 5,
                                                            width: {
                                                                xs: "100%",
                                                                sm: "100%",
                                                                md: "100%",
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item sm>
                                        <Box
                                            sx={{
                                                background: "#E3DDFB",
                                                padding: 2,
                                                borderRadius: 5,
                                            }}
                                            className='PremiumCard'
                                        >
                                            <Grid container spacing={5} direction={"column"}>
                                                <Grid item>
                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="h4"
                                                        component={"h2"}
                                                        sx={{
                                                            color: "#0B1E5B",
                                                            fontWeight: "bold",
                                                            marginBottom: 4,
                                                        }}
                                                        className="customHeading"
                                                    >
                                                        Custom Quote
                                                    </Typography>
                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="h5"
                                                        component={"h2"}
                                                        sx={{
                                                            color: "#0B1E5B",
                                                            fontWeight: "bold",
                                                            marginTop: 2,
                                                        }}
                                                    >
                                                        Tier 3 - Enterprise
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            background: "#F4F1FD",
                                                            padding: 2,
                                                            borderRadius: 2,
                                                            marginTop: 2,
                                                            marginBottom: 2,
                                                        }}
                                                    >
                                                        <Typography
                                                            textAlign={"left"}
                                                            variant="h6"
                                                            component={"h2"}
                                                            sx={{
                                                                color: "#9E9E9E",
                                                                fontWeight: "normal",
                                                                marginTop: 0,
                                                            }}
                                                        >
                                                            Materials Tagged
                                                        </Typography>
                                                        <Typography
                                                            textAlign={"left"}
                                                            variant="body1"
                                                            component={"h2"}
                                                            sx={{
                                                                color: "#616161",
                                                                fontWeight: "normal",
                                                                marginTop: 1,
                                                            }}
                                                        >
                                                            Customizable based on enterprise needs
                                                        </Typography>
                                                        <Typography
                                                            textAlign={"left"}
                                                            variant="body2"
                                                            component={"h2"}
                                                            sx={{
                                                                color: "#616161",
                                                                fontWeight: "normal",
                                                                marginTop: 1,
                                                            }}
                                                        >
                                                            Includes Tier 1 and Tier 2 features, plus
                                                        </Typography>
                                                    </Box>
                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="body1"
                                                        component={"p"}
                                                        sx={{
                                                            color: "#616161",
                                                            fontWeight: "normal",
                                                            marginTop: 1,
                                                            fontSize:'0.7rem'
                                                        }}
                                                    >
                                                        <img src="./Assets/Image/check.png" alt="" />
                                                        Dedicated account manager and 24/7 premium support
                                                    </Typography>
                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="body1"
                                                        component={"p"}
                                                        sx={{
                                                            color: "#616161",
                                                            fontWeight: "normal",
                                                            marginTop: 1,
                                                            fontSize:'0.7rem'
                                                        }}
                                                    >
                                                        <img src="./Assets/Image/check.png" alt="" />
                                                        Customizable dashboards and reporting tailored to
                                                        enterprise needs
                                                    </Typography>
                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="body1"
                                                        component={"p"}
                                                        sx={{
                                                            color: "#616161",
                                                            fontWeight: "normal",
                                                            marginTop: 1,
                                                            fontSize:'0.7rem'
                                                        }}
                                                    >
                                                        <img src="./Assets/Image/check.png" alt="" />
                                                        Integration support for complex supply chains.
                                                    </Typography>
                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="body1"
                                                        component={"p"}
                                                        sx={{
                                                            color: "#616161",
                                                            fontWeight: "normal",
                                                            marginTop: 1,
                                                            fontSize:'0.7rem'
                                                        }}
                                                    >
                                                        <img src="./Assets/Image/check.png" alt="" />
                                                        Advanced security features
                                                    </Typography>
                                                    <SharedButton
                                                        // onClick={() => {
                                                        //     window.open(PlanThreeUrl);
                                                        // }}
                                                        className="LandingBtnHover"
                                                        value={"Get Started"}
                                                        variant="contained"
                                                        sx={{
                                                            textAlign: "left",
                                                            marginTop: 5,
                                                            width: {
                                                                xs: "100%",
                                                                sm: "100%",
                                                                md: "100%",
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>

                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};
