import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import PhoneInput, { CountryData } from "react-phone-input-2";

interface MobileNumberInputProps {
  value: string;
  onChange: (
    value: string,
    data: {} | CountryData,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => void;
}

const MobileNumberInput = (props: MobileNumberInputProps) => {
  return (
    <div
      className="PhoneNumber"
      style={
        {
          // border: "1px solid blue",
        }
      }
    >
      <PhoneInput
        containerStyle={{
          color: "#767676",
          borderRadius: "8px",
          fontSize: "22px",
          backgroundColor: "#fff",
          borderLeft: "none",
          padding: 6,
          paddingLeft: 0,
          // border: "1px solid red",
        }}
        country={"us"} // Set the default country (e.g., United States)
        value={props.value} // Pass the value as state
        onChange={props.onChange} // Handle phone number changes
      />
    </div>
  );
};

export default MobileNumberInput;
