import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  FormGroup,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
// import ResponsiveAppBar from '../../SharedComponent/AppBar/ResponsiveAppBar';
import { Container, Grid, Typography } from "@mui/material";
import SharedButton from "../../SharedComponent/Button/SharedButton";
import Footer from "./Footer";
import CustomizedSteppers from "./CustomizedSteppers";
import { AiOutlineCheck, AiOutlineDollarCircle } from "react-icons/ai";
import { PiScanFill, PiShareNetworkFill } from "react-icons/pi";
import ResponsiveAppBar from "../../SharedComponent/AppBar/ResponsiveAppBar";
import { useDispatch } from "react-redux";
import { useSDK } from "@metamask/sdk-react";
import { authActions } from "../../store/auth";
import { AuthModel } from "../../models/authModel";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { login } from "../../networking/networkCalls";
import {
  PlanOneUrl,
  PlanThreeUrl,
  PlanTwoUrl,
} from "../../constants/constants";
import { getCurrentNetworkId } from "../../utils/web3";
import UnlockProduct from "./UnlockProduct";
import CaptureConnect from "./CaptureConnect";
import JoinThePlychain from "./JoinThePlychain";
import HowToGetStarted from "./HowToGetStarted";
import ContactUs from "./ContactUs";
import SliderTransparency from "./SliderTransparency";
import WaterBottleComp from "./WaterBottleComp";
import UnlockTransparency from "./UnlockTransparency";

export const LandingPage = () => {
  const [selectedGrid, setSelectedGrid] = useState(1); // Initialize with grid 1

  const { sdk } = useSDK();
  const navigate: NavigateFunction = useNavigate();
  const dispatch = useDispatch();

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const connect = async () => {
    try {
      const isCorrectNetworkId: boolean = await getCurrentNetworkId();
      // console.log("isCorrectNetworkId --> ",isCorrectNetworkId);
      if (isCorrectNetworkId !== true) {
        return;
      }

      const accounts = await sdk?.connect();
      // @ts-ignore
      if (accounts?.length > 0) {
        // hit login api and check if user/business is already registered

        const res = await login({
          wallet_address: accounts?.[0],
        });

        if (res) {
          if (res.status === 200 && res.data && res.data.success) {
            const { data } = res.data;
            if (res.data.data.isBusinessRegistered) {
              // business is already registered
              // navigate to dashboard

              // save login state and data in local storage
              localStorage.setItem("login", "1");
              localStorage.setItem("authData", JSON.stringify(data));

              // save login state and data in redux store
              dispatch(authActions.setLoginState(true));
              const authData: AuthModel = data;
              dispatch(authActions.setAuthData(authData));

              navigate("/dashboard", { replace: true });
            } else {
              // business is not registered
              // navigate to signup

              // save login state and data in local storage
              localStorage.setItem("authData", JSON.stringify(data));

              // save login state and data in redux store
              const authData: AuthModel = data;
              dispatch(authActions.setAuthData(authData));

              navigate("/signup");
            }
          }
        }
      }
    } catch (err) {
      console.warn(`failed to connect..`, err);
    }
  };

  const handleGridClick = (gridNumber) => {
    setSelectedGrid(gridNumber);
  };

  return (
    <>
      <ResponsiveAppBar />
      <Box
        sx={{
          background: "#F7F8F2",
          // p: {
          //   xs: 2,
          // },

          paddingTop: "97px",
        }}
      >
        {/* ------- Unlock Product Authenticity Start--------- */}
        <UnlockProduct onClick={connect} />

        {/* ------- Unlock Product Authenticity End--------- */}
        <CaptureConnect />
        {/* -------How to Get Started Start--------- */}
        <HowToGetStarted onClick={connect} />
        {/* -------How to Get Started End--------- */}

        {/* -------Water Bottle image Start--------- */}
        <WaterBottleComp />
        {/* -------Water Bottle image End--------- */}

        {/* -------Slider transparency Start--------- */}

        <SliderTransparency />

        {/* -------Slider transparency End--------- */}

        {/* -------Unlock the Power of Transparency Start--------- */}
        <UnlockTransparency onClick={connect} />

        {/* -------Unlock the Power of Transparency End--------- */}

        {/* -------Join the PlyChain Revolution Start--------- */}

        <JoinThePlychain />

        {/* -------Join the PlyChain Revolution End--------- */}

        {/* -------Contact US Start--------- */}
        <ContactUs />
        {/* -------Contact US End--------- */}
      </Box>

      <Footer />
    </>
  );
};
