import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ButtonBase,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSearchParams } from "react-router-dom";
import { getShipment } from "../../networking/networkCalls";
import { ShipmentModel } from "../../models/shipmentModel";
import CheckpointModel from "../../models/checkpointModel";
import { MdVerified } from "react-icons/md";

const Product = () => {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<ShipmentModel>(undefined);
  const [selectedImage, setSelectedImage] = useState<string>();
  const [imageDisplayed, setImageDisplayed] = useState(false);

  const [searchParams] = useSearchParams();
  const id = searchParams.get("sId");
  const source = searchParams.get("source");

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }

    const getData = async () => {
      const res = await getShipment({ id: parseInt(id), source });

      if (res) {
        if (res.status === 200) {
          const { data } = res.data;
          const shipment: ShipmentModel = new ShipmentModel(data);
          setData(shipment);

          setSelectedImage(shipment.Product?.thumbnails_url[0]);
        }
      }
    };

    getData();

    setLoading(false);
  }, []);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleImageClick = (newImageSrc) => {
    setSelectedImage(newImageSrc);
  };

  const buildProductHistoryCheckpointComponent = (
    date: string,
    country: string,
    address: string
  ) => {
    return (
      <Grid
        item
        sm
        sx={{
          mt: 1,
          mb: 1,
        }}
      >
        <Paper
          elevation={0}
          sx={{
            border: "1px solid #ddd",
            p: 2,
            textAlign: "left",
            // width:'100%',
            background: "transparent!important",
          }}
        >
          <Typography
            variant="body2"
            component={"p"}
            className="productnowrap"
            sx={{
              color: "#757575",
              fontWeight: "normal",
              textAlign: "left",
              fontSize: "12px",
            }}
          >
            {date}
          </Typography>
          <Typography
            variant="body1"
            component={"p"}
            className="productnowrap"
            sx={{
              color: "#0a0a0a",
              fontWeight: "normal",
              textAlign: "left",
              mt: 1,
              fontSize: "14px",
            }}
          >
            {country}
          </Typography>
          <Typography
            variant="body2"
            component={"p"}
            className="productnowrap"
            sx={{
              fontSize: "14px",
              color: "#757575",
              fontWeight: "normal",
              textAlign: "left",
              mt: 3,
            }}
          >
            {address}
          </Typography>
        </Paper>
      </Grid>
    );
  };

  const buildProductHistoryComponent = () => {
    return (
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        sx={{
          background: "#F7F8F2",
          boxShadow: "0px 0px 0px #000",
          border: "0px solid #000",
          borderRadius: 2,
          margin: "10px 0px 0px 0px",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />} // Add the expansion arrow
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <Typography>Product History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="producthistory">
            {/* Check if the image has not been displayed */}
            {/* {!imageDisplayed && (
             
            )} */}

            <Grid container direction="column">
              <Grid container direction={"row"}>
                <Grid item>
                  <img
                    src="Assets/Image/Mobilehistory.png"
                    alt=""
                    className="mobilehistory"
                  />
                </Grid>
                <Grid item xs={10}>
                  {buildProductHistoryCheckpointComponent(
                    `${data.getFormattedCreatedDate()}// ${data.getFormattedCreatedTime()}`,
                    `From ${data.Product.origin_country}`,
                    data.Product.origin_address
                  )}
                </Grid>
              </Grid>
              <Grid container direction="row">
                {data.checkpoints &&
                  data.checkpoints.map((v: CheckpointModel, i) => (
                    <React.Fragment key={i}>
                      <Grid item>
                        <img
                          src="Assets/Image/Mobilehistory.png"
                          alt=""
                          className="mobilehistory"
                        />
                      </Grid>
                      <Grid item xs={10}>
                        {buildProductHistoryCheckpointComponent(
                          `${v.getFormattedDate()}// ${v.time}`,
                          `To ${v.country}`,
                          v.address
                        )}
                      </Grid>
                    </React.Fragment>
                  ))}
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Box
      className={"MainProductBox"}
      sx={{
        display: {
          xs: "block",
          sm: "none",
          md: "none",
        },
        background: "#fff",
        padding: {
          xs: 2,
        },
      }}
    >
      <Box
        sx={{
          borderBottom: "1px solid #d0d0d0",
          p: 2,
        }}
      >
        <Grid container direction={"row"} spacing={0} style={{
          justifyContent:'center',
          alignItems:'center'
        }}>
          <Grid item sm>
            <Typography
              variant="h5"
              component={"p"}
              style={{
                color: "#0B1E5B",
                fontWeight: "semibold",
                textAlign: "center",
                fontSize: "18px",
              }}
            >
              <ButtonBase sx={{}}>
                {/* <img src="./Assets/Image/PlaychainIcon.png" alt="" /> */}
                <img
                  src={!loading && data && data.User && data.User.business_logo}
                  alt=""
                  className="productImage"
                />
              </ButtonBase>
              {!loading && data && data.User && data.User.name}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {loading && <CircularProgress />}
      <Typography
        variant="h5"
        component={"p"}
        sx={{
          color: "#0B1E5B",
          fontWeight: "normal",
          textAlign: "center",
          margin: "10px 0px",
        }}
      >
        Product Passport
      </Typography>
      <Typography
        variant="h5"
        component={"p"}
        sx={{
          color: "#0B1E5B",
          fontWeight: "normal",
          textAlign: "center",
          margin: "10px 0px",
          fontSize: "16px",
        }}
      >
        {!loading && data && data.generated_shipment_id}
      </Typography>

      {!loading && data && (
        <div>
          <Box>
            <Grid container>
              <Grid item sm>
                <Grid container>
                  <Grid item md={12} sm={12}>
                    <ButtonBase
                      sx={{
                        textAlign: "center",
                        width: "100%",
                        margin: "10px auto",
                      }}
                    >
                      <img src={selectedImage} alt="" className="bigimg" />
                    </ButtonBase>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction={"row"}
                  sx={{
                    justifyContent: "start",
                    gap: "10px",
                    margin: "10px 0px",
                  }}
                >
                  {data.Product?.thumbnails_url.map((v, i) => {
                    return (
                      <Grid item xs={3} sm>
                        <ButtonBase
                          onClick={() => handleImageClick(v)}
                          sx={{
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          <img src={v} alt="" className="ProductImg" />
                        </ButtonBase>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={{
                background: "#F7F8F2",
                boxShadow: "0px 0px 0px #000",
                border: "0px solid #000",
                borderRadius: 2,
                margin: "10px 0px 0px 0px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />} // Add the expansion arrow
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>Product Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item sm>
                    <Typography
                      variant="h6"
                      component={"p"}
                      sx={{
                        color: "#757575",
                        fontWeight: "semibold",
                        textAlign: "left",
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "center",
                      }}
                    >
                      {data.Product?.name}
                      <span className="verifyicon">
                        <MdVerified /> Authentic
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction={"row"} spacing={0}>
                  {/* <Grid
                    item
                    xs={6}
                    sm
                    direction={"column"}
                    sx={{
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant="body1"
                      component={"p"}
                      sx={{
                        color: "#757575",
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      MATERIAL
                    </Typography>
                    <Typography
                      variant="body1"
                      component={"p"}
                      sx={{
                        color: "#000",
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      100% Cotton
                    </Typography>
                  </Grid> */}
                  <Grid
                    item
                    xs={6}
                    sm
                    direction={"column"}
                    sx={{
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant="body1"
                      component={"p"}
                      sx={{
                        color: "#757575",
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      ORIGIN
                    </Typography>
                    <Typography
                      variant="body1"
                      component={"p"}
                      sx={{
                        color: "#000",
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      {data.Product.origin_country}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction={"row"} spacing={0}>
                  <Grid
                    item
                    xs={6}
                    sm
                    direction={"column"}
                    sx={{
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant="body1"
                      component={"p"}
                      sx={{
                        color: "#757575",
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      PRODUCER
                    </Typography>
                    <Typography
                      variant="body1"
                      component={"p"}
                      sx={{
                        color: "#000",
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      {data.User.name}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm
                    direction={"column"}
                    sx={{
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant="body1"
                      component={"p"}
                      sx={{
                        color: "#757575",
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      DATE PRODUCED
                    </Typography>
                    <Typography
                      variant="body1"
                      component={"p"}
                      sx={{
                        color: "#000",
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      {data.Product?.getFormattedCreatedDate()}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction={"row"} spacing={0}>
                  <Grid
                    item
                    sm
                    direction={"column"}
                    sx={{
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant="body1"
                      component={"p"}
                      sx={{
                        color: "#757575",
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      CONDITION
                    </Typography>
                    <Typography
                      variant="body1"
                      component={"p"}
                      sx={{
                        color: "#000",
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      {data.Product?.condition.toUpperCase()}
                    </Typography>
                  </Grid>
                  {/* <Grid
                    item
                    xs={6}
                    sm
                    direction={"column"}
                    sx={{
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant="body1"
                      component={"p"}
                      sx={{
                        color: "#757575",
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      RECYCLABLE
                    </Typography>
                    <Typography
                      variant="body1"
                      component={"p"}
                      sx={{
                        color: "#000",
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      Fully Recyclable
                    </Typography>
                  </Grid> */}
                </Grid>
                <Grid container>
                  <Grid item sm>
                    <Typography
                      variant="h6"
                      component={"p"}
                      sx={{
                        color: "#757575",
                        fontWeight: "semibold",
                        textAlign: "left",
                        mb: "15px",
                      }}
                    >
                      Description
                    </Typography>
                    <Typography
                      variant="body1"
                      component={"p"}
                      sx={{
                        color: "#0a0a0a",
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      {data.Product?.description}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              sx={{
                background: "#F7F8F2",
                boxShadow: "0px 0px 0px #000",
                border: "0px solid #000",
                borderRadius: 2,
                margin: "10px 0px 0px 0px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />} // Add the expansion arrow
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>Product Story</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body1"
                  component={"p"}
                  sx={{
                    color: "#0B1E5B",
                    fontWeight: "normal",
                    textAlign: "left",
                    margin: "10px 0px",
                  }}
                >
                  {data.Product.product_story}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              sx={{
                background: "#F7F8F2",
                boxShadow: "0px 0px 0px #000",
                border: "0px solid #000",
                borderRadius: 2,
                margin: "10px 0px 0px 0px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />} // Add the expansion arrow
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>Reuse or Recycle information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body1"
                  component={"p"}
                  sx={{
                    color: "#0B1E5B",
                    fontWeight: "normal",
                    textAlign: "left",
                    margin: "10px 0px",
                  }}
                >
                  {data.Product.reuse_recycle_info}
                </Typography>
              </AccordionDetails>
            </Accordion>
            {/* {data.Shipment &&
              data.Shipment.length > 0 &&
              data.Shipment.map((v, i) => {
                return buildProductHistoryComponent(v);
              })} */}
            {buildProductHistoryComponent()}
          </Box>
        </div>
      )}
    </Box>
  );
};

export default Product;
