import React, { useState, useEffect } from "react";
import { Box, List } from "@mui/material";
import { Container, Typography } from "@mui/material";
import ResponsiveAppBar from "../../SharedComponent/AppBar/ResponsiveAppBar";
import Footer from "./Footer";
import { moveToTop } from "../../utils/utils";

const PrivacyPolicy = () => {
useEffect(()=>{
moveToTop()
},[])
  const baseClass = {
    lineHeight: 1.6,
    textAlign: "left",
    marginTop: 2.8,
    fontFamily: "nexa",
    width: "100%",
    fontSize: {
      xs: "24px",
      sm: "28px",
      md: "32px",
      lg: "32px",
    },
  };
  const basePClass = {
    fontFamily: "inter",
    fontWeight: "400",
    textAlign: "left",
    fontSize: "16px",
    width: "100%",
    marginBottom: 1,
    listStyleType: "none",
  };

  const baseClass3 = {
    fontFamily: "inter",
    fontWeight: "400",
    textAlign: "left",
    fontSize: "20px",
    width: "100%",
    marginBottom: 1,
    listStyleType: "none",
  };

  const baseClass4 = {
    ...baseClass3,
    marginLeft: 0,
  };

  return (
    <>
      <ResponsiveAppBar />
      <Container>
        <Box
          sx={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="h1"
            variant="h3"
            color="#0b1e5b"
            sx={{
              fontFamily: "nexa",
              width: "100%",

              fontSize: {
                xs: "28px",
                md: "36px",
                lg: "48px",
              },
              padding: {
                xs: "0",
              },
            }}
          >
            Privacy Policy
          </Typography>
          <Typography
            color="#0b1e5b"
            component="p"
            sx={{
              width: {
                xs: "100%",
                sm: "50%",
                md: "30%",
                lg: "30%",
                xl: "30%",
              },
              borderRadius: 1,
              padding: 1,
              marginBottom: 4,
              marginTop: 2,
              border: "1px solid #0b1e5b",
            }}
          >
            Effective from November 1, 2023
          </Typography>
          <Typography variant="body1" color="#0b1e5b" sx={basePClass}>
            At Plychain, we take your privacy seriously. Please read this
            Privacy Policy to learn how we treat your personal data. By using or
            accessing our Services in any manner, you acknowledge that you
            accept the practices and policies outlined below, and you hereby
            consent that we will collect, use, and share your information as
            described in this Privacy Policy. Remember that your use of
            Plychain's Services is at all times subject to our Terms and
            Conditions, which incorporates this Privacy Policy. Any terms we use
            in this Policy without defining them have the definitions given to
            them in the Terms and Conditions.
          </Typography>

          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={baseClass}
          >
            What this Privacy Policy Covers
          </Typography>

          <Typography color="#0b1e5b" component="li" sx={basePClass}>
            This Privacy Policy covers how we treat Personal Data that we gather
            when you access or use our Services. “Personal Data” means any
            information that identifies or relates to a particular individual
            and also includes information referred to as “personally
            identifiable information” or “personal information” under applicable
            data privacy laws, rules or regulations. This Privacy Policy does
            not cover the practices of companies we don’t own or control or
            people we don’t manage.
          </Typography>

          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={baseClass}
          >
            Personal Data
            <Typography
              color="#0b1e5b"
              component="li"
              sx={{ ...baseClass3, fontSize: "16px" }}
            >
              This Privacy Policy covers how we treat Personal Data that we
              gather when you access or use our Services. “Personal Data” means
              any information that identifies or relates to a particular
              individual and also includes information referred to as
              “personally identifiable information” or “personal information”
              under applicable data privacy laws, rules or regulations. This
              Privacy Policy does not cover the practices of companies we don’t
              own or control or people we don’t manage.
            </Typography>
          </Typography>

          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={baseClass}
          >
            Personal Data
          </Typography>

          <Typography
            variant="body1"
            color="#0b1e5b"
            component="p"
            sx={basePClass}
          >
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="p"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
                listStyleType: "none",
                marginLeft: 0,
              }}
            >
              Categories of Personal Data We Collect
            </Typography>
            Below details the categories of personal data that we collect and
            have collected over the past 12 months.
            <br />
            Profile or Contact Data
          </Typography>

          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={baseClass}
          >
            ‍What are the basics of using Plychain?
            <List>
              <Typography
                variant="body1"
                color="#0b1e5b"
                component="li"
                fontSize="20px"
              >
                1. Examples of Personal Data We Collect
                <List>
                  <Typography
                    variant="body1"
                    color="#0b1e5b"
                    component="li"
                    fontSize="16px"
                  >
                    (a) First and last name
                  </Typography>
                  <Typography variant="body1" color="#0b1e5b" fontSize="16px">
                    (b) Email
                  </Typography>
                  <Typography
                    variant="body1"
                    color="#0b1e5b"
                    component="li"
                    fontSize="16px"
                  >
                    (c) Phone number
                  </Typography>
                  <Typography
                    variant="body1"
                    color="#0b1e5b"
                    component="li"
                    fontSize="16px"
                  >
                    (d) Title
                  </Typography>
                  <Typography
                    variant="body1"
                    color="#0b1e5b"
                    component="li"
                    fontSize="16px"
                  >
                    (e) Unique identifiers such as passwords
                  </Typography>
                </List>
              </Typography>

              <Typography
                variant="body1"
                color="#0b1e5b"
                component="li"
                fontSize="20px"
              >
                Categories of Third Parties with whom we Share this Personal
                Data
                <List>
                  <Typography
                    variant="body1"
                    color="#0b1e5b"
                    component="li"
                    fontSize="16px"
                  >
                    (a) Service providers
                  </Typography>
                  <Typography
                    variant="body1"
                    color="#0b1e5b"
                    component="li"
                    fontSize="16px"
                  >
                    (b) Parties you authorize, access, or authenticate
                  </Typography>
                </List>
              </Typography>
            </List>
            <List>
              <Typography
                variant="body1"
                color="#0b1e5b"
                component="li"
                fontSize="20px"
              >
                2. Payment Data
                <List>
                  <Typography
                    variant="body1"
                    color="#0b1e5b"
                    component="li"
                    fontSize="16px"
                  >
                    Examples of Personal Data We Collect
                    <List>
                      <Typography
                        variant="body1"
                        color="#0b1e5b"
                        component="li"
                      >
                        (a) Bank account information
                      </Typography>
                      <Typography
                        variant="body1"
                        color="#0b1e5b"
                        component="li"
                      >
                        (b) Billing address
                      </Typography>
                      <Typography
                        variant="body1"
                        color="#0b1e5b"
                        component="li"
                      >
                        (c) Phone number
                      </Typography>
                      <Typography
                        variant="body1"
                        color="#0b1e5b"
                        component="li"
                      >
                        (d) Email
                      </Typography>
                    </List>
                  </Typography>

                  <Typography
                    variant="body1"
                    color="#0b1e5b"
                    component="li"
                    fontSize="20px"
                  >
                    Categories of Third Parties with whom we Share this Personal
                    Data
                    <List>
                      <Typography
                        variant="body1"
                        color="#0b1e5b"
                        component="li"
                      >
                        (a) Service providers (specifically our payment
                        processing partner)
                      </Typography>
                    </List>
                  </Typography>
                </List>
              </Typography>
            </List>
            <List>
              <Typography
                variant="body1"
                color="#0b1e5b"
                component="li"
                fontSize="20px"
              >
                3. Device/IP Data
                <List>
                  <Typography
                    variant="body1"
                    color="#0b1e5b"
                    component="li"
                    fontSize="20px"
                  >
                    Examples of Personal Data We Collect
                    <List>
                      <Typography
                        variant="body1"
                        color="#0b1e5b"
                        component="li"
                      >
                        (a) IP address
                      </Typography>
                    </List>
                  </Typography>

                  <Typography
                    variant="body1"
                    color="#0b1e5b"
                    component="li"
                    fontSize="20px"
                  >
                    Categories of Third Parties with whom we Share this Personal
                    Data
                    <List>
                      <Typography
                        variant="body1"
                        color="#0b1e5b"
                        component="li"
                      >
                        (a) Service providers
                      </Typography>
                    </List>
                  </Typography>
                </List>
              </Typography>
            </List>
            <List>
              <Typography
                variant="body1"
                color="#0b1e5b"
                component="li"
                fontSize="20px"
              >
                4. Geolocation Data
                <List>
                  <Typography
                    variant="body1"
                    color="#0b1e5b"
                    component="li"
                    fontSize="20px"
                  >
                    Examples of Personal Data We Collect
                    <List>
                      <Typography
                        variant="body1"
                        color="#0b1e5b"
                        component="li"
                      >
                        (a) IP-address-based location information
                      </Typography>
                    </List>
                  </Typography>

                  <Typography
                    variant="body1"
                    color="#0b1e5b"
                    component="li"
                    fontSize="20px"
                  >
                    Categories of Third Parties with whom we Share this Personal
                    Data
                    <List>
                      <Typography
                        variant="body1"
                        color="#0b1e5b"
                        component="li"
                      >
                        (a) Service providers
                      </Typography>
                      <Typography
                        variant="body1"
                        color="#0b1e5b"
                        component="li"
                      >
                        (b) Parties you authorize, access, or authenticate
                      </Typography>
                    </List>
                  </Typography>
                </List>
              </Typography>
            </List>
          </Typography>

          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={baseClass}
          >
            Categories of Sources of Personal Data
          </Typography>

          <Typography
            variant="body1"
            color="#0b1e5b"
            component="li"
            sx={{
              fontFamily: "inter",
              fontWeight: "400",
              textAlign: "left",
              fontSize: "20px",
              width: "100%",
              marginBottom: 1,
              listStyleType: "none",
              marginLeft: 0,
            }}
          >
            We collect Personal Data about you from the following categories of
            sources:
          </Typography>

          <Typography
            variant="body1"
            color="#0b1e5b"
            component="p"
            sx={{
              fontFamily: "nexa",
              fontWeight: "500",
              textAlign: "left",
              fontSize: "24px",
              width: "100%",
              marginBottom: 1,
              listStyleType: "none",
              marginLeft: 0,
            }}
          >
            You
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              sx={baseClass3}
            >
              <ul>
                1. When you provide such information directly to us.
                <Box
                  sx={{
                    marginLeft: "24px",
                    fontSize: "16px",
                  }}
                >
                  ● When you use our interactive tools and Services.
                  <br />● When you voluntarily provide information in free-form
                  text boxes through the Services or through responses to
                  surveys or questionnaires.
                  <br /> ● When you send us an email or otherwise contact us.
                </Box>
              </ul>
            </Typography>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              sx={baseClass3}
            >
              <ul>
                2. When you use the Services and such information is collected
                automatically.
                <Box
                  sx={{
                    marginLeft: "24px",
                    fontSize: "16px",
                  }}
                >
                  ● If you download our mobile application or use a
                  location-enabled browser, we may receive information about
                  your location and mobile device, as applicable.
                  <br />● If you use, download, or install certain applications
                  and software we make available, we may receive and collect
                  information transmitted from your computing device for the
                  purpose of providing you the relevant Services.
                </Box>
              </ul>
            </Typography>
          </Typography>

          <Typography
            variant="body1"
            color="#0b1e5b"
            component="p"
            sx={{
              fontFamily: "nexa",
              fontWeight: "500",
              textAlign: "left",
              fontSize: "24px",
              width: "100%",
              marginBottom: 1,
              marginTop: 2,
              listStyleType: "none",
              marginLeft: 0,
            }}
          >
            Third Parties
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              sx={baseClass3}
            >
              <ul>
                1. Vendors
                <Box
                  sx={{
                    marginLeft: "24px",
                    fontSize: "16px",
                  }}
                >
                  ● We may use analytics providers to analyze how you interact
                  and engage with the website, Services, or third parties, and
                  third parties engaged by us may collect Personal Data about
                  you in the course of helping us provide you with customer
                  support and other services.
                </Box>
              </ul>
            </Typography>
          </Typography>

          <Typography
            variant="body1"
            color="#0b1e5b"
            component="p"
            sx={{
              fontFamily: "nexa",
              fontWeight: "500",
              textAlign: "left",
              fontSize: "24px",
              width: "100%",
              marginBottom: 1,
              marginTop: 2,
              listStyleType: "none",
              marginLeft: 0,
            }}
          >
            Our Commercial or Business Purposes for Collecting Personal Data
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              sx={baseClass3}
            >
              <ul>
                1. Providing, Customizing, and Improving the Services
                <Box
                  sx={{
                    marginLeft: "24px",
                    fontSize: "16px",
                  }}
                >
                  ● Processing orders or other transactions; billing.
                  <br />
                  ● Providing you with the products, services, or information
                  you request.
                  <br />
                  ● Meeting or fulfilling the reason you provided the
                  information to us.
                  <br />
                  ● Providing support and assistance for the Services.
                  <br />
                  ● Improving the Services, including testing, research,
                  internal analytics, and product development.
                  <br />
                  ● Personalizing the Services, website content, and
                  communications based on your preferences.
                  <br />
                  ● Supporting fraud protection, security, and debugging.
                  <br />● Carrying out other business purposes stated when
                  collecting your Personal Data or as otherwise set forth in
                  applicable data privacy laws, such as the California Consumer
                  Privacy Act of 2018, as amended by the California Privacy
                  Rights Act of 2020 (the “CPRA”) and the E.U. or UK General
                  Data Protection Regulation (“GDPR”).
                </Box>
                2. Marketing the Services
                <Box
                  sx={{
                    marginLeft: "24px",
                    fontSize: "16px",
                  }}
                >
                  ● Marketing and selling the Services.
                </Box>
                3. Corresponding with You
                <Box
                  sx={{
                    marginLeft: "24px",
                    fontSize: "16px",
                  }}
                >
                  ● Responding to correspondence that we receive from you,
                  contacting you when necessary or requested, and sending you
                  information about Plychain or the Services.
                  <br />● Sending emails and other communications according to
                  your preferences or that display content that we think will
                  interest you.
                </Box>
                4. Meeting Legal Requirements and Enforcing Legal Terms
                <Box
                  sx={{
                    marginLeft: "24px",
                    fontSize: "16px",
                  }}
                >
                  ● Fulfilling our legal obligations under applicable law,
                  regulation, court order or other legal process, such as
                  preventing, detecting, and investigating security incidents
                  and potentially illegal or prohibited activities.
                  <br />
                  ● Protecting the rights, property or safety of you, Plychain,
                  or another party.
                  <br />
                  ● Enforcing any agreements with you.
                  <br />
                  ● Responding to claims that any posting or other content
                  violates third-party rights.
                  <br />
                  ● Resolving disputes.
                  <br />
                </Box>
                We will not collect additional categories of Personal Data or
                use the Personal Data we collected for materially different,
                unrelated, or incompatible purposes without providing you
                notice.
              </ul>
            </Typography>
          </Typography>
        </Box>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="p"
          sx={{
            fontFamily: "inter",
            fontWeight: "500",
            textAlign: "left",
            fontSize: "24px",
            width: "100%",
            marginBottom: 1,
            listStyleType: "none",
            marginLeft: 0,
          }}
        >
          How We Share Your Personal Data
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="p"
          sx={{
            fontFamily: "inter",
            fontWeight: "400",
            textAlign: "left",
            fontSize: "16px",
            width: "100%",
            marginBottom: 1,
            listStyleType: "none",
          }}
        >
          We disclose your Personal Data to the categories of service providers
          and other parties listed in this section. For more information, please
          refer to the state-specific sections below.{" "}
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="h2"
          sx={baseClass}
        >
          Service Providers.
        </Typography>

        <Typography
          variant="body1"
          color="#0b1e5b"
          component="p"
          sx={{
            fontFamily: "nexa",
            fontWeight: "400",
            textAlign: "left",
            fontSize: "20px",
            width: "100%",
            marginBottom: 1,
            listStyleType: "none",
            marginLeft: 0,
          }}
        >
          These parties help us provide the Services or perform business
          functions on our behalf. They include:
          <List>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              fontSize="16px"
            >
              (a) Hosting, technology, and communication providers.
            </Typography>
          </List>
          <List>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              fontSize="16px"
            >
              (b) Data enhancement.
            </Typography>
          </List>
          <List>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              fontSize="16px"
            >
              (c) Payment processors.
            </Typography>
          </List>
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="p"
          sx={baseClass4}
        >
          Legal Obligations
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="p"
            sx={{ ...baseClass4, fontSize: "16px" }}
          >
            <br />
            We may share any Personal Data that we collect with third parties in
            conjunction with any of the activities set forth under “Meeting
            Legal Requirements and Enforcing Legal Terms” in the “Our Commercial
            or Business Purposes for Collecting Personal Data” section above.
            Business Transfers
            <br />
            All of your Personal Data that we collect may be transferred to a
            third party if we undergo a merger, acquisition, bankruptcy, or
            other transaction in which that third party assumes control of our
            business (in whole or in part). Should one of these events occur, we
            will make reasonable efforts to notify you before your information
            becomes subject to different privacy and security policies and
            practices. Data that is Not Personal Data <br />
            We may create aggregated, de-identified, or anonymized data from the
            Personal Data we collect, including by removing information that
            makes the data personally identifiable to a particular user. We may
            use such aggregated, de-identified, or anonymized data and share it
            with third parties for our lawful business purposes, including to
            analyze, build and improve the Services and promote our business,
            provided that we will not share such data in a manner that could
            identify you.
          </Typography>
        </Typography>

        <Typography
          variant="body1"
          color="#0b1e5b"
          component="h2"
          sx={baseClass}
        >
          Data Security and Retention
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="p"
          sx={{ ...baseClass4, fontSize: "16px" }}
        >
          We seek to protect your Personal Data from unauthorized access, use
          and disclosure using appropriate physical, technical, organizational,
          and administrative security measures based on the type of Personal
          Data and how we are processing that data. Although we work to protect
          the security of your data that we hold in our records, please be aware
          that no method of transmitting data over the internet or storing data
          is completely secure. We retain Personal Data about you for as long as
          necessary to provide you with our Services. In some cases, we retain
          Personal Data for longer, if doing so is necessary to comply with our
          legal obligations, resolve disputes or collect fees owed, or is
          otherwise permitted or required by applicable law, rule or regulation.
          We may further retain information in an anonymous or aggregated form
          where that information would not identify you personally.
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="h2"
          sx={baseClass}
        >
          Personal Data of Children
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="p"
          sx={{ ...baseClass4, fontSize: "16px" }}
        >
          As noted in the Terms and Conditions, we do not knowingly collect or
          solicit Personal Data about children under 16 years of age; if you are
          a child under the age of 16, please do not attempt to register for or
          otherwise use the Services or send us any Personal Data. If we learn
          we have collected Personal Data from a child under 16 years of age, we
          will delete that information as quickly as possible. If you believe
          that a child under 16 years of age may have provided Personal Data to
          us, please contact us at contact@plychain.io.
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="h2"
          sx={baseClass}
        >
          Your Choices
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="p"
          sx={{ ...baseClass4, fontSize: "16px" }}
        >
          If you use one of our Services, you can stop all collection of
          information by the Service by ceasing to use it. If you would like to
          modify or delete certain Personal Data provided to us, you may be able
          to do so via the Service (though please note that we may require
          certain Personal Data in order to provide the Service).
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="h2"
          sx={baseClass}
        >
          California Resident Rights
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="p"
          sx={{ ...baseClass4, fontSize: "16px" }}
        >
          If you are a California resident, you have the rights set forth in
          this section, subject to certain limitations. Please see the
          “Exercising Your Rights” section below for instructions regarding how
          to exercise these rights. Please note that we may process Personal
          Data of our customers’ end users or employees in connection with our
          provision of certain services to our customers. If we are processing
          your Personal Data as a service provider, you should contact the
          entity that collected your Personal Data in the first instance to
          address your rights with respect to such data. If there are any
          conflicts between this section and any other provision of this Privacy
          Policy and you are a California resident, the portion that is more
          protective of Personal Data shall control to the extent of such
          conflict. If you have any questions about this section or whether any
          of the following rights apply to you, please contact us at
          contact@plychain.io.
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="li"
          fontSize="20px"
          sx={{ ...baseClass4, fontSize: "16px" }}
        >
          1. Access <br />
          You have the right to request the following information about our
          collection and use of your Personal Data over the past 12 months:
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="li"
            sx={{ ...baseClass3, fontSize: "16px" }}
          >
            <ul>
              <Box
                sx={{
                  marginLeft: "24px",
                }}
              >
                ● The categories of Personal Data that we have collected about
                you.
                <br />
                ● The categories of sources from which that Personal Data was
                collected.
                <br />
                ● The categories of third parties with whom we have shared your
                Personal Data.
                <br />● The specific pieces of Personal Data that we have
                collected about you.
              </Box>
              If we have disclosed your Personal Data to any third parties for a
              business purpose over the past 12 months, upon request we will
              identify the categories of Personal Data shared with each category
              of third party recipient.
            </ul>
            <br />
            2. Deletion
            <br />
            You have the right to request that we delete the Personal Data that
            we have collected about you. Under the CPRA, this right is subject
            to certain exceptions: for example, we may need to retain your
            Personal Data to provide you with the Services or complete a
            transaction or other action you have requested, if deletion of your
            Personal Data involves disproportionate effort, or to comply with
            applicable law. If your deletion request is subject to one of these
            exceptions, we may deny your deletion request.
            <br />
            3. Correction
            <br />
            You have the right to request that we correct any inaccurate
            Personal Data we have collected about you. Under the CPRA, this
            right is subject to certain exceptions: for example, if we decide,
            based on the totality of circumstances related to your Personal
            Data, that such data is correct. If your correction request is
            subject to one of these exceptions, we may deny your request.
            <br />
            4. Exercising Your Rights
            <br />
            To exercise certain of the rights described above, please see “Your
            Choices” above. Alternatively, you may submit a request using the
            following methods:
            <ul>
              <Box
                sx={{
                  marginLeft: "24px",
                }}
              >
                ● Email us at: contact@plychain.io
                <br />
              </Box>
            </ul>
            Please note that we may require additional information from you in
            order to honor your request, and there may be circumstances in which
            we will not be able to honor your request
            <br />
            5. Personal Data Sales Opt-Out and Opt-In
            <br />
            We do not sell your Personal Data.
            <br />
            Personal Data Sharing Opt-Out and Opt-In
            <br />
            Under the CPRA, California residents have certain rights when a
            business “shares” Personal Data with third parties for purposes of
            cross-contextual behavioral advertising. We do not share your
            Personal Data for cross-contextual behavioral advertising. To our
            knowledge, we do not share the Personal Data of minors under 16
            years of age.
            <br />
            6. We Will Not Discriminate Against You for Exercising Your Rights
            Under the CPRA
            <br />
            Consumers have the right not to be discriminated against for
            exercising their CPRA rights. We will not deny you our goods or
            services, charge you different prices or rates, or provide you a
            lower quality of goods and services if you exercise your rights
            under the CPRA.
          </Typography>
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="h2"
          sx={baseClass}
        >
          Exercising Your Rights under CPRA
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="li"
          fontSize="20px"
          sx={{ ...baseClass4, marginBottom: 0, fontSize: "16px" }}
        >
          To exercise the rights described in this Privacy Policy, you or, if
          you are a California resident, your Authorized Agent (defined below)
          must send a request that (1) provides sufficient information to allow
          us to verify that you are the person about whom we have collected
          Personal Data, and (2) describes your request in sufficient detail to
          allow us to understand, evaluate and respond to it. Each request that
          meets both of these criteria will be considered a “Valid Request”. We
          may not respond to requests that do not meet these criteria. We will
          only use Personal Data provided in a Valid Request to verify your
          identity and complete your request. You do not need an account to
          submit a Valid Request. We will work to respond to your Valid Request
          within the time period required by applicable law. We will not charge
          you for a fee for making a Valid Request unless your Valid Request(s)
          is excessive, repetitive, or manifestly unfounded. If we determine
          that your Valid Request warrants a fee, we will notify you of the fee
          and explain that decision before completing your request.
          <br />
          You may submit a Valid Request using the following method:
          <ul>
            <Box
              sx={{
                marginLeft: "24px",
              }}
            >
              ● Email us at: contact@plychain.io
            </Box>
          </ul>
          If you are a California resident, you may also authorize an agent (an
          “Authorized Agent”) to exercise your rights on your behalf. To do
          this, you must provide your Authorized Agent with written permission
          to exercise your rights on your behalf, and we may request a copy of
          this written permission from your Authorized Agent when they make a
          request on your behalf.
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="h2"
          sx={baseClass}
        >
          Other State Law Privacy Rights
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="li"
          fontSize="20px"
          sx={{ ...baseClass4, marginBottom: 0, fontSize: "16px" }}
        >
          1. California Resident Rights
          <br />
          Under California Civil Code Sections 1798.83-1798.84, California
          residents are entitled to contact us to prevent disclosure of Personal
          Data to third parties for such third parties’ direct marketing
          purposes; in order to submit such a request, please contact us at
          contact@plychain.io.
          <br />
          2. Nevada Resident Rights
          <br />
          If you are a resident of Nevada, you have the right to opt-out of the
          sale of certain Personal Data to third parties who intend to license
          or sell that Personal Data. Please note, we do not believe we “sell”
          Personal Data as that term is defined under Nevada Revised Statutes
          chapter 603A (Security and Privacy of Personal Information).
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="h2"
          sx={baseClass}
        >
          European Union, European Economic Area, and United Kingdom Data
          Subject Rights
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="li"
          fontSize="20px"
          sx={{ ...baseClass4, marginBottom: 0, fontSize: "16px" }}
        >
          1. EU, EEA, and UK Residents
          <br />
          If you are a resident of the European Union (“EU”), European Economic
          Area (“EEA”) Lichtenstein, Norway, Sweden, or Iceland, or the United
          Kingdom (“UK”), you may have additional rights under the EU or UK
          General Data Protection Regulation (the “GDPR”) with respect to your
          Personal Data, as outlined below. For this section, we use the terms
          “Personal Data” and “processing” as they are defined in the GDPR, but
          “Personal Data” generally means information that can be used to
          individually identify a person, and “processing” generally covers
          actions that can be performed in connection with data such as
          collection, use, storage and disclosure. For Personal Data collected
          through our website plychain.io, Plychain will be the controller of
          your Personal Data. If there are any conflicts between this section
          and any other provision of this Privacy Policy, the policy or portion
          that is more protective of Personal Data shall control to the extent
          of such conflict. If you have any questions about this section or
          whether any of the following applies to you, please contact us at
          contact@plychain.io. Note that we may also process Personal Data of
          our customers’ end users or employees in connection with our provision
          of certain services to customers, in which case we are the processor
          of Personal Data. If we are the processor of your Personal Data (i.e.,
          not the controller), please contact the controller party in the first
          instance to address your rights with respect to such data.
          <br />
          2. Personal Data We Collect
          <br />
          The “Categories of Personal Data We Collect” section above details the
          Personal Data that we collect from you.
          <br />
          3. Personal Data Use and Processing Grounds The “Our Commercial or
          Business Purposes for Collecting Personal Data” section above explains
          how we use your Personal Data. We will only process your Personal Data
          if we have a lawful basis for doing so. Lawful bases for processing
          include consent, contractual necessity and our “legitimate interests”
          or the legitimate interest of others, as further described below.
          <br />
          (a) Contractual Necessity: We process the following categories of
          Personal Data as a matter of “contractual necessity”, meaning that we
          need to process the data to perform under our Terms and Conditions
          with you, which enables us to provide you with the Services. When we
          process data due to contractual necessity, failure to provide such
          Personal Data will result in your inability to use some or all
          portions of the Services that require such data.
          <ul>
            <Box
              sx={{
                marginLeft: "24px",
              }}
            >
              ● Profile or Contact Data
              <br />
              ● Payment Data
              <br />
              ● Device/IP Data
              <br />● Geolocation Data
            </Box>
          </ul>
          (b) Legitimate Interest: We process the following categories of
          Personal Data when we believe it furthers the legitimate interest of
          us or third parties
          <ul>
            <Box
              sx={{
                marginLeft: "24px",
              }}
            >
              ● Profile or Contact Data
              <br />
              ● Payment Data
              <br />
              ● Device/IP Data
              <br />
              ● Geolocation Data
              <br />● We may also de-identify or anonymize Personal Data to
              further our legitimate interests.
            </Box>
          </ul>
          Examples of these legitimate interests include (as described in more
          detail above):
          <ul>
            <Box
              sx={{
                marginLeft: "24px",
              }}
            >
              ● Providing, customizing and improving the Services.
              <br />
              ● Corresponding with you.
              <br />
              ● Meeting legal requirements and enforcing legal terms.
              <br />● Completing corporate transactions.
            </Box>
          </ul>
          (c) Consent: In some cases, we process Personal Data based on the
          consent you expressly grant to us at the time we collect such data.
          When we process Personal Data based on your consent, it will be
          expressly indicated to you at the point and time of collection. (d)
          Other Processing Grounds: From time to time we may also need to
          process Personal Data to comply with a legal obligation, if it is
          necessary to protect the vital interests of you or other data
          subjects, or if it is necessary for a task carried out in the public
          interest.
          <br />
          4. Sharing Personal Data
          <br />
          The “How We Share Your Personal Data” section above details how we
          share your Personal Data with third parties.
          <br />
          5. EU Data Subject Rights
          <br />
          You have certain rights with respect to your Personal Data, including
          those set forth below. To exercise certain of these rights, please see
          “Your Choices” above. For more information about these rights, or to
          submit a request, please email us at contact@plychain.io. Please note
          that in some circumstances, we may not be able to fully comply with
          your request, such as if it is frivolous or extremely impractical, if
          it jeopardizes the rights of others, or if it is not required by law,
          but in those circumstances, we will still respond to notify you of
          such a decision. In some cases, we may also need you to provide us
          with additional information, which may include Personal Data, if
          necessary to verify your identity and the nature of your request.
          <ul>
            <Box
              sx={{
                marginLeft: "2x",
              }}
            >
              ● Access: You can access the Personal Data we hold about you and
              request a copy of such Personal Data.
              <br />
              ● Rectification: You may correct or supplement Personal Data we
              hold about you.
              <br /> ● Erasure: You may be able to erase (or you can request
              that we erase) some or all of your Personal Data from our systems.
              <br /> ● Withdrawal of Consent: If we are processing your Personal
              Data based on your consent (as indicated at the time of collection
              of such data), you have the right to withdraw your consent at any
              time. Please note, however, that if you exercise this right, you
              may have to then provide express consent on a case-by-case basis
              for the use or disclosure of certain of your Personal Data, if
              such use or disclosure is necessary to enable you to utilize some
              or all of our Services.
              <br /> ● Portability: You can ask for a copy of your Personal Data
              in a machine-readable format. You can also request that we
              transmit the data to another controller where technically
              feasible.
              <br /> ● Objection: You can contact us to let us know that you
              object to the further use or disclosure of your Personal Data for
              certain purposes, such as for direct marketing purposes. ●
              Restriction of Processing: You can ask us to restrict further
              processing of your Personal Data.
              <br /> ● Right to File Complaint: You have the right to lodge a
              complaint about Plychain's practices with respect to your Personal
              Data with the supervisory authority of your country or EU Member
              State. A list of Supervisory Authorities is available here:
              <a
                href="https://edpb.europa.eu/about-edpb/board/members_en"
                style={{
                  color: "#0B1E5B",
                }}
              >
                https://edpb.europa.eu/about-edpb/board/members_en.
              </a>
            </Box>
          </ul>
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="h2"
          sx={baseClass}
        >
          Changes to this Privacy Policy
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="p"
          fontSize="20px"
          sx={{ ...baseClass4, fontSize: "16px" }}
        >
          We’re constantly trying to improve our Services, so we may need to
          change this Privacy Policy from time to time, but we will alert you to
          any such changes by placing a notice on the Plychain website, by
          sending you an email and/or by some other means. If you use the
          Services after any changes to the Privacy Policy have been posted,
          that means you agree to all of the changes. Use of information we
          collect is subject to the Privacy Policy in effect at the time such
          information is collected.
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="h2"
          sx={baseClass}
        >
          Contact Information
        </Typography>
        <Typography
          variant="body1"
          color="#0b1e5b"
          component="p"
          fontSize="20px"
          sx={{ ...baseClass4, fontSize: "16px" }}
        >
          We’re constantly trying to improve our Services, so we may need to
          change this Privacy Policy from time to time, but we will alert you to
          any such changes by placing a notice on the Plychain website, by
          sending you an email and/or by some other means. If you use the
          Services after any changes to the Privacy Policy have been posted,
          that means you agree to all of the changes. Use of information we
          collect is subject to the Privacy Policy in effect at the time such
          information is collected.
        </Typography>

        <Typography
          variant="body1"
          color="#0b1e5b"
          component="p"
          fontSize="20px"
          sx={{ ...baseClass4, fontSize: "16px" }}
        >
          If you have any questions or comments about this Privacy Policy, the
          ways in which we collect and use your Personal Data or your choices
          and rights regarding such collection and use, please do not hesitate
          to contact us at:
          <ul>
            <Box
              sx={{
                marginLeft: "2x",
              }}
            >
              <a
                href="https://www.plychain.io"
                style={{
                  color: "#0B1E5B",
                  textDecoration: " none",
                }}
              >
                ● https://www.plychain.io
              </a>
              <br />
              <a
                href="mailto:contact@plychain.io"
                style={{
                  color: "#0B1E5B",
                  textDecoration: " none",
                }}
              >
                ● contact@plychain.io
              </a>
            </Box>
          </ul>
        </Typography>
        <br />
        <br />
        <br />
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
