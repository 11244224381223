import React from "react";
import { Box, Typography, Grid, Link, Container } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import { AiOutlineInstagram } from "react-icons/ai";
import { BiLogoLinkedin } from "react-icons/bi";
import { RiTwitterXLine } from "react-icons/ri";
import { fbUrl, igUrl, liUrl, twitterUrl } from "../../constants/constants";
import { moveToTop, reloadWebsite } from "../../utils/utils";
import { Link as RouterLink } from "react-router-dom";

// images
import curved_left from "../../Assets/assets/footer-lines-left.svg";
import curved_right from "../../Assets/assets/footer-lines-right.svg";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        // backgroundImage: `url('./Assets/Image/FooterMainBackground.png')`,
        // backgroundSize: "cover",
        // backgroundPosition: "center",
        color: "#000",
        paddingTop: {
          xs: "150px",
          md: "100px",
        },
        // paddingRight: 2,
        // paddingLeft: 2,
        paddingBottom: {
          xs: "60px",
          md: "100px",
        },
        paddingX: "24px",
        // border: "1px solid blue",
        position: "relative",
        height: "100%",
      }}
    >
      {/* curved image left */}
      <Box
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          height: "100%",
          display: {
            xs: "none",
            md: "block",
          },
        }}
      >
        <img src={curved_left} className="curved__footer__lines" alt=""></img>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "0",
          right: "0",
          height: "100%",
        }}
      >
        {/* curved image right */}
        <img src={curved_right} className="curved__footer__lines" alt=""></img>
      </Box>
      <Box
        sx={{
          // border: "1px solid red",
          maxWidth: "1440px",
          marginX: "auto",
          zIndex: "2",
          position: "relative",
        }}
      >
        <Grid
          container
          spacing={0}
          sx={{
            // border: "1px solid green",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sm={4}
            // sx={{ border: "1px solid red" }}
          >
            <Box>
              <Box
                sx={{
                  // textAlign: "center",
                  // border: "1px solid red",
                  // marginX: "auto",
                  width: { xs: "160px", sm: "200px" },
                }}
              >
                {/* Logo */}
                <img
                  src="./Assets/Image/MainLogo.png"
                  alt=""
                  className="footerLogo2"
                  onClick={(e) => reloadWebsite()}
                />
              </Box>
              <Box
                className="socialMediaIcon"
                sx={{
                  // textAlign: "center",
                  marginTop: 2,
                  marginLeft: "-20px",
                  // border: "1px solid blue",
                  width: "250px",
                  display: {
                    xs: "none",
                    md: "block",
                  },
                }}
              >
                <RiTwitterXLine
                  fontSize="large"
                  onClick={(e) => window.open(twitterUrl)}
                />
                <FacebookIcon
                  fontSize="large"
                  onClick={(e) => window.open(fbUrl)}
                />
                <AiOutlineInstagram
                  fontSize="large"
                  onClick={(e) => window.open(igUrl)}
                />
                <BiLogoLinkedin
                  fontSize="large"
                  onClick={(e) => window.open(liUrl)}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              // border: "1px solid green",
              marginTop: {
                xs: "27px",
                md: "0",
              },
              marginBottom: {
                xs: "37px",
                md: "0",
              },
            }}
          >
            <Box
              className="FooterLink"
              sx={{
                textAlign: "center",
                // border: "1px solid green",
                marginBottom: "15px",
              }}
            >
              <Link
                href="#"
                color="inherit"
                onClick={() => {
                  moveToTop();
                }}
                sx={{
                  // marginX: 1,
                  fontSize: {
                    xs: "14px",
                    sm: "20px",
                  },
                }}
              >
                About Us
              </Link>
              <RouterLink
                to={"/privacy-policy"}
                color="primary"
                className="header__navlink"
                onClick={() => {
                  moveToTop();
                }}
              >
                <Link
                  color="inherit"
                  sx={{
                    marginRight: "10px",
                    fontSize: {
                      xs: "14px",
                      sm: "20px",
                    },
                  }}
                >
                  Privacy Policy
                </Link>
              </RouterLink>
            </Box>
            <Box
              className="FooterLink"
              sx={{
                textAlign: "center",
                // border: "1px solid red",
              }}
            >
              <RouterLink
                to={"/contactus"}
                color="primary"
                className="header__navlink"
                onClick={() => {
                  moveToTop();
                }}
              >
                <Link
                  color="inherit"
                  sx={{
                    // marginX: 1,
                    fontSize: {
                      xs: "14px",
                      sm: "20px",
                    },
                  }}
                >
                  Contact Us
                </Link>
              </RouterLink>
              <RouterLink
                to={"/terms-of-use"}
                color="primary"
                className="header__navlink"
                onClick={() => {
                  moveToTop();
                }}
              >
                <Link
                  color="inherit"
                  sx={{
                    // marginX: 1,
                    fontSize: {
                      xs: "14px",
                      sm: "20px",
                    },
                  }}
                >
                  Terms and Use
                </Link>
              </RouterLink>
            </Box>
          </Grid>
        </Grid>
        <Box
          className="socialMediaIcon"
          sx={{
            // textAlign: "center",
            // marginTop: 2,
            marginLeft: "-20px",
            // border: "1px solid blue",
            width: "250px",
            display: {
              xs: "block",
              md: "none",
            },
          }}
        >
          <RiTwitterXLine
            fontSize="large"
            onClick={(e) => window.open(twitterUrl)}
          />
          <FacebookIcon fontSize="large" onClick={(e) => window.open(fbUrl)} />
          <AiOutlineInstagram
            fontSize="large"
            onClick={(e) => window.open(igUrl)}
          />
          <BiLogoLinkedin
            fontSize="large"
            onClick={(e) => window.open(liUrl)}
          />
        </Box>
        <Box sx={{ textAlign: "center", marginTop: "100px" }}>
          <Typography
            variant="body1"
            component="h5"
            sx={{
              color: "#757575",
              marginTop: 2,
              // border: "1px solid blue",
              fontSize: { xs: "12px", sm: "14px" },
            }}
          >
            Copyright © 2023 PlyChain. All Rights Reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
