import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  FormGroup,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import { Container, Grid, Typography } from "@mui/material";
import SharedButton from "../../SharedComponent/Button/SharedButton";
import CustomizedSteppers from "./CustomizedSteppers";
import { AiOutlineCheck, AiOutlineDollarCircle } from "react-icons/ai";
import { PiScanFill, PiShareNetworkFill } from "react-icons/pi";

const CaptureConnect = () => {
  return (
    <Box
      sx={
        {
          // border: "1px solid red",
        }
      }
    >
      <Grid
        container
        sx={{
          alignItems: "center",
        }}
      >
        <Grid
          item
          sm={12}
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center", // Center horizontally
              justifyContent: "center",
              overflow: "hidden",
              paddingTop: 8,
              marginTop: 6,
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute", // Position the blur element
                top: 0,
                left: 0,
                bottom: 0,
                width: "10%", // Adjust the width for the left side
                background: "linear-gradient(91deg, #ffffffe3, #ffffff00)", // Adjust the color and opacity
                zIndex: 1,
                borderRadius: "0% 50% 50% 0%", // Place it behind the content
              }}
            ></Box>

            {/* Right Blur */}
            <Box
              sx={{
                position: "absolute", // Position the blur element
                top: 0,
                right: 0,
                bottom: 0,
                width: "10%", // Adjust the width for the right side
                background: "linear-gradient(91deg, #ffffff00 , #ffffffe3)", // Adjust the color and opacity
                borderRadius: "50% 0% 0% 50%", // Place it behind the content
                zIndex: 1, // Place it behind the content
              }}
            ></Box>

            <Box
              sx={{
                background: "#E3DDFB",
                p: 1,
                transform: "rotate(5deg)",
                transformOrigin: "top center",
                overflow: "hidden",
                width: "100%",
              }}
            >
              <Box
                className="marquee-container" // Apply the container class
                sx={{
                  width: "100%",
                  margin: "0px 0px",
                  overflow: "hidden", // Hide overflowing content
                }}
              >
                <Typography
                  variant="h2"
                  component="h1"
                  sx={{
                    color: "#0B1E5B",
                    fontWeight: "500",
                    // display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    fontSize: {
                      xs: "1.1rem",
                      sm: "3.75rem",
                      md: "3.75rem",
                    },
                  }}
                  className="marquee-text" // Apply the text class
                >
                  Capture <PiScanFill /> Connect <PiShareNetworkFill /> Monetize{" "}
                  <AiOutlineDollarCircle /> Capture <PiScanFill /> Connect{" "}
                  <PiShareNetworkFill /> Monetize <AiOutlineDollarCircle />{" "}
                  Capture <PiScanFill /> Connect <PiShareNetworkFill /> Monetize{" "}
                  <AiOutlineDollarCircle /> Capture <PiScanFill /> Connect{" "}
                  <PiShareNetworkFill /> Monetize <AiOutlineDollarCircle />
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                background: "#E3DDFB",
                p: 1,
                transform: "rotate(-5deg)",
                transformOrigin: {
                  xs: "-260px",
                  sm: "-320px",
                  md: "-320px",
                },
                width: "100%",
              }}
            >
              <Box
                className="marquee-container" // Apply the container class
                sx={{
                  width: "100%",
                  margin: "0px 0px",
                  overflow: "hidden", // Hide overflowing content
                }}
              >
                <Typography
                  variant="h2"
                  component="h1"
                  sx={{
                    color: "#0B1E5B",
                    fontWeight: "500",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    fontSize: {
                      xs: "1.1rem",
                      sm: "3.75rem",
                      md: "3.75rem",
                    },
                  }}
                  className="marquee-text" // Apply the text class
                >
                  Capture <PiScanFill /> Connect <PiShareNetworkFill /> Monetize{" "}
                  <AiOutlineDollarCircle /> Capture <PiScanFill /> Connect{" "}
                  <PiShareNetworkFill /> Monetize <AiOutlineDollarCircle />{" "}
                  Capture <PiScanFill /> Connect <PiShareNetworkFill /> Monetize{" "}
                  <AiOutlineDollarCircle /> Capture <PiScanFill /> Connect{" "}
                  <PiShareNetworkFill /> Monetize <AiOutlineDollarCircle />
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CaptureConnect;
