import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ResponsiveDrawer from "../../SharedComponent/Sidebar/ResponsiveDrawer";
import Header from "../../SharedComponent/Header/Header";
import {
  Alert,
  AlertColor,
  CircularProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import SharedButton from "../../SharedComponent/Button/SharedButton";
import { BsCardImage } from "react-icons/bs";
import { MdVerified } from "react-icons/md";
import { InputField } from "../../SharedComponent/TextField/InputField";
import InputFileUpload from "../../SharedComponent/TextField/InputFileUpload";
import { useDispatch, useSelector } from "react-redux";
import { AuthModel } from "../../models/authModel";
import { AiFillCloseCircle } from "react-icons/ai";
import { updateProfile } from "../../networking/networkCalls";
import { authActions } from "../../store/auth";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const Profile = () => {
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState<{
    email?: string;
    name?: string;
    dob?: string;
    business_logo_url?: string;
  }>();

  const [businessLogoFile, SetBusinessLogoFile] =
    React.useState<File>(undefined);

  const [snackBarState, setSnackBarState] = React.useState<{
    show: boolean;
    msg: string;
    severity: AlertColor;
  }>({
    show: false,
    msg: "",
    severity: "success",
  });

  // @ts-ignore
  const authData: AuthModel = useSelector((state) => state.auth.authData);

  const dispatch = useDispatch();

  React.useEffect(() => {
    const data = {
      email: authData?.email,
      name: authData?.name,
      dob: authData?.dob,
      business_logo_url: authData?.business_logo,
    };

    setFormData(data);
  }, []);

  const onAvatarFileChangeHandler = (
    e: React.SyntheticEvent<HTMLInputElement, Event>
  ) => {
    const target = e.target as HTMLInputElement;
    const selectedFile: File = (target.files as FileList)[0];

    if (selectedFile) {
      SetBusinessLogoFile(selectedFile);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    const data = { ...formData };

    data[`${field}`] = e.target.value;

    setFormData(data);
  };

  const onSaveHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setLoading(true);

    const data = { ...formData, business_logo: businessLogoFile };

    const res = await updateProfile(data, authData.id);

    if (res && res.status === 201) {
      const { data } = res.data;
      setSnackBarState({
        show: true,
        msg: "Profile has been updated",
        severity: "success",
      });

      const updatedData = { ...data };

      updatedData.token = authData.token;

      // save login state and data in local storage
      localStorage.setItem("authData", JSON.stringify(updatedData));

      // save data in redux store
      const updatedAuthData: AuthModel = updatedData;
      dispatch(authActions.setAuthData(updatedAuthData));
    } else {
      const { data } = res;
      setSnackBarState({
        show: true,
        msg: data.msg,
        severity: "error",
      });
    }

    setLoading(false);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarState({ ...snackBarState, show: false });
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Snackbar
          open={snackBarState.show}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity={snackBarState.severity}
            sx={{ width: "100%" }}
          >
            {snackBarState.msg}
          </Alert>
        </Snackbar>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <ResponsiveDrawer />
          </Grid>
          <Grid item xs={10}>
            <Header />
            <Box className={"MainBoxComponent"}>
              <Grid container spacing={0}>
                <Grid item xs>
                  <Typography
                    marginLeft={"10px"}
                    textAlign={"left"}
                    variant="h5"
                    component={"h4"}
                  >
                    Profile
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={0} marginTop={3}>
                <Grid item xs>
                  <Typography
                    marginLeft={"10px"}
                    textAlign={"left"}
                    variant="body2"
                    component={"h4"}
                    sx={{
                      borderBottom: "1px solid #dcdcdc",
                    }}
                  >
                    Personal Info
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={0} marginTop={3}>
                <Grid item xs>
                  <Typography
                    marginLeft={"10px"}
                    textAlign={"left"}
                    variant="h6"
                    component={"h4"}
                  >
                    Profile
                  </Typography>
                  <Typography
                    marginLeft={"10px"}
                    textAlign={"left"}
                    variant="body2"
                    component={"h4"}
                    className="greyColor"
                  >
                    Update your photo and personal details here.
                  </Typography>
                </Grid>
                <Grid item xs textAlign={"right"}>
                  {loading && <CircularProgress />}
                  {!loading && (
                    <SharedButton
                      value={"Save Profile"}
                      variant="contained"
                      className="Primary-button"
                      onClick={onSaveHandler}
                    />
                  )}
                </Grid>
              </Grid>
              <Box className="ProfileInfo">
                <Grid container spacing={2} marginTop={3}>
                  <Grid item xs={4}>
                    <Typography
                      marginLeft={"10px"}
                      textAlign={"left"}
                      variant="h6"
                      component={"h4"}
                      fontWeight={600}
                    >
                      Business Name
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputField
                      value={formData?.name}
                      type="text"
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => handleInputChange(e, "name")}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginTop={1}>
                  <Grid item xs={4}>
                    <Typography
                      marginLeft={"10px"}
                      textAlign={"left"}
                      variant="h6"
                      component={"h4"}
                      fontWeight={600}
                    >
                      Email Address
                    </Typography>
                    <Typography
                      marginLeft={"10px"}
                      textAlign={"left"}
                      variant="body2"
                      component={"p"}
                      sx={{
                        color: "green",
                      }}
                    >
                      <MdVerified /> Your email has been verified
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <InputField
                      value={formData?.email}
                      type="email"
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => handleInputChange(e, "email")}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  marginTop={1}
                  paddingBottom={4}
                  borderBottom={"1px solid #dcdcdc"}
                >
                  <Grid item xs={4}>
                    <Typography
                      marginLeft={"10px"}
                      textAlign={"left"}
                      variant="h6"
                      component={"h4"}
                      fontWeight={600}
                    >
                      Date
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <InputField
                      value={formData?.dob}
                      type="date"
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => handleInputChange(e, "dob")}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginTop={3}>
                  <Grid item xs={4}>
                    <Typography
                      marginLeft={"10px"}
                      textAlign={"left"}
                      variant="h6"
                      component={"h4"}
                      fontWeight={600}
                    >
                      Business Logo
                    </Typography>
                    <Typography
                      marginLeft={"10px"}
                      textAlign={"left"}
                      variant="body2"
                      component={"h4"}
                      className="greyColor"
                    >
                      Upload logo following this format PNG, JPG (max.
                      800x800px)
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    {(businessLogoFile ||
                      (formData?.business_logo_url &&
                        formData?.business_logo_url.trim() !== "")) && (
                      <Box className="ProductImg">
                        {(businessLogoFile !== undefined ||
                          (formData?.business_logo_url &&
                            formData?.business_logo_url.trim() !== "")) && (
                          <AiFillCloseCircle
                            className="closeIcon"
                            onClick={(e) => {
                              if (businessLogoFile) {
                                SetBusinessLogoFile(undefined);
                              } else {
                                const data = { ...formData };
                                data.business_logo_url = "";
                                setFormData(data);
                              }
                            }}
                          />
                        )}
                        <img
                          id={"1"}
                          src={
                            businessLogoFile
                              ? URL.createObjectURL(businessLogoFile)
                              : formData?.business_logo_url
                          }
                          alt=""
                        />
                      </Box>
                    )}
                    {!businessLogoFile &&
                      (!formData?.business_logo_url ||
                        formData?.business_logo_url.trim() === "") && (
                        <InputFileUpload
                          text="Upload image click here or Drag & Drop"
                          icon={<BsCardImage />}
                          className="FileUpload"
                          component="label"
                          variant="contained"
                          accept="image/*"
                          onChange={onAvatarFileChangeHandler}
                        />
                      )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
