import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  FormGroup,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import { Container, Grid, Typography } from "@mui/material";
import SharedButton from "../../SharedComponent/Button/SharedButton";

// images
import plychain from "../../Assets/assets/Plychain.png";
import shadow from "../../Assets/assets/circle-shadow.svg";
import green from "../../Assets/assets/light-green.svg";
import shoe from "../../Assets/assets/shoe.svg";
import blue from "../../Assets/assets/light-blue.svg";
import fish from "../../Assets/assets/fish.svg";
import D_blue from "../../Assets/assets/dark-blue.svg";
import meds from "../../Assets/assets/meds.svg";
import purple from "../../Assets/assets/purple.svg";
import engine from "../../Assets/assets/engine.svg";
import stick from "../../Assets/assets/stick.svg";
import { reloadWebsite } from "../../utils/utils";
const WaterBottleComp = () => {
  const [foodIndustry, setFoodIndustry] = useState<boolean>(false);
  const [fashionIndustry, setFashionIndustry] = useState<boolean>(false);
  const [medIndustry, setMedIndustry] = useState<boolean>(false);
  const [autoIndustry, setAutoIndustry] = useState<boolean>(false);
  return (
    <Box
      sx={{
        // border: "1px solid blue",
        marginTop: { xs: "100px", md: "450px" },
        marginBottom: { xs: "0px", md: "100px" },
      }}
    >
      {/* actual comp */}
      <Box
        sx={{
          maxWidth: "1280px",
          marginX: "auto",
          paddingX: "24px",
          // border: "1px solid yellow",
        }}
      >
        <Box
          sx={{
            marginTop: "380px",
            display: {
              xs: "none",
              md: "block",
            },
            position: "relative",
            // border: "1px solid green",
          }}
        >
          {/* comp part 1*/}
          <Box
            sx={{
              position: "absolute",
              bottom: "0",
              left: "10%",
              cursor: "pointer",
              width: "20%",
              // border: "1px solid red",
            }}
            onMouseOver={() => {
              setFashionIndustry(true);
            }}
            onMouseLeave={() => {
              setFashionIndustry(false);
            }}
          >
            {/* words */}
            <Box
              sx={{
                position: "absolute",
                top: "-80px",
                zIndex: "2",
              }}
            >
              <Typography
                sx={{
                  fontSize: "30px",
                  fontFamily: "Nexa",
                  fontWeight: "600",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                {" "}
                Fashion Industry
              </Typography>
              {/* image */}
              <Box
                sx={{
                  marginX: "auto",
                  width: "fit-content",
                }}
              >
                <img src={stick} alt=""></img>
              </Box>
            </Box>

            {/* pic and image part */}
            <Box
              sx={{
                width: "100%",
              }}
            >
              <img
                src={green}
                alt=""
                style={{
                  width: "100%",
                  objectFit: "cover",
                }}
              ></img>
              <Box
                sx={{
                  position: "absolute",
                  top: "60px",
                  zIndex: "2",
                  left: "-40px",
                  width: "108%",
                }}
              >
                <img
                  src={shoe}
                  alt=""
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                ></img>
              </Box>
            </Box>

            {/* hover words */}
            {fashionIndustry && (
              <Box
                sx={{
                  position: "absolute",
                  width: "196%",
                  borderRadius: "24px",
                  zIndex: "3",
                  top: "25%",
                  left: "40%",
                  paddingX: "40px",
                  paddingY: "30px",
                  boxShadow: "",
                  background: "#F4F1FD",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "30px",
                    fontFamily: "Nexa",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  Fashion Industry
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Inter",
                    marginTop: "10px",
                  }}
                >
                  {" "}
                  In the fashion industry, PlyChain can be used to verify the
                  authenticity of branded products, reducing the prevalence of
                  counterfeit items. Customers can scan a QR code to confirm the
                  origin and authenticity of their purchases.
                </Typography>
              </Box>
            )}
          </Box>

          {/* comp part 2*/}
          <Box
            sx={{
              position: "absolute",
              top: "-40%",
              left: "28%",
              cursor: "pointer",
              width: "17.8%",
              // border: "1px solid red",
            }}
            onMouseOver={() => {
              setFoodIndustry(true);
            }}
            onMouseLeave={() => {
              setFoodIndustry(false);
            }}
          >
            {/* words */}
            <Box
              sx={{
                position: "absolute",
                top: "-80px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "30px",
                  fontFamily: "Nexa",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                {" "}
                Food Industry
              </Typography>
              {/* image */}
              <Box
                sx={{
                  marginX: "auto",
                  width: "fit-content",
                }}
              >
                <img src={stick} alt=""></img>
              </Box>
            </Box>

            {/* pic and image part */}
            <Box
              sx={{
                width: "100%",
              }}
            >
              <img
                src={blue}
                alt=""
                style={{
                  width: "100%",
                  objectFit: "cover",
                }}
              ></img>
              <Box
                sx={{
                  position: "absolute",
                  top: "60px",
                  zIndex: "2",
                  left: "-10px",
                  width: "110%",
                }}
              >
                <img
                  src={fish}
                  alt=""
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                ></img>
              </Box>
            </Box>

            {/* hover words */}
            {foodIndustry && (
              <Box
                sx={{
                  position: "absolute",
                  width: "220%",
                  borderRadius: "24px",
                  zIndex: "3",
                  top: "25%",
                  left: "40%",
                  paddingX: "40px",
                  paddingY: "30px",
                  boxShadow: "0 1px 1px rgb(0 0 0 / 0.05)",
                  background: "#F4F1FD",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "30px",
                    fontFamily: "Nexa",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  Food Industry
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Inter",
                    marginTop: "10px",
                  }}
                >
                  {" "}
                  PlyChain can be used to track the origin, processing, and
                  distribution of food products, ensuring safety and
                  authenticity. It helps in identifying sources of contamination
                  or spoilage in real-time, allowing for faster recalls and
                  reducing food waste.
                </Typography>
              </Box>
            )}
          </Box>

          {/* comp part 3*/}
          <Box
            sx={{
              position: "absolute",
              top: "-40%",
              right: "28%",
              cursor: "pointer",
              width: "16.9%",
              // border: "1px solid red",
            }}
            onMouseOver={() => {
              setMedIndustry(true);
            }}
            onMouseLeave={() => {
              setMedIndustry(false);
            }}
          >
            {/* words */}
            <Box
              sx={{
                position: "absolute",
                top: "-120px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "30px",
                  fontFamily: "Nexa",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                {" "}
                Pharmaceutical Industry
              </Typography>
              {/* image */}
              <Box
                sx={{
                  marginX: "auto",
                  width: "fit-content",
                }}
              >
                <img src={stick} alt=""></img>
              </Box>
            </Box>

            {/* pic and image part */}
            <Box
              sx={{
                width: "100%",
              }}
            >
              <img
                src={D_blue}
                alt=""
                style={{
                  width: "100%",
                  objectFit: "cover",
                }}
              ></img>
              <Box
                sx={{
                  position: "absolute",
                  top: "10px",
                  zIndex: "2",
                  left: "10px",
                  width: "92.1%",
                }}
              >
                <img
                  src={meds}
                  alt=""
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                ></img>
              </Box>
            </Box>

            {/* hover words */}
            {medIndustry && (
              <Box
                sx={{
                  position: "absolute",
                  width: "213%",
                  borderRadius: "24px",
                  zIndex: "3",
                  top: "25%",
                  right: "40%",
                  paddingX: "40px",
                  paddingY: "30px",
                  boxShadow: "",
                  background: "#F4F1FD",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "30px",
                    fontFamily: "Nexa",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  Pharmaceuticals
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Inter",
                    marginTop: "10px",
                  }}
                >
                  {" "}
                  PlyChain's traceability can be applied to pharmaceuticals to
                  ensure the authenticity of drugs and medications. It helps in
                  the fight against counterfeit drugs and ensures patients
                  receive genuine, safe products.
                </Typography>
              </Box>
            )}
          </Box>

          {/* comp part 4*/}
          <Box
            sx={{
              position: "absolute",
              bottom: "0",
              right: "10%",
              cursor: "pointer",
              width: "20%",
              // border: "1px solid red",
            }}
            onMouseOver={() => {
              setAutoIndustry(true);
            }}
            onMouseLeave={() => {
              setAutoIndustry(false);
            }}
          >
            {/* words */}
            <Box
              sx={{
                position: "absolute",
                top: "-90px",
                zIndex: "2",
              }}
            >
              <Typography
                sx={{
                  fontSize: "30px",
                  fontFamily: "Nexa",
                  fontWeight: "600",
                  textAlign: "center",
                  // position: "relative",
                  // zIndex: "3",
                }}
              >
                {" "}
                Automotive Industry
              </Typography>
              {/* image */}
              <Box
                sx={{
                  marginX: "auto",
                  width: "fit-content",
                }}
              >
                <img src={stick} alt=""></img>
              </Box>
            </Box>

            {/* pic and image part */}
            <Box
              sx={{
                width: "100%",
              }}
            >
              <img
                src={purple}
                alt=""
                style={{
                  width: "100%",
                  objectFit: "cover",
                }}
              ></img>
              <Box
                sx={{
                  position: "absolute",
                  top: "30px",
                  zIndex: "2",
                  right: "-30px",
                  width: "108%",
                }}
              >
                <img
                  src={engine}
                  alt=""
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                ></img>
              </Box>
            </Box>

            {/* hover words */}
            {autoIndustry && (
              <Box
                sx={{
                  position: "absolute",
                  width: "194%",
                  borderRadius: "24px",
                  zIndex: "3",
                  top: "25%",
                  right: "40%",
                  paddingX: "40px",
                  paddingY: "30px",
                  boxShadow: "",
                  background: "#F4F1FD",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "30px",
                    fontFamily: "Nexa",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  Fashion Industry
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Inter",
                    marginTop: "10px",
                  }}
                >
                  {" "}
                  In the fashion industry, PlyChain can be used to verify the
                  authenticity of branded products, reducing the prevalence of
                  counterfeit items. Customers can scan a QR code to confirm the
                  origin and authenticity of their purchases.
                </Typography>
              </Box>
            )}
          </Box>

          {/* circle path */}
          <Box
            sx={{
              width: {
                xs: "70%",
                lg: "700px",
              },
              zIndex: "1",
              position: "relative",
              border: "8px solid white",
              background: "#F7F8F2",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              borderTopRightRadius: "9999px",
              borderTopLeftRadius: "9999px",
              height: "340px",
              marginX: "auto",
            }}
          >
            <Box
              sx={
                {
                  // border: "1px solid green",
                }
              }
            >
              {/* image */}
              <Box
                sx={{
                  width: {
                    xs: "150px",
                    sm: "200px",
                  },
                  marginX: "auto",
                }}
              >
                <img
                  src="./Assets/Image/MainLogo.png"
                  alt=""
                  className="footerLogo2"
                  onClick={(e) => reloadWebsite()}
                />
              </Box>
              <Typography
                sx={{
                  maxWidth: {
                    xs: "200px",
                    sm: "500px",
                  },
                  marginTop: "30px",
                  textAlign: "center",
                  marginX: "auto",
                  fontFamily: "Inter",
                }}
              >
                PlyChain's blockchain-based traceability technology offers
                viable commercial applications across various industries.
              </Typography>
            </Box>
          </Box>

          {/* shadow part */}
          <Box
            sx={{
              width: { xs: "100%", md: "750px" },
              marginTop: "-5px",
              marginX: "auto",
            }}
          >
            <img
              src={shadow}
              alt=""
              style={{
                width: "100%",
                objectFit: "cover",
              }}
            ></img>
          </Box>
        </Box>
      </Box>

      {/* image comp on small screens */}
      <Box
        sx={{
          width: "100%",
          // height: {
          //   xs: "270px",
          //   sm: "auto",
          // },
          // marginTop: "100px",
          // border: "1px solid red",

          display: {
            xs: "block",
            md: "none",
          },
        }}
      >
        <img
          src={plychain}
          alt=""
          style={{
            width: "100%",
            // height: "100%",
            // objectFit: "contain",
          }}
        ></img>
      </Box>
    </Box>
  );
};

export default WaterBottleComp;
