import { ethers } from "ethers";
import abi from "../constants/abi.json";

let provider: ethers.BrowserProvider = undefined;
let signer: ethers.JsonRpcSigner = undefined;
let contract: ethers.Contract = undefined;

const getProviderSignerContract = async () => {
  if (provider === undefined) {
    if (window.ethereum != null && window.ethereum !== undefined) {
      provider = new ethers.BrowserProvider(window.ethereum);
    }
  }

  if (provider !== undefined) {
    signer = await provider.getSigner();
    contract = new ethers.Contract(
      process.env.REACT_APP_SMART_CONTRACT_ADDRESS,
      abi,
      signer
    );
  }

  return { provider, signer, contract };
};

const sendTransaction = async (hashedData: string) => {
  try {
    // const { signer } = await getProviderSignerContract();

    // const tx: ethers.TransactionRequest = {
    //   data: hashedData,
    //   to: signer.address,
    //   // gasLimit: 200000,
    //   // maxFeePerGas: ethers.parseUnits("10", "gwei"),
    //   // maxPriorityFeePerGas: ethers.parseUnits("10", "gwei"),
    // };

    // const response: ethers.TransactionResponse = await signer.sendTransaction(
    //   tx
    // );

    // const txReceipt: ethers.TransactionReceipt = await response.wait();

    // // Check status of transaction
    // if (txReceipt.status !== 1) {
    //   throw new Error("Something went wrong, please try again later");
    // }

    // return { success: true, txReceipt };

    const { signer, contract, provider } = await getProviderSignerContract();

    const balance = await provider.getBalance(signer.getAddress());

    // Convert balance to Matic (or Ether) for better readability
    const balanceInMatic = ethers.formatUnits(balance, "ether");

    if (parseFloat(balanceInMatic.toString()) < 3) {
      return {
        success: false,
        error: {
          code: "INSUFFICIENT_FUND",
          msg: "Wallet does not have enough balance",
        },
      };
    }

    // Amount to send in Wei (0.001 Matic)
    const amountToSend = ethers.parseUnits("3", "ether");

    // Encode the function call for the 'pay' method
    const data = contract.interface.encodeFunctionData("pay");

    const tx: ethers.TransactionRequest = {
      data: data + hashedData.slice(2), // removing the '0x' prefix
      to: process.env.REACT_APP_SMART_CONTRACT_ADDRESS,
      value: amountToSend, // Sending the specified amount
    };

    const response: ethers.TransactionResponse = await signer.sendTransaction(
      tx
    );

    const txReceipt: ethers.TransactionReceipt = await response.wait();

    // Check status of transaction
    if (txReceipt.status !== 1) {
      throw new Error("Something went wrong, please try again later");
    }

    // console.log("txReceipt --> ", txReceipt.toJSON());

    return { success: true, txReceipt };
  } catch (error) {
    // console.log(`ERROR :: sendTransaction :: ${error}`);
    return { success: false, error };
  }
};

const addPolygonMainnet = async () => {
  try {
    const response = await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0x89",
          chainName: "Polygon Mainnet",
          rpcUrls: ["https://polygon-rpc.com/"],
          nativeCurrency: {
            symbol: "MATIC",
            decimals: 18,
          },
          blockExplorerUrls: ["https://polygonscan.com/"],
        },
      ],
    });

    if (response === "null") {
      // user successfully added polygon network
      // switch to polygon network
      return await switchToPolygonMainnet();
    }

    return false;
  } catch (error) {
    // console.log(`ERROR :: addPolygonMainnet :: ${error}`);
    return false;
  }
};

const switchToPolygonMainnet = async () => {
  try {
    const response = await window?.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [
        {
          chainId: "0x89",
        },
      ],
    });

    if (typeof response === "object" && response == null) {
      return true;
    }

    // if (typeof response === "object") {
    //   if (Object.hasOwn(response, "code") && response["code"] == 4902) {
    //     // user does not have polygon mainnet in metamask wallet
    //     // request to add network
    //     return await addPolygonMainnet();
    //   }
    // }

    return false;
  } catch (error) {
    // console.log(`ERROR :: switchToPolygonMainnet :: ${JSON.stringify(error)}`);

    if (Object.hasOwn(error, "code") && error["code"] == 4902) {
      // user does not have polygon mainnet in metamask wallet
      // request to add network
      return await addPolygonMainnet();
    }

    return false;
  }
};

const getCurrentNetworkId = async () => {
  try {
    const response = await window?.ethereum.request({
      method: "eth_chainId",
      params: [],
    });

    // console.log("Current Network Id -> ", response);

    if (response !== "0x89") {
      // user is not on polygon mainnet
      // switch network
      return await switchToPolygonMainnet();
      // return false;
    }

    return true;
  } catch (error) {
    // console.log(`ERROR :: getCurrentNetworkId :: ${error}`);
    return false;
  }
};

export { sendTransaction, getCurrentNetworkId };
