import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Container, Typography } from "@mui/material";
import ResponsiveAppBar from "../../SharedComponent/AppBar/ResponsiveAppBar";
import Footer from "./Footer";
import { moveToTop } from "../../utils/utils";

const TermsOfUse = () => {
  useEffect(() => {
    moveToTop();
  }, []);
  return (
    <>
      <ResponsiveAppBar />
      <Container>
        <Box
          sx={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="h1"
            variant="h3"
            color="#0b1e5b"
            sx={{
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "28px",
                md: "36px",
                lg: "48px",
              },
              padding: {
                xs: "0",
              },
            }}
          >
            Terms of Use
          </Typography>

          <Typography
            variant="body1"
            color="#0b1e5b"
            sx={{
              fontFamily: "inter",
              fontWeight: "400",
              textAlign: "left",
              fontSize: "16px",
              width: "100%",
              marginBottom: 1,
              listStyleType: "none",
            }}
          >
            Terms of use outlined in the following (also referred to as “Terms
            of Use”) apply to the Plychain website (www.plychain.io referred to
            as our “Website”) the Plychain software application collectively
            also referred to as the “App”) the Plychain software infrastructure
            (collectively, together with the Website and the App, also referred
            to as our “Infrastructure”), all communication with us via online
            media or other channels including but not limited to, Linkedin (via
            https://www.linkedin.com/company/plychain/), Facebook (via
            https://facebook.com/useplychain), Twitter (via
            https://twitter.com/useplychain), Instagram (via
            https://instagram.com/useplychain) as well as all other
            communications of Plychain with individuals via other written or
            oral means, such as email or phone, collectively also referred to as
            our “Service”. ‍ The Terms of Use govern the usage of our Services.
            By accessing and using our Service, we assume you accept these Terms
            of Use in full. Do not continue to use our Service as long as you do
            not accept all of the terms and conditions stipulated in these Terms
            of Use. How to Contact Us If you have suggestions or questions
            concerning our Service or the Terms of Use, please send an email to
            contact@plychain.io
          </Typography>

          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            1. General
          </Typography>
          <ul>
            <Typography
              color="#0b1e5b"
              component="p"
              sx={{
                fontFamily: "inter",
                fontWeight: "400",
                textAlign: "left",
                fontSize: "16px",
                width: "100%",
                marginBottom: 1,
                listStyleType: "none",
              }}
            >
              These Terms of Use (also referred to as the "Terms" or
              “Agreement”) describe the general terms and conditions applicable
              to the user’s ("You", "Your") access to and use of our Service, as
              well as our and Your obligations related to such use. Your access
              or use of our Service ("Use of Service") is strictly subject to
              Your compliance with the Terms (as updated periodically) and our
              then-current policies applicable to the Service. Your use of our
              Service constitutes your consent and agreement to be bound by
              these Terms. In particular, by accepting the Terms, you
              acknowledge that You agree to and comply with our Privacy Policy
              and the compliance with all laws and regulations applicable to the
              Use of Service. ‍ In case you represent or otherwise act on behalf
              of a company or other entity or person (collectively also referred
              to as “Entity”), by entering this Agreement, You represent and
              warrant that you are authorized to do so for your Entity and that
              your Entity consents and agrees with these Terms. If you do not
              agree with these Terms, then do not use our Service. ‍ You should
              review these Terms periodically, and each use of our Service is
              subject to the then-current form of the Terms.
            </Typography>

            <Typography
              variant="body1"
              color="#0b1e5b"
              component="h2"
              sx={{
                lineHeight: 1.6,
                textAlign: "left",
                marginTop: 2.8,
                fontFamily: "nexa",
                width: "100%",
                fontSize: {
                  xs: "24px",
                  sm: "28px",
                  md: "32px",
                  lg: "32px",
                },
              }}
            >
              2. Eligibility to the Use of Services.
              <ul>
                <Typography
                  color="#0b1e5b"
                  component="p"
                  sx={{
                    fontFamily: "inter",
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: "16px",
                    width: "100%",
                    marginBottom: 1,
                    listStyleType: "none",
                  }}
                >
                  Our Services shall be used only by users who are duly
                  authorized to use our Service and acting in compliance with
                  the Terms. In case that our respective Service requires
                  registration or any other kind of access limitation and
                  authorization, You are only allowed to use the respective
                  Service with the respective due authorization. You agree and
                  commit to refrain from the Use of Service without due
                  authorization and your compliance with the Terms. ‍
                </Typography>
              </ul>
            </Typography>
          </ul>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            3. Confidentiality
          </Typography>
          <ul>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
                listStyleType: "none",
                marginLeft: 0,
              }}
            >
              3.1. Information You Share
              <ul>
                <Typography
                  variant="body1"
                  color="#0b1e5b"
                  component="li"
                  sx={{
                    fontFamily: "inter",
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: "16px",
                    width: "100%",
                    marginBottom: 1,
                    listStyleType: "none",
                  }}
                >
                  Any information You share when You use our Service is deemed
                  not to be confidential unless an exception of this general
                  rule is agreed individually and expressly with us. In
                  particular, information shall not be confidential that: (i)
                  You share in a way that can obviously be accessed and seen by
                  any user of the respective channel (for example on via our App
                  or other Infrastructure, as well as on our channels on
                  Facebook, Instagram, Linkedin, Twitter as well as on any other
                  channels of communication to the public); (ii) is or becomes a
                  matter of public knowledge through no fault of the receiving
                  party; (iii) is rightfully received from a third party without
                  a duty of confidentiality; (iv) is independently developed by
                  the receiving party ; (v) is disclosed under operation of law
                  (i.e., in response to a subpoena, governmental or regulatory
                  request, or the like); or (vi) is disclosed by us due to Your
                  prior approval. This in particular applies to information that
                  is disclosed via the App when you assign the respective data
                  to or share it in categories that are meant to be made public
                  or transparent. ‍ We and You agree to use reasonable efforts
                  to maintain, safeguard, and not to disclose information that
                  we explicitly agreed to be confidential. We can use your
                  information as needed to facilitate Your Use of Service, track
                  usage, address security, fraud prevention and data integrity
                  requirements and perform aggregated and anonymous analytics or
                  use it otherwise to perform services under these Terms as
                  agreed with You. We may disclose information if legally
                  required, for example to respond to requests of government
                  authorities.
                </Typography>
              </ul>
            </Typography>

            <ul>
              <Typography
                variant="body1"
                color="#0b1e5b"
                component="li"
                sx={{
                  fontFamily: "inter",
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: "20px",
                  width: "100%",
                  marginBottom: 1,
                  listStyleType: "none",
                  marginLeft: 0,
                }}
              >
                3.2. Your Use of Other Person's Information
                <ul>
                  <Typography
                    variant="body1"
                    color="#0b1e5b"
                    component="li"
                    sx={{
                      fontFamily: "inter",
                      fontWeight: "400",
                      textAlign: "left",
                      fontSize: "16px",
                      width: "100%",
                      marginBottom: 1,
                      listStyleType: "none",
                    }}
                  >
                    In order to facilitate interaction among users of the App or
                    other Service, You may be allowed to access certain
                    information about other users of the Service. By entering
                    into this Agreement, You agree to treat information about
                    other users of our Service in strict accordance with this
                    Agreement. In all cases You collect or process personal data
                    from other individuals, You must do this in compliance with
                    law. You must give such users an opportunity to request to
                    review what information You have collected about them and a
                    chance to delete personal data that relates to them. In
                    addition, under no circumstances, can You disclose
                    personally identifiable information about another person to
                    any third party without the consent of the respective user.
                  </Typography>
                </ul>
              </Typography>

              <Typography
                variant="body1"
                color="#0b1e5b"
                component="li"
                sx={{
                  fontFamily: "inter",
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: "20px",
                  width: "100%",
                  marginBottom: 1,
                  listStyleType: "none",
                  marginLeft: 0,
                }}
              >
                3.3. Confidentiality of Our Information
                <ul>
                  <Typography
                    variant="body1"
                    color="#0b1e5b"
                    component="li"
                    sx={{
                      fontFamily: "inter",
                      fontWeight: "400",
                      textAlign: "left",
                      fontSize: "16px",
                      width: "100%",
                      marginBottom: 1,
                      listStyleType: "none",
                    }}
                  >
                    Information (but not Your Data) about the processing
                    capabilities of our Service, the technology or interface
                    features of our Service, and the notices and updates sent by
                    Us to You are proprietary and confidential information of
                    Plychain and You hereby agree not to share such information
                    with third parties or use such information except to support
                    Your Use of our Service.
                  </Typography>
                </ul>
              </Typography>
            </ul>
          </ul>

          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            4. Accuracy of Your Information
          </Typography>

          <ul>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              sx={{
                fontFamily: "inter",
                fontWeight: "400",
                textAlign: "left",
                fontSize: "16px",
                width: "100%",
                marginBottom: 1,
                listStyleType: "none",
              }}
            >
              All information that You provide to Plychain must be accurate
              according to your best and due knowledge and carefully specified.
              This in particular applies to Your name, address, and payment card
              information (if applicable), whether or not supplied during the
              registration process. The company name You provide must be the
              full legal name of the Entity You represent. If requested, it
              shall be associated with Your Entity's Tax ID and/or commercial
              registration number as provided by the competent authority and/or
              your Entity’s D-U-N-S number according to the Data Universal
              Numbering System by Dun & Bradstreet (see
              https://www.dnb.com/duns-number.html). Your Entity must have the
              respective operations at the address of the Entity You provide. ‍
              You are responsible for keeping your registration, account and
              billing data up-to-date and must provide changes without undue
              delay.
            </Typography>
          </ul>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            5. Billing
          </Typography>
          <ul>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              sx={{
                fontFamily: "inter",
                fontWeight: "400",
                textAlign: "left",
                fontSize: "16px",
                width: "100%",
                marginBottom: 1,
                listStyleType: "none",
                marginLeft: 0,
              }}
            >
              If there are any fees applicable to Your use of Services, we may
              collect information relating to billing You or your Entity
              respectively (also referred to as “Billing Data”). We may use this
              Billing Data for billing, collecting and enforcing claims and may
              share Billing Data with duly authorized third parties. We may
              maintain the Billing Data as long as necessary or as required by
              law and used internally for record keeping, internal reporting,
              and support purposes, which may include transfer to IT systems of
              Plychain or duly authorized third parties for processing. We may
              provide the Billing Data as required by law or court order, or to
              defend rights in a legal dispute.
            </Typography>
          </ul>

          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            6. Involvement with Third Parties
          </Typography>
          <ul>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
                listStyleType: "none",
                marginLeft: 0,
              }}
            >
              6.1. Links to Third Party Sites.
              <ul>
                <Typography
                  variant="body1"
                  color="#0b1e5b"
                  component="li"
                  sx={{
                    fontFamily: "inter",
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: "16px",
                    width: "100%",
                    marginBottom: 1,
                    listStyleType: "none",
                  }}
                >
                  Our Service may allow access to websites of third parties that
                  may not be under Our control. We or our Users may provide
                  links only as a convenience. We are not responsible for the
                  contents of any linked site. Any such inclusion of any link
                  does not imply endorsement by Plychain of the link or site of
                  a third party or any part of its contents.
                </Typography>
              </ul>
            </Typography>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
                listStyleType: "none",
                marginLeft: 0,
              }}
            >
              6.2. User Content
              <ul>
                <Typography
                  variant="body1"
                  color="#0b1e5b"
                  component="li"
                  sx={{
                    fontFamily: "inter",
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: "16px",
                    width: "100%",
                    marginBottom: 1,
                    listStyleType: "none",
                  }}
                >
                  Our Service may allow You and/or other Users to make or
                  process transactions on Your or the respective User’s behalf
                  or on behalf of Your Entity or the Entity of the respective
                  User (each transaction individually as well as collectively
                  also referred to as "Transactions"), for example, to post
                  content, to generate and share certificates or upload or
                  otherwise introduce, route and share information (also
                  referred to as "User Content"). We do not control or endorse
                  any User Content processed by our Service and we have no
                  responsibility whatsoever relating to the User Content besides
                  processing the User Content according to these Terms of Use,
                  our Privacy Policy and the laws and regulations applicable to
                  us.
                  <br />
                  You agree to indemnify and hold harmless Plychain from any and
                  all claims, losses, liabilities, and damages relating to
                  Transactions by You and also for all liability or damages
                  relating to User Content uploaded, shared, posted or otherwise
                  introduced by You or on Your behalf. In case You use Our
                  Service to provide User Content on behalf of Your Entity, you
                  also agree to do so only in case that your Entity provides to
                  indemnify and hold harmless Plychain from any and all claims,
                  losses, liabilities, and damages relating to Transactions by
                  You on behalf of Your Entity and also for all liability or
                  damages relating to User Content uploaded, shared, posted or
                  otherwise introduced by You on behalf of Your Entity.
                  <br />
                  Plychain expressly bears no responsibility or liability for
                  inaccurate user content.
                </Typography>
              </ul>
            </Typography>
          </ul>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            7. System Integrity, Data Integrity, Data Storage
          </Typography>
          <ul>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
                listStyleType: "none",
                marginLeft: 0,
              }}
            >
              7.1. System Integrity
              <ul>
                <Typography
                  variant="body1"
                  color="#0b1e5b"
                  component="li"
                  sx={{
                    fontFamily: "inter",
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: "16px",
                    width: "100%",
                    marginBottom: 1,
                    listStyleType: "none",
                  }}
                >
                  Concerning Your Use of Services, You agree not to knowingly:
                  (i) interfere with or attempt to interfere with the proper
                  working of the Service; (ii) post or transmit to the Service
                  any unlawful, fraudulent, harassing, libelous, or obscene
                  content; (iii) post or send to the Service any content that
                  contains a virus, bug, cancelbot, worm, trojan horse or other
                  harmful item; (iv) publish, perform, distribute, prepare
                  derivative works, copy, or reverse engineer (except to the
                  limited extent that applicable law prohibits a restriction on
                  reverse engineering) the Service; or (v) take any action which
                  imposes an unreasonable or disproportionately large load on
                  the Service (guidelines on appropriate load will be posted on
                  the Service, and You will be notified and given an opportunity
                  to cure if You violate this section. Since most content on the
                  Service will be sent to the Service by third parties or You,
                  You also understand that We cannot and do not guarantee that
                  such User Content will be free of infection or viruses, worms,
                  Trojan Horses or other code or defects that manifest
                  contaminating or destructive properties. We agree to not
                  knowingly post or send to the Service any files (i.e., files
                  created by Us) that contain a virus, bug, cancelbot, worm,
                  trojan horse or other harmful item. However, both parties
                  understand that they cannot and do not guarantee or warrant
                  that files or software of any kind, or from any source,
                  available for downloading through the Service, will be free of
                  infection or viruses, worms, trojan horses or other code or
                  defects that manifest contaminating or destructive properties.
                </Typography>
              </ul>
            </Typography>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
                listStyleType: "none",
                marginLeft: 0,
              }}
            >
              7.2. Data Integrity
              <ul>
                <Typography
                  variant="body1"
                  color="#0b1e5b"
                  component="li"
                  sx={{
                    fontFamily: "inter",
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: "16px",
                    width: "100%",
                    marginBottom: 1,
                    listStyleType: "none",
                  }}
                >
                  You are responsible for confirming that according to Your best
                  knowledge and due care, any data You send to the Service is
                  accurate. You are responsible for maintaining a means external
                  to the Service for the reconstruction of Your data. Plychain
                  has procedures to reasonably assist Your efforts to confirm
                  that Your data is accurate, current, and complete where such
                  confirmation is requested by Us.
                </Typography>
              </ul>
            </Typography>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
                listStyleType: "none",
                marginLeft: 0,
              }}
            >
              7.3. Storage of Data
              <ul>
                <Typography
                  variant="body1"
                  color="#0b1e5b"
                  component="li"
                  sx={{
                    fontFamily: "inter",
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: "16px",
                    width: "100%",
                    marginBottom: 1,
                    listStyleType: "none",
                  }}
                >
                  Our Service may allow You to access data You send to or
                  receive via our Service for an undetermined, limited, or
                  unlimited period of time, as the case may be and which may
                  vary for specific services or functions. Notwithstanding the
                  foregoing, We will not delete the transaction Data you retain
                  via our Plychain website that has been stored on a blockchain
                  by Plychain or on behalf of Plychain, provided that your
                  account is active and in good standing. You are solely
                  responsible for saving all of Your own data. Therefore, You
                  should take proactive measures to store Your data within Your
                  own computers or on system, in order to preserve the
                  accessibility of such data beyond our Service retention period
                  applicable to You.
                </Typography>
              </ul>
            </Typography>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
                listStyleType: "none",
                marginLeft: 0,
              }}
            >
              7.4. No Load Testing without Advance Consent
              <ul>
                <Typography
                  variant="body1"
                  color="#0b1e5b"
                  component="li"
                  sx={{
                    fontFamily: "inter",
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: "16px",
                    width: "100%",
                    marginBottom: 1,
                    listStyleType: "none",
                  }}
                >
                  You agree to not perform, without Our advance consent, any
                  load testing or intrusion testing on the Service, even if it
                  is performed using test accounts.
                </Typography>
              </ul>
            </Typography>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
                listStyleType: "none",
                marginLeft: 0,
              }}
            >
              7.5. Operating Policies
              <ul>
                <Typography
                  variant="body1"
                  color="#0b1e5b"
                  component="li"
                  sx={{
                    fontFamily: "inter",
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: "16px",
                    width: "100%",
                    marginBottom: 1,
                    listStyleType: "none",
                  }}
                >
                  Plychain's current Policies apply to Our operation of Our
                  Service. "Policies" means the system-wide policies relating to
                  the use of Our Services including our Privacy Policy.
                </Typography>
              </ul>
            </Typography>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="li"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
                listStyleType: "none",
                marginLeft: 0,
              }}
            >
              7.6. Actions to Prevent Risk
              <ul>
                <Typography
                  variant="body1"
                  color="#0b1e5b"
                  component="li"
                  sx={{
                    fontFamily: "inter",
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: "16px",
                    width: "100%",
                    marginBottom: 1,
                    listStyleType: "none",
                  }}
                >
                  In order to minimize legal or operational risk (if any) to
                  Plychain or Our Service as a result of Your Use of Service, We
                  reserve the right to take any reasonable action with respect
                  to such Use of Service we deem necessary or appropriate to
                  prevent or mitigate the risk. As the case may be, such action
                  may require suspending or terminating Your Use of Services,
                  for example if it causes or creates an unreasonable risk to
                  Us, other Users or a third party. Use of Service which may
                  present such a risk would include the use of an unauthorized
                  RPA with the Service. "RPA" means robotic process automation
                  used as a productivity tool to configure one or more scripts
                  to navigate a graphical user interface or to automate the
                  activation of specific keystrokes (i.e. a software "bot").
                </Typography>
              </ul>
            </Typography>
          </ul>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            8. Title to the Service
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="p"
              sx={{
                fontFamily: "inter",
                fontWeight: "400",
                textAlign: "left",
                fontSize: "16px",
                width: "100%",
                marginBottom: 1,
              }}
            >
              You acknowledge that the contents of the Service (other than Your
              data and data of other Users) are the intellectual property of
              Plychain and are copyrighted and protected by international
              copyright laws. Plychain grants You a non-exclusive,
              non-transferable license to print and download content on the
              Service solely for Your non-commercial use provided You maintain
              Our copyrights as well as the confidentiality and, as the case may
              be, any other rights that apply to such copies. You may not copy
              and redistribute outside of Your Entity any content or information
              from the Service to display on any other website or system. This
              section does not apply to Your ownership of data and does not
              impose any use restrictions on You as to Your use of Your data.
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            9. Limited Warranty and Disclaimer
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="p"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
              }}
            >
              9.1. Warranty of Authority
              <ul>
                <Typography
                  variant="body1"
                  color="#0b1e5b"
                  component="li"
                  sx={{
                    fontFamily: "inter",
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: "16px",
                    width: "100%",
                    marginBottom: 1,
                    listStyleType: "none",
                  }}
                >
                  Plychain warrants that it has full power and authority to
                  provide Our Service and all related services to You and to
                  grant You respectively Your Entity the rights granted herein.
                </Typography>
              </ul>
              9.2. Warranty of Conformity
              <ul>
                <Typography
                  variant="body1"
                  color="#0b1e5b"
                  component="li"
                  sx={{
                    fontFamily: "inter",
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: "16px",
                    width: "100%",
                    marginBottom: 1,
                    listStyleType: "none",
                  }}
                >
                  Plychain warrants that during the period of Your Subscription,
                  Our Service will conform in all material respects to the
                  documentation published by Us for the respective Service.
                  "Member" refers to a User of Our Service which subscribes to
                  Our Service involving a fee. Plychain charges Transaction
                  Fees. To make a claim that a Service fails to comply with the
                  warranty, You must identify the non-conformity in a written
                  notice delivered to Plychain during the warranty period
                  ("Warranty Claim Notice"). Your sole and exclusive remedy, and
                  Our entire liability for breach of this limited warranty,
                  shall be the correction of the warranted nonconformity in the
                  Service or, if We fail to correct the warranted nonconformity
                  after using reasonable commercial efforts, We will terminate
                  access to the non-conforming Service. This limited warranty
                  shall not be valid to the extent the warranty nonconformity
                  was caused by Your abuse, misuse, accident, alteration
                  (including use of an RPA), or unauthorized modification or
                  installation of Our Service. This section only applies to
                  Members that have paid the applicable Transaction Fees.
                </Typography>
              </ul>
              9.3. Service Provided As It Is Other Than the Express Warranties
              Stated
              <ul>
                <Typography
                  variant="body1"
                  color="#0b1e5b"
                  component="li"
                  sx={{
                    fontFamily: "inter",
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: "16px",
                    width: "100%",
                    marginBottom: 1,
                    listStyleType: "none",
                  }}
                >
                  In This Agreement, Our Service provided under this Agreement
                  is provided on an "as is" basis without warranties of any
                  kind, either expressed or implied, including but not limited
                  to, the implied warranties of merchantability, fitness for a
                  particular purpose, and non-infringement, all of which
                  Plychain expressly disclaims. Plychain makes no warranty (i)
                  that Our Service will meet your requirements, (ii) that Our
                  Service will be uninterrupted or error-free, or (iii) as to
                  any User Content, Plychain assumes no responsibility for
                  errors or omissions in Our Service or any other website that
                  may be linked to via Our Service. Any content obtained by you
                  or your Entity through Our Service is done at your and your
                  Entity’s own discretion and risk.
                </Typography>
              </ul>
              9.4. Exclusion of Limitations
              <ul>
                <Typography
                  variant="body1"
                  color="#0b1e5b"
                  component="li"
                  sx={{
                    fontFamily: "inter",
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: "16px",
                    width: "100%",
                    marginBottom: 1,
                    listStyleType: "none",
                  }}
                >
                  The limitations set forth in this Section 9 shall not apply to
                  damages due to bodily injury or death caused by and
                  attributable to Plychain. Some jurisdictions do not allow the
                  exclusion of certain warranties or the limitation or exclusion
                  of liability for incidental or consequential damages.
                  Accordingly, to that extent some of the above limitations of
                  this Section may not apply to You or Your Entity.
                </Typography>
              </ul>
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            10. LIMITATION OF LIABILITY
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="p"
              sx={{
                fontFamily: "inter",
                fontWeight: "400",
                textAlign: "left",
                fontSize: "16px",
                width: "100%",
                marginBottom: 1,
              }}
            >
              In no event shall Plychain or its service providers be liable
              under any contract, negligence, strict liability or other legal or
              equitable theory for (a) any special, incidental, punitive,
              indirect or consequential damages of any kind, including, without
              limitation, those resulting from interruption of use, loss or
              corruption of data, or lost profits or moral damages, whether or
              not Plychain has been advised of the possibility of such damage,
              arising out of or in connection with this Agreement or your online
              services use, or (b) for a total and aggregate liability amount
              under this Agreement in excess of the amount of subscription paid
              by you within one (1) year prior to the date of such claim for the
              applicable online services use or optional service giving rise to
              liability. The limitations set forth in this Section 10 shall not
              apply to damages due to bodily injury or death. Some jurisdictions
              do not allow the exclusion of certain warranties or the limitation
              or exclusion of liability for incidental or consequential damages.
              Accordingly, to that extent some of the above limitations of this
              Section may not apply to you or your entity.
            </Typography>
          </Typography>

          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            11. Termination or Suspension
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="p"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
              }}
            >
              11.1. Termination By You.
              <Typography
                variant="body1"
                color="#0b1e5b"
                component="p"
                sx={{
                  fontFamily: "inter",
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: "16px",
                  width: "100%",
                  marginBottom: 1,
                }}
              >
                You or your Entity may terminate this Agreement (along with Your
                Use of Service) at any time, for any reason, by notifying Us. If
                You or Your Entity are dissatisfied with Our Service or with any
                term, condition, rule, policy, guideline or practice of Plychain
                in operating Our Service, Your sole and exclusive remedy is to
                discontinue using Our Service. If You or Your Entity have paid
                Us any Subscription, You or Your Entity will not receive any
                refund under any circumstances if you cancel.
              </Typography>
              11.2. Termination or Suspension for Breach
              <Typography
                variant="body1"
                color="#0b1e5b"
                component="p"
                sx={{
                  fontFamily: "inter",
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: "16px",
                  width: "100%",
                  marginBottom: 1,
                }}
              >
                If You or Your Entity are a Member and you fail to perform any
                material obligation under this Agreement or another contract
                with Plychain (either being a breach), and You or Your Entity do
                not cure such breach within thirty (30) calendar days after
                receipt of notice of such breach, Plychain may terminate this
                Agreement or suspend Your and Your Entity’s Use of Our Service.
                Plychain may terminate the Agreement immediately (i) upon Your
                or Your Entity being the subject of a bankruptcy proceeding,
                insolvency, liquidation or similar proceedings, or (ii) to
                comply with laws applicable to Plychain for lawful operation of
                Our Service. In case of termination or suspension under this
                paragraph, if You or Your Entity have prepaid Subscription Fees,
                Plychain's only liability to You and Your Entity will be to
                refund a pro-rata portion of the fees based on the period of
                time remaining on Your or Your Entity’s prepaid Subscription If
                You or Your Entity use Our Service for illegal, fraudulent or
                abusive purpose, such use may be referred to law enforcement
                authorities without notice to You or Your Entity. If Plychain
                suspends Your or Your Entity’s Use of Our Service or terminates
                this Agreement, Plychain may communicate such suspension or
                termination, and the reason therefore, to the companies with
                which You have transacted (including via their Plychain access
                provider, as applicable), in order for such companies to be
                informed that further attempts to transact with You or Your
                Entity via Our Service are not possible until Your or Your
                Entity’s account is reactivated. If You or Your Entity are not a
                Member, Plychain may terminate the Agreement or suspend Your Use
                of Service at any time for its convenience, with or without
                notice.
              </Typography>
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            12. Survival After Termination
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="p"
              sx={{
                fontFamily: "inter",
                fontWeight: "400",
                textAlign: "left",
                fontSize: "16px",
                width: "100%",
                marginBottom: 1,
              }}
            >
              Section 3 (Confidentiality), Section 8 (Title to the Online
              Services), Section 9 (Limited Warranty and Disclaimer), Section 10
              (Limitation of Liability), Section 11 (Termination or Suspension),
              Section 14 (Measures to Prevent Unauthorized Access), Section 15
              (Infringement Indemnity by You), Section 19.3 (Unpaid Fees), and
              Section 20 (Miscellaneous) shall survive any termination of this
              Agreement.
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            13. Compliance with Laws
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="p"
              sx={{
                fontFamily: "inter",
                fontWeight: "400",
                textAlign: "left",
                fontSize: "16px",
                width: "100%",
                marginBottom: 1,
              }}
            >
              Subject to the terms herein, (i) Plychain agrees to comply with
              all applicable laws, statutes, ordinances and regulations
              applicable to an operator of general purpose online services, and
              (ii) You and your Entity agree to comply with all applicable laws,
              statutes, ordinances and regulations regarding Your and your
              Entity’s Use of Service. This includes obtaining any required
              export authorizations. Plychain does not assume the responsibility
              or liability for failure to obtain appropriate authorizations.
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            14. Measures to Prevent Unauthorized Access
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="p"
              sx={{
                fontFamily: "inter",
                fontWeight: "400",
                textAlign: "left",
                fontSize: "16px",
                width: "100%",
                marginBottom: 1,
              }}
            >
              You hereby acknowledge and agree that Plychain is required to take
              reasonable measures to prevent unauthorized access to Our Service.
              You therefore acknowledge and agree that Plychain may a)
              automatically check any Entity and/or User registration data and
              other information you provide about Your identity against
              applicable Embargoed Country lists and applicable sanctioned party
              lists; b) regularly repeat such checks whenever an applicable
              Embargoed Country lists and applicable sanctioned party lists is
              updated or when you update your information; c) block your access
              to Our Service in case of a potential match; and d) in case of a
              potential match contact You in order to confirm Your and/or your
              Entity’s identity. ‍ The foregoing provision shall survive any
              termination or expiration of this Agreement.
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            15. Infringement Indemnity by You
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="p"
              sx={{
                fontFamily: "inter",
                fontWeight: "400",
                textAlign: "left",
                fontSize: "16px",
                width: "100%",
                marginBottom: 1,
              }}
            >
              You and your Entity agree to only submit information respectively
              data to Our Service if you have a right to reproduce, disclose,
              and distribute such data respectively. You and Your Entity agree
              to defend, indemnify, and hold Plychain harmless for any third
              party claim brought against Plychain alleging that data or other
              information You submit to Our Service infringes the intellectual
              property laws or rights of others ("Improper Content Claim"). In
              the event of an Improper Content Claim, Plychain shall (i)
              promptly notify You or your Entity in writing of any such Improper
              Content Claim; (ii) permit You or your Entity to control and
              direct the investigation, preparation, defense and settlement of
              the claim; and (iii) provide reasonable assistance and cooperate
              with You or your Entity in the defense of same, at Your or your
              Entity’s expense. Plychain shall not be responsible for any
              Intellectual Property, inaccuracy or other claims made against You
              or Your entity by a third party or by any individual or
              institution.
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            16. Relationship of Parties
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="p"
              sx={{
                fontFamily: "inter",
                fontWeight: "400",
                textAlign: "left",
                fontSize: "16px",
                width: "100%",
                marginBottom: 1,
              }}
            >
              Each party shall be and act as an independent contractor of the
              other and shall not bind nor attempt to bind the other to any
              contract. No agency, partnership, joint venture, employee-employer
              or franchisor-franchisee relationship is intended nor created by
              this Agreement.
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            17. Communicating with You
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="p"
              sx={{
                fontFamily: "inter",
                fontWeight: "400",
                textAlign: "left",
                fontSize: "16px",
                width: "100%",
                marginBottom: 1,
              }}
            >
              Any communication sent via email by Plychain to the designated
              contacts (either the primary or secondary contacts) You or your
              Entity provide during the registration process or Membership
              sign-up process shall constitute valid notice from Plychain to You
              and your Entity. You and your Entity acknowledge and agree to keep
              Plychain informed in a timely manner of any change in the
              designated contacts or their correct email addresses. Plychain is
              entitled to rely on the validity of the contact information (email
              addresses, fax number, or otherwise) for the designated contacts
              You or your Entity provide Plychain, even if Plychain receives an
              automated response email indicating that the email might not or
              did not reach its intended recipient.
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="h2"
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            18. Your Obligations Regarding User Names, Passwords, and Data
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="p"
              sx={{
                fontFamily: "inter",
                fontWeight: "400",
                textAlign: "left",
                fontSize: "16px",
                width: "100%",
                marginBottom: 1,
              }}
            >
              <Typography
                variant="body1"
                color="#0b1e5b"
                component="li"
                sx={{
                  fontFamily: "inter",
                  fontWeight: "500",
                  textAlign: "left",
                  fontSize: "24px",
                  width: "100%",
                  marginBottom: 1,
                  listStyleType: "none",
                  marginLeft: 0,
                }}
              >
                18.1. Keeping Your user name and password confidential
              </Typography>
              You shall notify Plychain immediately upon learning of any
              unauthorized use of Your user name or password. For any
              instruction, communication, or other information ("Transmission")
              Plychain receives from someone using your user name and password,
              Plychain is entitled to consider such Transmission as having been
              sent by You.
              <br />
              <br />
              <Typography
                variant="body1"
                color="#0b1e5b"
                component="li"
                sx={{
                  fontFamily: "inter",
                  fontWeight: "500",
                  textAlign: "left",
                  fontSize: "24px",
                  width: "100%",
                  marginBottom: 1,
                  listStyleType: "none",
                  marginLeft: 0,
                }}
              >
                18.2. Compliance with privacy, consumer and other laws and
                regulations
              </Typography>
              Complying with all applicable privacy, consumer and other laws and
              regulations with respect to Your (i) provision, use and disclosure
              of data; and (ii) use of Our Service. Additionally, Your use of
              Our Service may include the routing of data to one or more
              countries other than Your or Your Entity’s country; therefore You
              must ensure that Your use of data on Our Service does not conflict
              with applicable export restrictions, data protection or other
              applicable laws and regulations.
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="div" // Use 'div' instead of 'h2'
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            19. Agreement to Pay Fees and Taxes
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="div"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
                marginLeft: 0,
              }}
            >
              19.1. Fees for Membership and Optional Services.
              <Typography
                variant="body1"
                color="#0b1e5b"
                component="div"
                sx={{
                  fontFamily: "inter",
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: "16px",
                  width: "100%",
                  marginBottom: 1,
                }}
              >
                Unless otherwise individually and explicitly agreed with Us,
                fees for Subscription and other Services are continuous and
                billed at the rates then in effect. You may pay Us by a method
                accepted by Us. Notwithstanding your due cancellation, you agree
                to pay all fees that You incurred prior to your cancellation.
                Fees are instantly deducted based on the payment method and
                frequency agreed upon and are non-refundable once paid.
              </Typography>
            </Typography>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="div"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
                marginLeft: 0,
              }}
            >
              19.2. Taxes
              <Typography
                variant="body1"
                color="#0b1e5b"
                component="div"
                sx={{
                  fontFamily: "inter",
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: "16px",
                  width: "100%",
                  marginBottom: 1,
                }}
              >
                You agree to pay any applicable taxes, VAT, withholding tax, and
                other government-imposed payments ("Your Taxes"). All valid
                direct pay permits or tax exemption certificates must be
                provided upon registration. The address associated with Your
                Entity’s profile (Company Name and Address) provides the
                applicable tax jurisdiction for the determination of such taxes.
              </Typography>
            </Typography>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="div"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
                marginLeft: 0,
              }}
            >
              19.3. Unpaid Fees or Taxes
              <Typography
                variant="body1"
                color="#0b1e5b"
                component="div"
                sx={{
                  fontFamily: "inter",
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: "16px",
                  width: "100%",
                  marginBottom: 1,
                }}
              >
                If You fail to pay any due Fees or Your Taxes under this
                Agreement or fees owed under a separate contract with Plychain
                by the due date, You agree that We may (a) terminate and/or
                suspend Your Use of Service and/or suspend Your account as to
                the relationship applicable to the unpaid Fees; (b) bill charges
                to Your credit/debit card for such Fees or Your Taxes or send an
                invoice directly to You; (c) charge You the interest as a late
                charge of 1.5% per month or the maximum amount allowed by law,
                whichever is less; (d) assign unpaid late balances to a
                collection agency for collection; and/or (e) pursue so any
                available avenue of legal or equitable relief relating to
                amounts You owe Us. In addition, You agree and acknowledge that
                Plychain retains the right to pursue You for unpaid fees even if
                Your access has been terminated and/or suspended pursuant to the
                prior sentence. You agree to reimburse Us for all expenses
                Plychain incurs to recover amounts due, including attorneys'
                fees, collection expenses, and other expenses. Plychain may
                delegate invoicing and/or collections of Fees to any third
                party, including any subsidiary, affiliate or parent.
              </Typography>
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            color="#0b1e5b"
            component="div" // Use 'div' instead of 'h2'
            sx={{
              lineHeight: 1.6,
              textAlign: "left",
              marginTop: 2.8,
              fontFamily: "nexa",
              width: "100%",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "32px",
              },
            }}
          >
            20. Miscellaneous
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="div"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
                marginLeft: 0,
              }}
            >
              20.1. Notice
              <Typography
                variant="body1"
                color="#0b1e5b"
                component="div"
                sx={{
                  fontFamily: "inter",
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: "16px",
                  width: "100%",
                  marginBottom: 1,
                }}
              >
                Any notice ("Notice") sent to the other party under this
                Agreement must be in writing and in the English language, where
                such Notice relates to the Agreement or other legal issues. No
                failure or delay in exercising any right hereunder will operate
                as a waiver thereof, nor will any partial exercise of any right
                or power hereunder preclude further exercise.
              </Typography>
            </Typography>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="div"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
                marginLeft: 0,
              }}
            >
              20.2. Assignment, Transfer and Delegation
              <Typography
                variant="body1"
                color="#0b1e5b"
                component="div"
                sx={{
                  fontFamily: "inter",
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: "16px",
                  width: "100%",
                  marginBottom: 1,
                }}
              >
                You may not assign or transfer this Agreement without the prior
                explicit written consent of Plychain. Plychain may assign our
                interest in this Agreement to any now-existing or future direct
                or indirect Parent or subsidiary of Plychain, or pursuant to any
                assignment of Our Service, or any corporate sale or
                restructuring. We may also assign or delegate certain of our
                rights and responsibilities under this Agreement to independent
                contractors or other third parties and we agree to bind any such
                independent contractors or third parties to the same obligations
                and standards of performance contained in this Agreement. In any
                case, Plychain will retain ultimate and complete responsibility
                for the full performance of this Agreement, whether certain
                responsibilities have been delegated to any such third party, or
                not.
              </Typography>
            </Typography>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="div"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
                marginLeft: 0,
              }}
            >
              20.3. Unenforceability or Invalidity
              <Typography
                variant="body1"
                color="#0b1e5b"
                component="div"
                sx={{
                  fontFamily: "inter",
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: "16px",
                  width: "100%",
                  marginBottom: 1,
                }}
              >
                If any provision of this Agreement shall be adjudged by any
                court of competent jurisdiction to be unenforceable or invalid,
                that provision shall be limited or eliminated to the minimum
                extent necessary so that this Agreement shall otherwise remain
                in full force and effect and enforceable.
              </Typography>
            </Typography>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="div"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
                marginLeft: 0,
              }}
            >
              20.4. Jurisdiction, Selected Venue and Legal Fees
              <Typography
                variant="body1"
                color="#0b1e5b"
                component="div"
                sx={{
                  fontFamily: "inter",
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: "16px",
                  width: "100%",
                  marginBottom: 1,
                }}
              >
                Unless otherwise agreed by the parties, this Agreement shall be
                deemed to have been made in, and shall be construed pursuant to
                the laws of the state of Delaware and the federal American laws
                applicable therein, excluding its conflict of laws provisions,
                and without regard to the United Nations Convention on the
                International Sale of Goods. Any legal action or proceeding
                relating to this Agreement shall be subject only to Arbitration
                in the city of New York, New York. (the "Selected Venue(s)"),
                and each party hereby consents to this jurisdiction. Both You
                and Plychain hereby irrevocably and unconditionally undertake to
                take any and all steps which may be necessary in order to confer
                jurisdiction on the Selected Venue in the manner of Arbitration
                so stipulated, in the event of which, parties shall bear their
                own costs.
              </Typography>
            </Typography>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="div"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
                marginLeft: 0,
              }}
            >
              20.5. Complete and Exclusive Statement
              <Typography
                variant="body1"
                color="#0b1e5b"
                component="div"
                sx={{
                  fontFamily: "inter",
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: "16px",
                  width: "100%",
                  marginBottom: 1,
                }}
              >
                This Agreement is the complete and exclusive statement and an
                absolute integration of the mutual understanding of the parties
                and supersedes and cancels all previous and contemporaneous
                written and oral agreements, representations, proposals,
                discussions and communications relating to the subject matter of
                this Agreement. You agree to be bound by the most current
                version of this Agreement together with our Terms and
                Conditions.
              </Typography>
            </Typography>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="div"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
                marginLeft: 0,
              }}
            >
              20.6. Governing English Version
              <Typography
                variant="body1"
                color="#0b1e5b"
                component="div"
                sx={{
                  fontFamily: "inter",
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: "16px",
                  width: "100%",
                  marginBottom: 1,
                }}
              >
                The English version of this Agreement shall govern in the event
                of any conflict or substantive translation changes in a
                non-English language. All article or section headings, or
                exhibit names, are for reference and convenience only and shall
                not be considered in the interpretation of the Agreement.
              </Typography>
            </Typography>
            <Typography
              variant="body1"
              color="#0b1e5b"
              component="div"
              sx={{
                fontFamily: "inter",
                fontWeight: "500",
                textAlign: "left",
                fontSize: "24px",
                width: "100%",
                marginBottom: 1,
                marginLeft: 0,
              }}
            >
              20.7. Arbitration
              <Typography
                variant="body1"
                color="#0b1e5b"
                component="div"
                sx={{
                  fontFamily: "inter",
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: "16px",
                  width: "100%",
                  marginBottom: 1,
                }}
              >
                ARBITRATION NOTICE AND CLASS ACTION WAIVER: YOU AGREE THAT
                DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING,
                INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE
                IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
              </Typography>
            </Typography>
          </Typography>
        </Box>
        <br />
        <br />
        <br />
      </Container>
      <Footer />
    </>
  );
};

export default TermsOfUse;
