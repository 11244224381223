import {
  Box,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { BiEditAlt } from "react-icons/bi";
import { LuDownload } from "react-icons/lu";
import { GoArrowLeft } from "react-icons/go";
import SharedButton from "../../SharedComponent/Button/SharedButton";
import ImageSlider from "../../SharedComponent/ImageSlider";
import ProductModel from "../../models/productModel";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface ProductDetailOverviewProps {
  data: ProductModel;
}

export const ProductDetailOverview = (props: ProductDetailOverviewProps) => {
  const navigate: NavigateFunction = useNavigate();

  const handleGoBack = () => {
    window.history.back(); // This will navigate back to the previous page in the browser's history.
  };

  return (
    <>
      <Box className={"MainBoxComponent"}>
        <Paper elevation={0}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0}>
              <Grid item xs marginTop={"5px"}>
                <Typography textAlign={"left"} variant="h5" component={"h4"}>
                  <IconButton onClick={() => handleGoBack()}>
                    <GoArrowLeft />
                  </IconButton>
                  {props.data.name}
                </Typography>
              </Grid>
              <Grid item xs marginTop={"5px"} textAlign={"right"}>
                <Tooltip title="Edit" arrow>
                  <IconButton
                    aria-label="edit"
                    onClick={(e) => {
                      navigate("/updateProduct", {
                        state: {
                          mode: "UPDATE",
                          data: JSON.stringify(props.data),
                        },
                      });
                    }}
                  >
                    <BiEditAlt />
                  </IconButton>
                </Tooltip>
                {/* <Tooltip title="Download" arrow>
                  <IconButton aria-label="Download">
                    <LuDownload />
                  </IconButton>
                </Tooltip> */}
              </Grid>
            </Grid>
          </Box>
          <Box
            marginTop={"10px"}
            marginBottom={"10px"}
            sx={{ flexGrow: 1 }}
            className={"ProductPanelBox"}
          >
            <Grid container spacing={0}>
              <Grid item md={4} xs={12} marginTop={"5px"}>
                <ImageSlider imagesUrl={props.data.thumbnails_url} />
              </Grid>
              <Grid item md={8} xs={12}>
                <Box className={"ProductOverview"} marginTop={"30px"}>
                  <Typography textAlign={"left"} variant="h6" component={"h1"}>
                    Product Overview
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12} marginTop={"20px"}>
                      <Typography
                        fontSize={"12px"}
                        textAlign={"left"}
                        variant="body2"
                        component={"p"}
                      >
                        PRODUCT ID
                      </Typography>
                      <Typography
                        textAlign={"left"}
                        fontSize={"18px"}
                        variant="h6"
                        component={"h6"}
                        
                      >
                        {props.data.generated_product_id}
                      </Typography>
                    </Grid>
                    <Grid item md={4} xs={12} marginTop={"20px"}>
                      <Typography
                        fontSize={"12px"}
                        textAlign={"left"}
                        variant="body2"
                        component={"p"}
                      >
                        QUANTITY
                      </Typography>
                      <Typography
                        textAlign={"left"}
                        fontSize={"18px"}
                        variant="h6"
                        component={"h6"}
                        
                      >
                        {props.data.quantity} {props.data.quantity_unit}
                      </Typography>
                    </Grid>
                    <Grid item md={4} xs={12} marginTop={"20px"}>
                      <Typography
                        fontSize={"12px"}
                        textAlign={"left"}
                        variant="body2"
                        component={"p"}
                      >
                        CONDITION
                      </Typography>
                      <Typography
                        textAlign={"left"}
                        fontSize={"18px"}
                        variant="h6"
                        component={"h6"}
                        
                      >
                        {props.data?.condition?.toUpperCase()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12} marginTop={"20px"}>
                      <Typography
                        fontSize={"12px"}
                        textAlign={"left"}
                        variant="body2"
                        component={"p"}
                      >
                        SKU CODE
                      </Typography>
                      <Typography
                        textAlign={"left"}
                        fontSize={"18px"}
                        variant="h6"
                        component={"h6"}
                        
                      >
                        {props.data.sku}
                      </Typography>
                    </Grid>
                    <Grid item md={4} xs={12} marginTop={"20px"}>
                      <Typography
                        fontSize={"12px"}
                        textAlign={"left"}
                        variant="body2"
                        component={"p"}
                      >
                        WEIGHT
                      </Typography>
                      <Typography
                        textAlign={"left"}
                        fontSize={"18px"}
                        variant="h6"
                        component={"h6"}
                        
                      >
                        {props.data.weight} {props.data.weight_unit}
                      </Typography>
                    </Grid>
                    <Grid item md={4} xs={12} marginTop={"20px"}>
                      <Typography
                        fontSize={"12px"}
                        textAlign={"left"}
                        variant="body2"
                        component={"p"}
                      >
                        DIMENSION
                      </Typography>
                      <Typography
                        textAlign={"left"}
                        fontSize={"18px"}
                        variant="h6"
                        component={"h6"}
                        
                      >
                        {props.data.length} {props.data.length_unit} x{" "}
                        {props.data.width} {props.data.width_unit} x{" "}
                        {props.data.height} {props.data.height_unit}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12} marginTop={"20px"}>
                      <Typography
                        fontSize={"12px"}
                        textAlign={"left"}
                        variant="body2"
                        component={"p"}
                      >
                        COLOR
                      </Typography>
                      <Typography
                        textAlign={"left"}
                        fontSize={"18px"}
                        variant="h6"
                        component={"h6"}
                        
                      >
                        {props.data.color}
                      </Typography>
                    </Grid>
                    <Grid item md={4} xs={12} marginTop={"20px"}>
                      <Typography
                        fontSize={"12px"}
                        textAlign={"left"}
                        variant="body2"
                        component={"p"}
                      >
                        DATE CREATE
                      </Typography>
                      <Typography
                        textAlign={"left"}
                        fontSize={"18px"}
                        variant="h6"
                        component={"h6"}
                        
                      >
                        {props.data.getFormattedCreatedDate()}
                      </Typography>
                    </Grid>
                    <Grid item md={4} xs={12} marginTop={"20px"}>
                      <Typography
                        fontSize={"12px"}
                        textAlign={"left"}
                        variant="body2"
                        component={"p"}
                      >
                        TIME CREATE
                      </Typography>
                      <Typography
                        textAlign={"left"}
                        variant="h6"
                        component={"h6"}
                        
                      >
                        {props.data.getFormattedCreatedTime()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12} marginTop={"20px"}>
                      <Typography
                        fontSize={"12px"}
                        textAlign={"left"}
                        variant="body2"
                        component={"p"}
                      >
                        DESCRIPTION
                      </Typography>
                      <Typography
                        textAlign={"left"}
                        fontSize={"18px"}
                        variant="h6"
                        component={"h6"}
                        
                      >
                        {props.data.description}
                      </Typography>
                    </Grid>
                    <Grid item md={4} xs={12} marginTop={"20px"}>
                      <Typography
                        fontSize={"12px"}
                        textAlign={"left"}
                        variant="body2"
                        component={"p"}
                      >
                        PRODUCT STORY
                      </Typography>
                      <Typography
                        textAlign={"left"}
                        fontSize={"18px"}
                        variant="h6"
                        component={"h6"}
                        
                      >
                        {props.data.product_story}
                      </Typography>
                    </Grid>
                    <Grid item md={4} xs={12} marginTop={"20px"}>
                      <Typography
                        fontSize={"12px"}
                        textAlign={"left"}
                        variant="body2"
                        component={"p"}
                      >
                        REUSE OR RECYCLE INFORMATION
                      </Typography>
                      <Typography
                        textAlign={"left"}
                        fontSize={"18px"}
                        variant="h6"
                        component={"h6"}
                        
                      >
                        {props.data.reuse_recycle_info}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </>
  );
};
