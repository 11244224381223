import { Box, Grid, Paper, Typography } from "@mui/material";
import { AiOutlinePlus } from "react-icons/ai";
import { CiSearch } from "react-icons/ci";
import SharedButton from "../../SharedComponent/Button/SharedButton";
import { InputField } from "../../SharedComponent/TextField/InputField";
import { Link } from "react-router-dom";
import { useState } from "react";

// const panelData = [
//     {
//         id:"OverViewPanel01",
//         icon:<HiOutlinePresentationChartLine/>,
//         title: 'Products',
//         value: '10.9k',
//         description: 'Total number of Products',
//     },
//     {
//         id:"OverViewPanel02",
//         icon:<LuShip />,
//         title: 'Shipments',
//         value: '500',
//         description: 'Total number of Users',
//     },
//     {
//         id:"OverViewPanel03",
//         icon:<LuScanLine />,
//         title: 'Customer Scans',
//         value: '15.7k',
//         description: 'Total number of Users',
//     },
//     {
//         id:"OverViewPanel04",
//         icon:<HiOutlineLocationMarker />,
//         title: 'Shipment Locations',
//         value: '5',
//         description: 'Total number of Users',
//     },
//     // Add more data for additional panels as needed
// ];

interface ProductOverviewProps {
  value: string;
  onSearchTextChange: (data: string) => void;
}

export const ProductOverview = (props: ProductOverviewProps) => {
  const [searchText, setSearchText] = useState("");

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchText(e.target.value);
    props.onSearchTextChange(e.target.value);
  };

  return (
    <>
      <Paper elevation={0}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0}>
            <Grid item xs marginTop={"5px"}>
              <Typography textAlign={"left"} variant="h5" component={"h4"}>
                Products
              </Typography>
              <Typography textAlign={"left"} variant="body2" component={"p"}>
                Manage & Add Products
              </Typography>
            </Grid>
            <Grid item xs marginTop={"5px"}></Grid>
            <Grid item xs marginTop={"5px"}>
              <Paper elevation={1} className="ProductTotal">
                <Typography textAlign={"left"} variant="h4" component={"h4"}>
                  {props.value}
                </Typography>
                <Typography textAlign={"left"} variant="body2" component={"p"}>
                  Total number of Products
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Box
          marginTop={"10px"}
          marginBottom={"10px"}
          sx={{ flexGrow: 1, alignItems: "center" }}
          className={"ProductPanelBox"}
        >
          <Grid container spacing={0}>
            <Grid item xs={3} marginTop={"5px"}>
              <Link to={"/createProduct"}>
                <SharedButton
                  className="Primary-button"
                  title="Create New Product"
                  value={"Create New Product"}
                  variant="contained"
                  startIcon={<AiOutlinePlus />}
                />
              </Link>
            </Grid>
            <Grid item xs={5} alignItems={"center"}>
              <InputField
                value={searchText}
                placeholder="Search by Product Name..."
                icon={<CiSearch />}
                className="ProductSearchBox"
                type="text"
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  );
};
