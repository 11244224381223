import { v4 as uuidv4 } from "uuid";

const generateUniqueId = (): string => {
  const uniqueID = `PLC/${uuidv4()}`;

  return uniqueID;
};

const formatNumber = (number: number) => {
  if (number >= 1e9) {
    return (number / 1e9).toFixed(1) + "B";
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(1) + "M";
  } else if (number >= 1e3) {
    return (number / 1e3).toFixed(1) + "K";
  }
  return number.toString();
};

const convertFrom24HrsTo12Hrs = (timeIn24hrs: string) => {
  const [hours, minutes] = timeIn24hrs.split(":");
  let period = "AM";

  // Convert hours to 12-hour format and determine AM/PM
  let hours12hr = parseInt(hours, 10);
  if (hours12hr >= 12) {
    if (hours12hr > 12) {
      hours12hr -= 12;
    }
    period = "PM";
  }

  // Format the time in 12-hour format
  const time12hr = `${hours12hr}:${minutes} ${period}`;
  return time12hr;
};

const convertFrom12HrsTo24Hrs = (time12Hr: string): string | null => {
  const regex = /^(\d+):(\d+) ([APap][Mm])$/;
  const match = time12Hr.match(regex);

  if (!match) {
    return null; // Invalid input format
  }

  let hour = parseInt(match[1]);
  const minute = parseInt(match[2]);
  const meridian = match[3].toUpperCase();

  if (hour === 12 && meridian === "AM") {
    // Special case: 12:00 AM should be converted to 00:00
    hour = 0;
  } else if (hour !== 12 && meridian === "PM") {
    // Add 12 to the hour when it's PM, except for 12:00 PM
    hour += 12;
  }

  return `${hour.toString().padStart(2, "0")}:${minute
    .toString()
    .padStart(2, "0")}`;
};

const getOriginalFileName = (url: string) => {
  // Split the URL by underscores
  const parts: string[] = url.split("_");
  // Get the last part (before the last dot)
  const lastPart: string = parts[parts.length - 1];

  // Extract the words before the last dot
  const words: string = lastPart.split(".")[0];

  return decodeURIComponent(lastPart);
};

const reloadWebsite = () => (window.location.href = "/");

const moveToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export {
  generateUniqueId,
  formatNumber,
  convertFrom24HrsTo12Hrs,
  convertFrom12HrsTo24Hrs,
  getOriginalFileName,
  reloadWebsite,
  moveToTop,
};
