
import { Box, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import { BiEditAlt } from 'react-icons/bi';
import { LuDownload } from 'react-icons/lu';
import { GoArrowLeft } from 'react-icons/go';
import ImageSlider from '../../SharedComponent/ImageSlider';
import MyDatePicker from '../../SharedComponent/DatePicker/MyDatePicker';
import SharedButton from '../../SharedComponent/Button/SharedButton';

const steps = [
    {
        label: '16 Sept. 2023',
        address: `Imported - New York, United States of America`,
        description: `Previous Owner : Fueled by Ramen Co.
      44 Mind Park street, 5th floor, 29015, Málaga, Spain.`,
    },
    {
        label: '05 Mar. 2023',
        address: `Imported - New York, United States of America`,
        description: `Previous Owner : Fueled by Ramen Co.
      44 Mind Park street, 5th floor, 29015, Málaga, Spain.`,
    },
    {
        label: '10 Mar. 2023',
        address: `Imported - New York, United States of America`,
        description: `Previous Owner : Fueled by Ramen Co.
      44 Mind Park street, 5th floor, 29015, Málaga, Spain.`,
    },
];

export const ProductHistoryOverview = () => {
    const handleGoBack = () => {
        window.history.back();
    };


    return (
        <>
            <Box className={"MainBoxComponent"}>
                <Paper elevation={0} >
                    <Box sx={{ flexGrow: 1 }} >
                        <Grid container spacing={0}>
                            <Grid item xs marginTop={"5px"}>
                                <Typography textAlign={"left"} variant='h5' component={'h4'}>
                                    <IconButton onClick={() => handleGoBack()}>
                                        <GoArrowLeft />
                                    </IconButton>Textile Rolls
                                </Typography>
                            </Grid>
                            <Grid item xs marginTop={"5px"} textAlign={'right'}>
                                <Tooltip title="Edit" arrow>
                                    <IconButton
                                        aria-label="edit"
                                    >
                                        <BiEditAlt />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Download" arrow>
                                    <IconButton
                                        aria-label="Download"
                                    >
                                        <LuDownload />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box marginTop={"10px"} marginBottom={"10px"} sx={{ flexGrow: 1 }} className={"ProductPanelBox"}>
                        <Grid container spacing={0} alignItems={'start'}>
                            <Grid item md={4} xs={12} marginTop={"25px"}>
                                <ImageSlider imagesUrl={[]} />
                            </Grid>
                            <Grid item md={8} xs={12}>
                                <Box className={'ProductOverview'} marginTop={'0px'}>
                                    <Grid container spacing={0}>
                                        <Grid item md={8} xs marginTop={"20px"}>
                                            <Typography fontWeight={600}  textAlign={"left"} variant='h6' component={'h1'}>Product History</Typography>
                                        </Grid>
                                        <Grid item md={4} xs marginTop={"20px"} justifyContent={"end"}>
                                            <MyDatePicker />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <Grid item xs marginTop={"0px"}>
                                            <Box sx={{ maxWidth: 400 }}>

                                                {steps.map((step, index) => (
                                                    <Grid container>
                                                        <Grid item md={1} xs>
                                                            <img src="./Assets/Image/SideStep.png" alt="" />
                                                        </Grid>
                                                        <Grid item md={11} xs>
                                                            <Paper className='productHistory' key={step.label} elevation={1} sx={{ p: 1, my: 0 }}>
                                                                <Typography variant="body2" component={'h6'}>{step.label}</Typography>
                                                                <Typography variant="body1" component={'p'} sx={{ my: 1 }}>{step.address}</Typography>
                                                                <Typography variant="body2" component={'h6'}>{step.description}</Typography>
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>

                                                )
                                                )}
                                            </Box>
                                            <Grid container spacing={2}>
                                                <Grid item xs marginTop={"50px"}>
                                                    <SharedButton
                                                       className="light-grey01"
                                                        title="Details"
                                                        value={"Details"}
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{
                                                            boxShadow:'0px'   
                                                        }}
                                                    />

                                                    <SharedButton
                                                        
                                                        className="Primary-button"
                                                        title="Product Details"
                                                        value={"Product Details"}
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{
                                                            marginLeft: '20px',
                                                            boxShadow:'0px'   
                                                        }}
                                                    />

                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        </>
    )
}
