import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  FormGroup,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import { Container, Grid, Typography } from "@mui/material";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import SharedButton from "../../SharedComponent/Button/SharedButton";

const ContactUs = () => {
  return (
    <Box
      sx={{
        // border: "1px solid blue",
        maxWidth: "1440px",
        paddingX: "24px",
        marginX: "auto",
      }}
    >
      <Box
        sx={{
          background: `url('./Assets/Image/ContactUsBG.png') no-repeat center center fixed`,
          backgroundSize: "cover",
          marginTop: 20,
          marginBottom: 20,
          borderRadius: { xs: "251px", md: "320px" },
          // border: "1px solid yellow",
          maxWidth: "1200px",
          marginX: "auto",
        }}
      >
        <Grid
          container
          // spacing={5}
          // justifyContent={"center"}
          // alignItems={"center"}
          sx={{
            // border: "1px solid red",
            paddingY: "100px",
            paddingX: { xs: "16px", sm: "8%", md: "11%" },
            position: "relative",
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          <Grid
            container
            direction={"column"}
            // spacing={2}
            sx={{
              // pl: {
              //   xs: "0px",
              //   sm: 5,
              //   md: 5,
              // },
              // border: "1px solid green",

              width: { xs: "100%", md: "50%" },
            }}
          >
            <Typography
              textAlign={"left"}
              variant="body1"
              component={"h5"}
              // marginTop={2}
              sx={{
                color: "#0B1E5B",
                width: {
                  xs: "100%",

                  md: "80%",
                },
                textAlign: {
                  xs: "center",
                  md: "left",
                },
                // border: "1px solid red",
                marginBottom: "16px",
              }}
            >
              Have questions? Want some help to get started?
            </Typography>

            <Typography
              textAlign={"left"}
              variant="h3"
              component={"h2"}
              sx={{
                fontFamily: "Nexa",
                color: "#0B1E5B",
                fontSize: {
                  xs: "1.8rem",
                  sm: "2.8rem",
                  md: "2.8rem",
                },
                width: {
                  xs: "100%",
                  // sm: "75%",
                  // md: "75%",
                },
                // border: "1px solid blue",
                marginBottom: "40px",
                textAlign: {
                  xs: "center",
                  md: "left",
                },
              }}
            >
              Don’t be a stranger, we’ll love to hear from you
            </Typography>

            <Link
              to={"/contactus"}
              onClick={() => {}}
              color="inherit"
              style={{
                // border: "1px solid green",

                width: "100%",
                // maxWidth: "400px",
                // margin: "0 auto",
                position: "relative",
              }}
            >
              <SharedButton
                className="LandingBtnHover"
                value={"Get In Touch"}
                variant="text"
                sx={{
                  textAlign: "left",
                  width: {
                    xs: "100%",
                  },
                  maxWidth: {
                    xs: "400px",
                    // md: "100%",
                  },
                  position: {
                    xs: "relative",
                    md: "absolute",
                  },
                  left: {
                    md: "0",
                  },
                  fontFamily: "Inter",
                  fontSize: { xs: "14px", md: "16px" },
                  textTransform: "none",
                }}
              />
            </Link>
          </Grid>

          <Box
            // item
            // sm
            sx={{
              width: {
                xs: "100%",
                md: "40%",
              },
              p: {
                xs: 0,
              },
              // border: "1px solid red",
              position: { xs: "relative", md: "absolute" },
              right: "0",
              top: { md: "50%" },
              height: "380px",
              marginTop: { md: "-189.2px" },
            }}
          >
            <img
              src="./Assets/Image/ContactUs.png"
              alt=""
              // className="contactusImg"
              style={{
                // border: "1px solid yellow",
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default ContactUs;
