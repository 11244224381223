import moment from "moment";
import BlockchainTransactionModel from "./blockTransactionModel";
import { ShipmentModel } from "./shipmentModel";

export default class CheckpointModel {
  id: number;
  shipment_id: number;
  address: string;
  country: string;
  city: string;
  date: string;
  time: string;
  hash_data?: string | null;
  is_deleted: boolean;
  createdAt: Date;
  updatedAt: Date;
  Shipment: ShipmentModel;
  BlockchainTransaction: BlockchainTransactionModel[];

  constructor(data: Partial<CheckpointModel> = {}) {
    Object.assign(this, data);
  }

  getFormattedDate = () => {
    return moment(this.date).format("DD MMM YYYY");
  };
}
