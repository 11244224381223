import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  FormGroup,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import { Container, Grid, Typography } from "@mui/material";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import SharedButton from "../../SharedComponent/Button/SharedButton";
import AboutCard from "./AboutCard";

const SliderTransparency = () => {
  return (
    <Box
      sx={{
        // border: "1px solid blue",
        maxWidth: "1440px",
        paddingX: "24px",
        marginX: "auto",
      }}
    >
      <AboutCard
        header={"Bottom Line Profitability"}
        words={
          "While PlyChain primarily serves businesses, our solution indirectly benefits end consumers. Give your customers the assurance they seek about the authenticity, origin, and sustainability of your products."
        }
        image={"./Assets/Image/Slider04.png"}
        numbers={1}
      />
      <AboutCard
        header={" Enhanced Transparency"}
        words={
          "Gain unprecedented visibility into your supply chain, from raw materials to end products. Know where your products come from and build trust with your customers."
        }
        image={"./Assets/Image/Slider01.png"}
        numbers={2}
      />
      <AboutCard
        header={"Blockchain-Powered"}
        words={
          "Our state-of-the-art blockchain technology ensures data accuracy, security, and immutability, providing a tamper-proof record of your supply chain activities."
        }
        image={"./Assets/Image/Slider02.png"}
        numbers={3}
      />
      <AboutCard
        header={"Sustainability"}
        words={
          "Take meaningful steps toward a more sustainable future. Showcase your commitment to ethical sourcing and sustainability, attracting conscious consumers."
        }
        image={"./Assets/Image/Slider03.png"}
        numbers={4}
      />
      <AboutCard
        header={"Competitive Advantage"}
        words={
          " Stay ahead of the competition by demonstrating your dedication to responsible sourcing and transparent supply chain practices."
        }
        image={"./Assets/Image/Slider05.png"}
        numbers={5}
      />
    </Box>
  );
};

export default SliderTransparency;
