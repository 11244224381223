import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  InputAdornment,
  AlertColor,
  Alert,
  Snackbar,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import { InputField } from "../../SharedComponent/TextField/InputField";
import InputFileUpload from "../../SharedComponent/TextField/InputFileUpload";
import { BsCardImage } from "react-icons/bs";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { EntityStatus, PRODUCT_WEIGHT_UNITS } from "../../constants/constants";
import { sendTransaction } from "../../utils/web3";
import ProductModel from "../../models/productModel";
import { useSelector } from "react-redux";
import { AuthModel } from "../../models/authModel";
import {
  convertFrom12HrsTo24Hrs,
  convertFrom24HrsTo12Hrs,
  generateUniqueId,
  getOriginalFileName,
} from "../../utils/utils";
import { uploadProductThumbnails } from "../../utils/aws";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import {
  createBlockTransaction,
  createShipment,
  getAllProducts,
  updateShipment,
} from "../../networking/networkCalls";
import { ShipmentModel } from "../../models/shipmentModel";
import { AxiosResponse } from "axios";

export interface ShipmentStepFormRefHandler {
  onSaveDraftClicked: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

interface ShipmentStepFormProps {
  setDraftLoading: (value: React.SetStateAction<boolean>) => void;
}
const ShipmentStepForm = forwardRef<
  ShipmentStepFormRefHandler,
  ShipmentStepFormProps
>((props, ref) => {
  const [publishLoading, setPublishLoading] = useState<boolean>(false);

  const [activeStep, setActiveStep] = useState(0);
  const [productsDataList, setProductsDataList] = React.useState<
    ProductModel[]
  >([]);

  const [product, setProduct] = React.useState<ProductModel>(undefined);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [isTCAccepted, setIsTCAccepted] = useState<boolean>(false);
  const [time, setTime] = useState<string>();

  // @ts-ignore
  const authData: AuthModel = useSelector((state) => state.auth.authData);

  const location = useLocation();

  const [toBeUpdatedShipment] = useState<ShipmentModel>(
    JSON.parse(location.state?.data || "{}")
  );

  const isUpdateMode: boolean =
    location.state && location.state.mode && location.state.mode === "UPDATE";

  const [formData, setFormData] = useState<{
    generated_shipment_id: string;
    recipient_company_name?: string;
    recipient_address?: string;
    recipient_country?: string;
    recipient_city?: string;
    expected_arrival_date?: string;
    expected_arrival_time?: string;
    product_id?: number;
    quantity?: number;
    quantity_unit?: string;
    weight?: number;
    weight_unit?: string;
    labels?: string;
    further_information?: string;
  }>({
    generated_shipment_id: "",
    recipient_company_name: "",
    recipient_address: "",
    recipient_country: "",
    recipient_city: "",
    expected_arrival_date: "",
    expected_arrival_time: "",
    product_id: undefined,
    quantity: undefined,
    quantity_unit: "",
    weight: undefined,
    weight_unit: "",
    labels: "",
    further_information: "",
  });

  const [snackBarState, setSnackBarState] = useState<{
    show: boolean;
    msg: string;
    severity: AlertColor;
  }>({
    show: false,
    msg: "",
    severity: "success",
  });

  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    if (isUpdateMode) {
      // update mode
      const existignData = {
        generated_shipment_id: toBeUpdatedShipment.generated_shipment_id,
        recipient_company_name: toBeUpdatedShipment.recipient_company_name,
        recipient_address: toBeUpdatedShipment.recipient_address,
        recipient_country: toBeUpdatedShipment.recipient_country,
        recipient_city: toBeUpdatedShipment.recipient_city,
        expected_arrival_date: toBeUpdatedShipment.expected_arrival_date,
        expected_arrival_time: toBeUpdatedShipment.expected_arrival_time,
        quantity: toBeUpdatedShipment.quantity,
        quantity_unit: toBeUpdatedShipment.quantity_unit,
        weight: toBeUpdatedShipment.weight,
        weight_unit: toBeUpdatedShipment.weight_unit,
        labels: toBeUpdatedShipment.labels,
        further_information: toBeUpdatedShipment.further_information,
      };

      setFormData(existignData);

      setTime(
        convertFrom12HrsTo24Hrs(toBeUpdatedShipment.expected_arrival_time)
      );
    } else {
      // create mode
      if (formData.generated_shipment_id === "") {
        const data = { ...formData };
        data.generated_shipment_id = generateUniqueId();
        setFormData(data);
      }
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      const res = await getAllProducts({ skipPagination: true });

      if (res) {
        if (res.status === 200) {
          const { data } = res.data;

          if ((data as Array<any>).length > 0) {
            const dataList: ProductModel[] = [];
            for (const d of data as Array<any>) {
              dataList.push(new ProductModel(d));
            }

            setProductsDataList(dataList);

            if (isUpdateMode) {
              // update mode
              if (dataList.length > 0) {
                const product: ProductModel = dataList.find(
                  (v, i) => v.id === toBeUpdatedShipment.product_id
                );

                if (product) {
                  setProduct(product);
                }
              }
            }
          }
        }
      }
    };

    getData();
  }, []);

  const hitAPI = async (data) => {
    let res: AxiosResponse<any, any>;

    if (isUpdateMode) {
      // update mode

      res = await updateShipment(data, toBeUpdatedShipment.id);
    } else {
      // create mode
      res = await createShipment(data);
    }

    if (res) {
      if (res.status === 201) {
        const { logId, hashedData } = res.data.data;
        // send transaction on blockchain
        const { success, txReceipt, error } = await sendTransaction(hashedData);

        if (success === true) {
          // transaction was successfully sent to blockchain
          // hit createBlockTransaction API
          const trnxRes = await createBlockTransaction(logId, {
            hash_data: hashedData,
            trnx_data: txReceipt.toJSON(),
          });

          if (trnxRes) {
            if (trnxRes.status === 201) {
              setSnackBarState({
                show: true,
                msg:
                  data.status === "published"
                    ? "Shipment has been created"
                    : "Shipment has been saved as draft",
                severity: "success",
              });

              setTimeout(() => {
                navigate("/shipments", { replace: true });
              }, 2000);
            } else {
              // createBlockTransaction API failed
              setSnackBarState({
                show: true,
                msg: trnxRes.data.msg,
                severity: "error",
              });
            }
          } else {
            // createBlockTransaction API failed
            setSnackBarState({
              show: true,
              msg: "Something went wrong, please try again later",
              severity: "error",
            });
          }
        } else {
          // transaction got failed on blockchain
          if (error && error.code && error.code === "INSUFFICIENT_FUND") {
            setSnackBarState({
              show: true,
              msg: error.msg,
              severity: "error",
            });
          } else {
            setSnackBarState({
              show: true,
              msg: "Something went wrong, please try again later",
              severity: "error",
            });
          }
        }
      } else {
        // createShipment API failed
        setSnackBarState({
          show: true,
          msg: res.data.msg,
          severity: "error",
        });
      }
    } else {
      // createShipment API failed
      setSnackBarState({
        show: true,
        msg: "Something went wrong, please try again later",
        severity: "error",
      });
    }
  };

  useImperativeHandle(ref, () => ({
    async onSaveDraftClicked(
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) {
      props.setDraftLoading(true);

      const data: any = {
        ...formData,
        status: EntityStatus[EntityStatus.draft],
        product_id: product !== undefined ? product.id : undefined,
        quantity_unit: product !== undefined ? product.quantity_unit : "",
      };

      if (selectedFile) {
        data.shipment_documents = [selectedFile];
      }

      await hitAPI(data);

      props.setDraftLoading(false);
    },
  }));

  const handleOnProductChange = (event: SelectChangeEvent) => {
    if (event.target.value) {
      const product = productsDataList.find(
        (v, i) => v.id === parseInt(event.target.value)
      );

      if (!product) {
        return;
      }

      setProduct(product);
    }
  };

  const fileHandler = (e: React.SyntheticEvent<HTMLInputElement, Event>) => {
    const target = e.target as HTMLInputElement;
    const selectedFile: File = (target.files as FileList)[0];

    if (selectedFile) {
      if (
        selectedFile.type === "image/jpeg" ||
        selectedFile.type === "application/pdf"
      ) {
        setSelectedFile(selectedFile);
      } else {
        setSnackBarState({
          show: true,
          msg: "Document should be jpeg or pdf",
          severity: "error",
        });
      }
    }
  };

  const handleOnWeightUnitChange = (event: SelectChangeEvent) => {
    // setWeightUnit(event.target.value);
    const data = { ...formData };
    data.weight_unit = event.target.value;

    setFormData(data);
  };

  const handleNext = async (e) => {
    let isError = false;

    switch (activeStep) {
      case 0:
        isError =
          formData.recipient_company_name === "" ||
          formData.recipient_address === "" ||
          formData.recipient_country === "" ||
          formData.recipient_city === "" ||
          formData.expected_arrival_date === "" ||
          formData.expected_arrival_time === "";

        break;
      case 1:
        isError =
          product === undefined ||
          formData.quantity === undefined ||
          formData.weight === undefined ||
          formData.weight_unit === "";

        break;
      case 2:
        isError =
          formData.labels === "" ||
          formData.further_information === "" ||
          (isUpdateMode !== true && selectedFile === undefined) ||
          (isUpdateMode === true &&
            toBeUpdatedShipment.documents_url.length === 0);

        break;
    }

    if (activeStep <= 2 && isError === true) {
      setSnackBarState({
        show: true,
        msg: "Please fill in all the required fields",
        severity: "error",
      });

      return;
    }

    if (activeStep <= 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handlePublishShipment = async (e) => {
    if (isTCAccepted === false) {
      setSnackBarState({
        show: true,
        msg: "Please Accept Plychain's Terms of Use and Privacy Policy",
        severity: "error",
      });
      return;
    }

    // create shipment
    setPublishLoading(true);

    const data: any = {
      ...formData,
      status: EntityStatus[EntityStatus.published],
      product_id: product !== undefined ? product.id : undefined,
      quantity_unit: product !== undefined ? product.quantity_unit : "",
    };

    if (isUpdateMode) {
      // update mode
      if (selectedFile) {
        data.shipment_documents = [selectedFile];
      }
    } else {
      // create mode
      data.shipment_documents = [selectedFile];
    }

    await hitAPI(data);

    setPublishLoading(false);
  };

  const onTCCheckChangeHandler = (
    event: React.SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    setIsTCAccepted(checked);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarState({ ...snackBarState, show: false });
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    const data = { ...formData };

    if (field === "expected_arrival_time") {
      setTime(e.target.value);

      data[`${field}`] = convertFrom24HrsTo12Hrs(e.target.value);
    } else if (field === "quantity") {
      if (e.target.value === "") {
        // @ts-ignore
        data[`${field}`] = "";
      } else if (product === undefined) {
        // @ts-ignore
        data[`${field}`] = "";

        setSnackBarState({
          show: true,
          msg: `Please select a product`,
          severity: "error",
        });
      } else {
        const q = parseFloat(e.target.value);
        if (q > product.quantity) {
          setSnackBarState({
            show: true,
            msg: `Quantity must be less than or equals to ${product.quantity}`,
            severity: "error",
          });

          return;
        }

        if (q < 1) {
          setSnackBarState({
            show: true,
            msg: "Quantity must be at least 1",
            severity: "error",
          });
          // @ts-ignore
          data[`${field}`] = "";
          return;
        }

        data[`${field}`] = q;
      }
    } else {
      data[`${field}`] = e.target.value;
    }

    setFormData(data);
  };

  const firstStepComponent = () => (
    <Box className={"StepForm"} width={"60%"}>
      <Grid container>
        <Grid item xs>
          <Typography textAlign={"left"} variant="h6" component={"h6"}>
            Select a Recipient
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography textAlign={"right"} variant="body1" component={"h6"}>
            1/4
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <InputField
            value={formData.recipient_company_name}
            type="text"
            label={"Company"}
            placeholder="ex. Company Name"
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => handleInputChange(e, "recipient_company_name")}
          />
          <InputField
            value={formData.recipient_address}
            type="text"
            label={"Address"}
            placeholder="Company Address"
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => handleInputChange(e, "recipient_address")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs>
          <InputField
            value={formData.recipient_country}
            type="text"
            label={"Country"}
            placeholder="Country"
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => handleInputChange(e, "recipient_country")}
          />
        </Grid>
        <Grid item xs>
          <InputField
            value={formData.recipient_city}
            type="text"
            label={"City"}
            placeholder="Select City"
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => handleInputChange(e, "recipient_city")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs>
          <InputField
            value={formData.expected_arrival_date}
            type="date"
            label={"Expected Arrival Date"}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => handleInputChange(e, "expected_arrival_date")}
          />
        </Grid>
        <Grid item xs>
          <InputField
            value={time}
            type="time"
            label={"Expected Arrival Time"}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => handleInputChange(e, "expected_arrival_time")}
          />
        </Grid>
      </Grid>
    </Box>
  );

  const secondStepComponent = () => (
    <Box className={"StepForm"} width={"60%"}>
      <Grid container marginBottom={2}>
        <Grid item xs>
          <Typography textAlign={"left"} variant="h6" component={"h6"}>
            Add Product
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography textAlign={"right"} variant="body1" component={"h6"}>
            2/4
          </Typography>
        </Grid>
      </Grid>
      <Grid container marginBottom={1}>
        <Grid item xs>
          <Typography textAlign={"left"} variant="body2" component={"p"}>
            Please select the products you have and how much you’d like to sell
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs marginBottom={2}>
          <InputLabel
            id="demo-simple-select-label"
            sx={{
              textAlign: "left",
            }}
          >
            Product*
          </InputLabel>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={`${product?.id}`}
              label=""
              onChange={handleOnProductChange}
              placeholder="Select Product or Material"
              sx={{
                textAlign: "left",
              }}
            >
              {productsDataList.map((v) => (
                <MenuItem value={v.id}>{v.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs>
          <InputField
            value={formData.quantity}
            type="number"
            label={"Quantity"}
            placeholder="Quantity"
            InputProps={{
              endAdornment: (
                <InputAdornment position={"start"}>
                  {product !== undefined ? product.quantity_unit : "Unit"}
                </InputAdornment>
              ),
            }}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => handleInputChange(e, "quantity")}
          />
        </Grid>
        <Grid item xs>
          <Grid container spacing={0} marginTop={1}>
            <Grid
              item
              xs={4}
              marginTop={"32px"}
              sx={{
                padding: 0,
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.weight_unit}
                  label={"Unit"}
                  onChange={handleOnWeightUnitChange}
                >
                  {PRODUCT_WEIGHT_UNITS.map((v, i) => (
                    <MenuItem key={v} value={v}>
                      {v}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={8} marginTop={2}>
              <InputField
                value={formData.weight}
                type="number"
                // label={"Product Dimension"}
                placeholder="Enter Weight"
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="start"></InputAdornment>
                //   ),
                //   endAdornment: (
                //     <InputAdornment position="start">
                //       cm
                //     </InputAdornment>
                //   ),
                // }}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => handleInputChange(e, "weight")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  const thirdStepComponent = () => (
    <Box className={"StepForm"} width={"60%"}>
      <Grid container marginBottom={2}>
        <Grid item xs>
          <Typography textAlign={"left"} variant="h6" component={"h6"}>
            Share Files, Entity Certificates or other Information
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography textAlign={"right"} variant="body1" component={"h6"}>
            3/4
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <InputFileUpload
            text="Drag or click to upload certificate"
            icon={<BsCardImage />}
            className="FileUpload"
            component="label"
            variant="contained"
            onChange={fileHandler}
          />
          {!selectedFile &&
            isUpdateMode === true &&
            toBeUpdatedShipment.documents_url &&
            toBeUpdatedShipment.documents_url.length > 0 && (
              <Box textAlign={"left"}>
                {getOriginalFileName(toBeUpdatedShipment.documents_url[0])}
              </Box>
            )}
          {selectedFile && <Box textAlign={"left"}>{selectedFile.name}</Box>}
          <Typography
            textAlign={"left"}
            variant="body2"
            component={"p"}
            sx={{
              marginBottom: "10px",
              textAlign: "center",
              color: "#a0a0a0",
            }}
          >
            Document should be jpeg or pdf
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs>
          <InputField
            value={formData.labels}
            type="text"
            label={"Label"}
            placeholder="Add Label"
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => handleInputChange(e, "labels")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs>
          <InputField
            value={formData.further_information}
            type="text"
            label={"Add Further Information"}
            placeholder="Add Information"
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => handleInputChange(e, "further_information")}
          />
        </Grid>
      </Grid>
    </Box>
  );

  const fourthStepComponent = () => (
    <Box>
      <Box className={"StepForm"} width={"60%"}>
        <Grid container marginBottom={2}>
          <Grid item xs>
            <Typography textAlign={"left"} variant="h6" component={"h6"}>
              Please Preview the Details Below
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography textAlign={"right"} variant="body1" component={"h6"}>
              4/4
            </Typography>
          </Grid>
        </Grid>
        <Grid container marginBottom={1}>
          <Grid item xs>
            <Typography
              textAlign={"left"}
              variant="body2"
              component={"p"}
              paddingBottom={3}
              sx={{
                borderBottom: "1px solid #dcdcdc",
              }}
            >
              If all the information is correct, kindly finalize the completion
              of your shipment. If you want to make changes, kindly click back
              to do so.
            </Typography>
          </Grid>
        </Grid>
        <Box className={"ProductInfo"}>
          <Typography
            fontWeight={600}
            fontSize={"18px"}
            textAlign={"left"}
            variant="h1"
            component={"h1"}
            marginBottom={2}
          >
            Product Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <img
                src={product?.thumbnails_url[0]}
                alt=""
                className="img-fluid"
                style={{
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Box className={"InfoText"}>
                <Grid container spacing={1}>
                  <Grid item md={6} xs={12} marginTop={"10px"}>
                    <Typography
                      fontSize={"12px"}
                      textAlign={"left"}
                      variant="body2"
                      component={"p"}
                    >
                      PRODUCT
                    </Typography>
                    <Typography
                      textAlign={"left"}
                      fontSize={"18px"}
                      variant="h6"
                      component={"h6"}
                    >
                      {product?.name}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12} marginTop={"10px"}>
                    <Typography
                      fontSize={"12px"}
                      textAlign={"left"}
                      variant="body2"
                      component={"p"}
                    >
                      OWNER
                    </Typography>
                    <Typography
                      textAlign={"left"}
                      fontSize={"18px"}
                      variant="h6"
                      component={"h6"}
                    >
                      {authData.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item md={6} xs={12} marginTop={"20px"}>
                    <Typography
                      fontSize={"12px"}
                      textAlign={"left"}
                      variant="body2"
                      component={"p"}
                    >
                      QUANTITY
                    </Typography>
                    <Typography
                      textAlign={"left"}
                      fontSize={"18px"}
                      variant="h6"
                      component={"h6"}
                    >
                      {formData.quantity} {product?.quantity_unit}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12} marginTop={"20px"}>
                    <Typography
                      fontSize={"12px"}
                      textAlign={"left"}
                      variant="body2"
                      component={"p"}
                    >
                      WEIGHT
                    </Typography>
                    <Typography
                      textAlign={"left"}
                      fontSize={"18px"}
                      variant="h6"
                      component={"h6"}
                    >
                      {formData.weight}
                      {formData.weight_unit}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item md={6} xs={12} marginTop={"20px"}>
                    <Typography
                      fontSize={"12px"}
                      textAlign={"left"}
                      variant="body2"
                      component={"p"}
                    >
                      ARRIVAL DATE
                    </Typography>
                    <Typography
                      textAlign={"left"}
                      fontSize={"18px"}
                      variant="h6"
                      component={"h6"}
                    >
                      {formData.expected_arrival_date}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12} marginTop={"20px"}>
                    <Typography
                      fontSize={"12px"}
                      textAlign={"left"}
                      variant="body2"
                      component={"p"}
                    >
                      TIME
                    </Typography>
                    <Typography
                      textAlign={"left"}
                      fontSize={"18px"}
                      variant="h6"
                      component={"h6"}
                    >
                      {formData.expected_arrival_time}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={"ProductDis"}>
          <Typography
            fontWeight={600}
            fontSize={"18px"}
            textAlign={"left"}
            variant="h1"
            component={"h1"}
            marginBottom={2}
          >
            Seller and Buyer Information
          </Typography>
          <Box className={"InfoText"}>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12} marginTop={"10px"}>
                <Typography
                  fontSize={"12px"}
                  textAlign={"left"}
                  variant="body2"
                  component={"p"}
                >
                  SOURCE
                </Typography>
                <Typography
                  textAlign={"left"}
                  fontSize={"18px"}
                  variant="h6"
                  component={"h6"}
                >
                  {authData.name}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} marginTop={"10px"}>
                <Typography
                  fontSize={"12px"}
                  textAlign={"left"}
                  variant="body2"
                  component={"p"}
                >
                  DESTINATION
                </Typography>
                <Typography
                  textAlign={"left"}
                  fontSize={"18px"}
                  variant="h6"
                  component={"h6"}
                >
                  {formData.recipient_company_name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item md={6} xs={12} marginTop={"20px"}>
                <Typography
                  fontSize={"12px"}
                  textAlign={"left"}
                  variant="body2"
                  component={"p"}
                >
                  ADDRESS
                </Typography>
                <Typography
                  textAlign={"left"}
                  fontSize={"18px"}
                  variant="h6"
                  component={"h6"}
                >
                  {product?.origin_address}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} marginTop={"20px"}>
                <Typography
                  fontSize={"12px"}
                  textAlign={"left"}
                  variant="body2"
                  component={"p"}
                >
                  ADDRESS
                </Typography>
                <Typography
                  textAlign={"left"}
                  fontSize={"18px"}
                  variant="h6"
                  component={"h6"}
                >
                  {formData.recipient_address}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={"ProductDis"}>
          <Typography
            fontWeight={600}
            fontSize={"18px"}
            textAlign={"left"}
            variant="h1"
            component={"h1"}
            marginBottom={2}
          >
            Uploaded Documents
          </Typography>
          <Box className={"InfoDoc"}>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12} marginTop={"10px"}>
                <Box className={"UploadPdf"}>
                  <img src="./Assets/Image/pdf.png" alt="" className="pdnImg" />
                  <Typography
                    textAlign={"left"}
                    fontSize={"18px"}
                    variant="h6"
                    component={"h6"}
                  >
                    {isUpdateMode !== true && selectedFile.name}
                    {isUpdateMode === true &&
                      !selectedFile &&
                      getOriginalFileName(toBeUpdatedShipment.documents_url[0])}
                    {isUpdateMode === true && selectedFile && selectedFile.name}
                  </Typography>
                </Box>
              </Grid>
              {/* <Grid item md={6} xs={12} marginTop={"10px"}>
                <Typography
                  textAlign={"right"}
                  fontSize={"18px"}
                  variant="h6"
                  component={"h6"}
                >
                  View
                </Typography>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box className={"Checkbox"}>
        <FormControl>
          <FormControlLabel
            checked={isTCAccepted}
            control={<Checkbox />}
            label="I Agree to Plychain's Terms of Use and Privacy Policy and that the information can be shared and published in accordance with the assigned category of publication"
            onChange={onTCCheckChangeHandler}
          />
        </FormControl>
      </Box>
    </Box>
  );

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return firstStepComponent();
      case 1:
        return secondStepComponent();
      case 2:
        return thirdStepComponent();
      case 3:
        return fourthStepComponent();
      default:
        return <></>;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Snackbar
        open={snackBarState.show}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarState.severity}
          sx={{ width: "100%" }}
        >
          {snackBarState.msg}
        </Alert>
      </Snackbar>
      {renderStepContent()}
      {activeStep < 4 ? (
        <React.Fragment>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            {publishLoading && <CircularProgress />}
            {!publishLoading && (
              <Button
                variant="contained"
                onClick={activeStep === 3 ? handlePublishShipment : handleNext}
                className="Primary-button"
              >
                {activeStep === 3 ? "Publish Shipment" : "Proceed"}
              </Button>
            )}
          </Box>
        </React.Fragment>
      ) : null}
    </Box>
  );
});

export default ShipmentStepForm;
