import React from "react";
import TextField, {
  TextFieldProps,
  TextFieldVariants,
} from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { SvgIconProps } from "@mui/material/SvgIcon"; // Import the props for SVG icons
import { Box } from "@mui/material";

type InputAdornmentProps = {
  position: "start" | "end";
};

type InputFieldProps = TextFieldProps & {
  variant?: TextFieldVariants;
  placeholder?: string;
  autoComplete?: string;
  name?: string;
  label?: string;
  textfieldId?: string;
  onClick?: () => void;
  startAdornmentProps?: InputAdornmentProps; // Props for the start adornment
  icon?: React.ReactElement<SvgIconProps>; // Icon as a React element
};

export const InputField: React.FC<InputFieldProps> = ({
  variant,
  placeholder,
  autoComplete,
  name,
  label,
  textfieldId,
  onClick,
  startAdornmentProps,
  icon,
  ...otherProps
}) => {
  return (
    <>
      <Box className={"TextField"}>
        <label className="TextLabel">{label}</label>
        <TextField
          margin="normal"
          required
          fullWidth
          id={textfieldId}
          // label=
          name={name}
          autoComplete={autoComplete}
          placeholder={placeholder}
          autoFocus
          variant={variant}
          onClick={onClick}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position={startAdornmentProps?.position || "start"}
              >
                {icon} {/* Render the icon component passed as a prop */}
              </InputAdornment>
            ),
          }}
          {...otherProps} // Pass any additional props
        />
      </Box>
    </>
  );
};
