import React, { useEffect, useState } from "react";
import {
  CssBaseline,
  Box,
  Typography,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  AlertColor,
  Snackbar,
  Alert,
  CircularProgress,
  Grid,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { InputField } from "../../SharedComponent/TextField/InputField";
import InputFileUpload from "../../SharedComponent/TextField/InputFileUpload";
import CheckboxLabels from "../../SharedComponent/CheckBox/Checkbox";
import SharedButton from "../../SharedComponent/Button/SharedButton";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { LuMail } from "react-icons/lu";
import { LiaUserTieSolid } from "react-icons/lia";
import MobileNumberInput from "../../SharedComponent/TextField/MobileNumberInput";
import { signup } from "../../networking/networkCalls";
import { CountryData } from "react-phone-input-2";
import { authActions } from "../../store/auth";
import { AuthModel } from "../../models/authModel";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsCardImage } from "react-icons/bs";
import { BUSINESS_CATEGORIES } from "../../constants/constants";
import { reloadWebsite } from "../../utils/utils";

const defaultTheme = createTheme();

export const SignUp = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [selectedBusinessCategory, setBusinessCategory] = useState<string>("");
  const [businessCategories, setBusinessCategories] =
    useState<string[]>(BUSINESS_CATEGORIES);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedDocFile, setSelectedDocFile] = useState<File | undefined>(
    undefined
  );
  const [selectedLogoFile, setSelectedLogoFile] = useState<File | undefined>(
    undefined
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [snackBarState, setSnackBarState] = useState<{
    show: boolean;
    msg: string;
    severity: AlertColor;
  }>({
    show: false,
    msg: "",
    severity: "success",
  });

  const handleIsAgree = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAgree(!isAgree);
  };

  const handleBusinessCategoryChange = (newValue: string) => {
    setBusinessCategory(newValue);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarState({ ...snackBarState, show: false });
  };

  const handlePhoneChange = (value: string, country: {} | CountryData) => {
    // `value` will contain the formatted phone number
    // `country` will contain the selected country data
    setPhoneNumber(value);
  };

  const docFileHandler = (e: React.SyntheticEvent<HTMLInputElement, Event>) => {
    const target = e.target as HTMLInputElement;
    const selectedDocFile: File = (target.files as FileList)[0];
    setSelectedDocFile(selectedDocFile);
  };

  const logoFileHandler = (
    e: React.SyntheticEvent<HTMLInputElement, Event>
  ) => {
    const target = e.target as HTMLInputElement;
    const selectedLogoFile: File = (target.files as FileList)[0];
    setSelectedLogoFile(selectedLogoFile);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (selectedBusinessCategory.trim() === "") {
      setSnackBarState({
        show: true,
        msg: "Please Select Business Category",
        severity: "error",
      });
      return;
    }

    // Form validation
    const formData: FormData = new FormData(event.currentTarget);

    if (
      !formData.get("business_name") ||
      !formData.get("business_email") ||
      !formData.get("business_country") ||
      !formData.get("business_address") ||
      (selectedBusinessCategory === "Other" &&
        !formData.get("business_category")) ||
      phoneNumber === ""
    ) {
      setSnackBarState({
        show: true,
        msg: "Please fill in all the required fields",
        severity: "error",
      });
      return;
    }

    if (!selectedDocFile) {
      setSnackBarState({
        show: true,
        msg: "Please select a document of incorporation",
        severity: "error",
      });
      return;
    }

    if (!selectedLogoFile) {
      setSnackBarState({
        show: true,
        msg: "Please select a business logo",
        severity: "error",
      });
      return;
    }

    if (!isAgree) {
      setSnackBarState({
        show: true,
        msg: "Please agree to terms & condition and privacy policy",
        severity: "error",
      });
      return;
    }

    if (!loading) {
      setLoading(true);
    }

    const res = await signup({
      name: formData.get("business_name").toString(),
      email: formData.get("business_email").toString(),
      country: formData.get("business_country").toString(),
      address: formData.get("business_address").toString(),
      phone_number: phoneNumber,
      business_category:
        selectedBusinessCategory === "Other"
          ? formData.get("business_category").toString()
          : selectedBusinessCategory,
      document_of_incorporation: selectedDocFile,
      business_logo: selectedLogoFile,
    });

    setLoading(false);

    if (res && res.status === 201) {
      const { data } = res.data;
      setSnackBarState({
        show: true,
        msg: "Business details have been submitted",
        severity: "success",
      });

      // save login state and data in local storage
      localStorage.setItem("login", "1");
      localStorage.setItem("authData", JSON.stringify(data));

      // save login state and data in redux store
      dispatch(authActions.setLoginState(true));
      const authData: AuthModel = data;
      dispatch(authActions.setAuthData(authData));

      setTimeout(() => {
        setLoading(false);
        navigate("/dashboard", { replace: true });
      }, 1000);
    } else {
      const { data } = res;
      setSnackBarState({
        show: true,
        msg: data.msg,
        severity: "error",
      });
    }
  };

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            // border: "1px solid red",
            // width: "600px",
            maxWidth: "600px !important",
          }}
        >
          <CssBaseline />
          <Snackbar
            open={snackBarState.show}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={handleClose}
              severity={snackBarState.severity}
              sx={{ width: "100%" }}
            >
              {snackBarState.msg}
            </Alert>
          </Snackbar>
          <img
            src="/Assets/Image/MainBackground.png"
            className="MainBackgroundImage"
            alt="Example"
            style={
              {
                // border: "1px solid green",
              }
            }
          />
          <Box
            sx={{
              // width: {
              //   xs: "90px",
              //   sm: "130px",
              // },
              marginX: "auto",
              marginTop: 10,
              marginBottom: "50px",
              // border: "1px solid blue",
            }}
          >
            <img
              src="./Assets/Image/MainLogo.png"
              alt=""
              className="footerLogo"
              onClick={(e) => reloadWebsite()}
            />
          </Box>
          <Box
            sx={{
              // marginTop: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // border: "1px solid blue",
            }}
          >
            <Typography
              component="h1"
              variant="h4"
              // color="#0b1e5b"
              sx={{
                // fontFamily: "nexa",
                fontWeight: "600",
                // border: "1px solid yellow",
                fontSize: { xs: "30px", md: "48px" },
              }}
            >
              Create an Account
            </Typography>
            <Typography
              component="p"
              color="#757575"
              sx={{
                fontSize: "12px",
              }}
            >
              Create your account to get started
            </Typography>
            <Box
              className="AccountBox"
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{
                mt: 1,
                width: {
                  xs: "100%",
                  sm: "400px",
                  md: "400px",
                  lg: "400px",
                },
                // border: "1px solid green",
              }}
            >
              <InputField
                type="text"
                variant="outlined"
                textfieldId="outlined-basic"
                name="business_name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LiaUserTieSolid />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: "8px",
                    padding: "4px 12px",
                    backgroundColor: "#fff",
                    color: "#767676",
                    fontSize: "14px",
                  },
                }}
                placeholder="Enter Business Name"
              />
              {/* <SelectVariants
                labelId="BusinessCategories"
                SelectId="Categories"
                value={selectedBusinessCategory}
                label="Enter Business Category"
                onChange={handleBusinessCategoryChange}
              /> */}
              <FormControl
                fullWidth
                margin="normal"
                sx={{
                  background: "#fff",
                }}
              >
                <InputLabel
                  id="BusinessCategories"
                  sx={{
                    width: "100%",
                    marginTop: 0,

                    fontSize: "14px",
                    textAlign: "left",
                    color: "#9E9E9E",
                  }}
                >
                  Enter Business Category
                </InputLabel>
                <Select
                  labelId="BusinessCategories"
                  id="Categories"
                  value={selectedBusinessCategory}
                  label="Enter Business Category"
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    color: "#767676",
                    fontSize: "14px",
                    textAlign: "left",
                  }}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LiaUserTieSolid />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event: SelectChangeEvent) =>
                    handleBusinessCategoryChange(event.target.value as string)
                  }
                >
                  {businessCategories.map((d) => (
                    <MenuItem key={d} value={d}>
                      {d}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {selectedBusinessCategory === "Other" && (
                <InputField
                  type="text"
                  variant="outlined"
                  textfieldId="outlined-basic"
                  name="business_category"
                  icon={<LuMail />}
                  placeholder="Enter Business Category"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LuMail />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "8px",
                      padding: "4px 12px",
                      backgroundColor: "#fff",
                      color: "#767676",
                      fontSize: "14px",
                    },
                  }}
                />
              )}

              <InputFileUpload
                text="Drag or click to upload certificate of incorporation"
                icon={<CloudUploadIcon />}
                className="FileUpload"
                component="label"
                variant="contained"
                onChange={docFileHandler}
              />
              {selectedDocFile && (
                <Box textAlign={"left"}>{selectedDocFile.name}</Box>
              )}
              <InputField
                type="email"
                variant="outlined"
                textfieldId="outlined-basic"
                name="business_email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LuMail />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: "8px",
                    padding: "4px 12px",
                    backgroundColor: "#fff",
                    color: "#767676",
                    fontSize: "14px",
                  },
                }}
                placeholder="Enter Business Email"
              />
              <MobileNumberInput
                value={phoneNumber}
                onChange={handlePhoneChange}
              />
              <InputField
                type="country"
                variant="outlined"
                textfieldId="outlined-basic"
                name="business_country"
                icon={<HiOutlineLocationMarker />}
                placeholder="Enter Business Country"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <HiOutlineLocationMarker />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: "8px",
                    padding: "4px 12px",
                    backgroundColor: "#fff",
                    color: "#767676",
                    fontSize: "14px",
                  },
                }}
              />

              <InputField
                type="address"
                variant="outlined"
                textfieldId="outlined-basic"
                name="business_address"
                icon={<HiOutlineLocationMarker />}
                placeholder="Enter Business Address"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <HiOutlineLocationMarker />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: "8px",
                    padding: "4px 12px",
                    backgroundColor: "#fff",
                    color: "#767676",
                    fontSize: "14px",
                  },
                }}
              />

              <Grid container spacing={2} marginTop={0}>
                <Grid item xs={12}>
                  <Typography
                    marginLeft={"10px"}
                    textAlign={"left"}
                    variant="h6"
                    component={"h4"}
                    fontWeight={600}
                  >
                    Business Logo
                  </Typography>
                  <Typography
                    marginLeft={"10px"}
                    textAlign={"left"}
                    variant="body2"
                    component={"h4"}
                    className="greyColor"
                  >
                    Upload logo following this format PNG, JPG (max. 800x800px)
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {!selectedLogoFile && (
                    <InputFileUpload
                      text="Upload image click here or Drag & Drop"
                      icon={<BsCardImage />}
                      className="FileUpload"
                      component="label"
                      variant="contained"
                      accept="image/*"
                      onChange={logoFileHandler}
                    />
                  )}
                  {selectedLogoFile && (
                    <Box className="ProductImg">
                      {selectedLogoFile && (
                        <AiFillCloseCircle
                          className="closeIcon"
                          onClick={() => setSelectedLogoFile(undefined)}
                        />
                      )}
                      <img
                        id="1"
                        src={
                          selectedLogoFile
                            ? URL.createObjectURL(selectedLogoFile)
                            : ""
                        }
                        alt=""
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>

              <FormGroup
                sx={{
                  // border: "1px solid yellow",

                  textAlign: "left",
                }}
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={isAgree}
                      onChange={handleIsAgree}
                      sx={{
                        color: "#0b1e5b",
                        "&.Mui-checked": {
                          color: "#0b1e5b ",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      component="p"
                      sx={{
                        margin: "18px 0",
                        color: "#767676",
                        // display: "flex",
                        fontWeight: "400",
                        alignItems: "center",
                        // fontSize: "14px",
                        textAlign: "left",
                        fontSize: {
                          xs: "11.5px",
                          lg: "14px",
                        },
                        // gap: 2,
                      }}
                    >
                      I Agree to the
                      <Typography
                        component="a"
                        href="/#/terms-and-conditions"
                        sx={{
                          color: "#9F8BF0",
                          textDecoration: "none",
                          marginLeft: 1,
                          marginRight: 1,
                          fontSize: {
                            xs: "11.5px",
                            lg: "14px",
                          },
                        }}
                      >
                        Terms & Conditions
                      </Typography>
                      and
                      <Typography
                        component="a"
                        href="/#/privacy-policy"
                        sx={{
                          color: "#9F8BF0",
                          textDecoration: "none",
                          marginLeft: 1,
                          fontSize: {
                            xs: "11.5px",
                            lg: "14px",
                          },
                        }}
                      >
                        Privacy Policy
                      </Typography>
                    </Typography>
                  }
                />
              </FormGroup>
              {/* <CheckboxLabels
                checked={isAgree}
                label="I Agree to the Term & Conditions & Privacy Policy"
                onChange={handleIsAgree}
              /> */}
              {!loading && (
                <SharedButton
                  value={"Submit"}
                  type="submit"
                  variant="contained"
                  size="large"
                  fullWidth
                  className="ConnectWalletBTN"
                  sx={{
                    fontFamily: "Inter, sans-serif",
                    textTransform: "capitalize",
                  }}
                />
              )}
              {loading && <CircularProgress />}
              <Box marginY={5}></Box>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
};
