import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ResponsiveDrawer from "../../SharedComponent/Sidebar/ResponsiveDrawer";
import Header from "../../SharedComponent/Header/Header";

import EnhancedTable from "../../SharedComponent/DataTable/Table";
import { DashboardOverview } from "../DashboardPages/DashboardOverview";
import { ProductDetailOverview } from "./ProductDetailOverview";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { getProduct } from "../../networking/networkCalls";
import ProductModel from "../../models/productModel";
import { CircularProgress } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const ProductDetail = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<ProductModel>(undefined);

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  React.useEffect(() => {
    if (!loading) {
      setLoading(true);
    }

    const getData = async () => {
      const res = await getProduct({ id: parseInt(id) });

      if (res) {
        if (res.status === 200) {
          const { data } = res.data;
          setData(new ProductModel(data));
        }
      }
    };

    getData();

    setLoading(false);
  }, []);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <ResponsiveDrawer />
          </Grid>
          <Grid item xs={10}>
            <Header />
            {loading && <CircularProgress />}
            {!loading && data && <ProductDetailOverview data={data} />}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
