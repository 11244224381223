import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ResponsiveDrawer from "../../SharedComponent/Sidebar/ResponsiveDrawer";
import Header from "../../SharedComponent/Header/Header";
import {
  Alert,
  AlertColor,
  Button,
  IconButton,
  LinearProgress,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { GoArrowLeft } from "react-icons/go";
import { BiEditAlt } from "react-icons/bi";
import { LuDownload } from "react-icons/lu";
import SharedButton from "../../SharedComponent/Button/SharedButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ShipmentCheckPoint from "./ShipmentCheckPoint";
import MyDatePicker from "../../SharedComponent/DatePicker/MyDatePicker";
import { ShipmentModel } from "../../models/shipmentModel";
import { useState } from "react";
import { AuthModel } from "../../models/authModel";
import { useSelector } from "react-redux";
import { getOriginalFileName } from "../../utils/utils";
import { getShipment } from "../../networking/networkCalls";
import CheckpointModel from "../../models/checkpointModel";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const handleGoBack = () => {
  window.history.back();
};

export const ShipmentDetailPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<ShipmentModel>(undefined);
  const [checkpointData, setCheckpointData] = useState<CheckpointModel[]>([]);

  const location = useLocation();
  const navigate = useNavigate();

  const [snackBarState, setSnackBarState] = useState<{
    show: boolean;
    msg: string;
    severity: AlertColor;
  }>({
    show: false,
    msg: "",
    severity: "success",
  });

  // @ts-ignore
  const authData: AuthModel = useSelector((state) => state.auth.authData);

  React.useEffect(() => {
    if (!loading) {
      setLoading(true);
    }

    const getData = async () => {
      const res = await getShipment({ id: location.state });

      if (res) {
        if (res.status === 200) {
          const { data } = res.data;

          const shipment: ShipmentModel = new ShipmentModel(data);

          setData(shipment);

          if (shipment.checkpoints.length > 0) {
            setCheckpointData(shipment.checkpoints);
          }
        }
      }
    };

    getData();

    setLoading(false);
  }, [location.state]);

  const checkpointCallback = (
    value: CheckpointModel | undefined,
    alertData: {
      show: boolean;
      msg: string;
      severity: AlertColor;
    }
  ) => {
    if (value) {
      const data = [...checkpointData];

      data.push(value);
      setCheckpointData(data);
    }

    setSnackBarState(alertData);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarState({ ...snackBarState, show: false });
  };

  const handleDownloadDoc = (e) => {
    if (data.documents_url && data.documents_url.length > 0) {
      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = data.documents_url[0];

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event on the link
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <ResponsiveDrawer />
          </Grid>
          <Snackbar
            open={snackBarState.show}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              onClose={handleClose}
              severity={snackBarState.severity}
              sx={{ width: "100%" }}
            >
              {snackBarState.msg}
            </Alert>
          </Snackbar>
          {loading && <LinearProgress />}
          {!loading && data && (
            <Grid item xs={10}>
              <Header />
              <Box className={"MainBoxComponent"}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs marginTop={"5px"}>
                      <Typography
                        textAlign={"left"}
                        variant="h5"
                        component={"h4"}
                      >
                        <IconButton onClick={() => handleGoBack()}>
                          <GoArrowLeft />
                        </IconButton>
                        PLC/DE908KO12
                      </Typography>
                    </Grid>

                    <Grid item xs marginTop={"5px"} textAlign={"right"}>
                      <ShipmentCheckPoint
                        checkpointCallback={checkpointCallback}
                        id={data.id}
                      />
                    </Grid>
                    <Grid item xs={1} marginTop={"5px"} textAlign={"left"}>
                      <Tooltip title="Edit" arrow>
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            navigate("/updateShipment", {
                              state: {
                                mode: "UPDATE",
                                data: JSON.stringify(data),
                              },
                            });
                          }}
                        >
                          <BiEditAlt />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip title="Download" arrow>
                        <IconButton aria-label="Download">
                          <LuDownload />
                        </IconButton>
                      </Tooltip> */}
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Box className={"StepForm"} width={"60%"} padding={2}>
                    <Box className={"ProductInfo"}>
                      <Typography
                        fontWeight={600}
                        fontSize={"18px"}
                        textAlign={"left"}
                        variant="h1"
                        component={"h1"}
                        marginBottom={2}
                      >
                        Product Information
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <img
                            src={data.Product?.thumbnails_url[0]}
                            alt=""
                            className="img-fluid"
                            style={{
                              width: "100%",
                            }}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Box className={"InfoText"}>
                            <Grid container spacing={1}>
                              <Grid item md={6} xs={12} marginTop={"10px"}>
                                <Typography
                                  fontSize={"12px"}
                                  textAlign={"left"}
                                  variant="body2"
                                  component={"p"}
                                >
                                  PRODUCT
                                </Typography>
                                <div className="textContent">
                                <Typography
                                  textAlign={"left"}
                                  fontSize={"18px"}
                                  variant="h6"
                                  component={"h6"}
                                >
                                  {data.Product?.name}
                                </Typography>
                                </div>
                              </Grid>
                              <Grid item md={6} xs={12} marginTop={"10px"}>
                                <Typography
                                  fontSize={"12px"}
                                  textAlign={"left"}
                                  variant="body2"
                                  component={"p"}
                                >
                                  OWNER
                                </Typography>
                                <div className="textContent">
                                <Typography
                                  textAlign={"left"}
                                  fontSize={"18px"}
                                  variant="h6"
                                  component={"h6"}
                                >
                                  {authData.name}
                                </Typography>
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container spacing={0}>
                              <Grid item md={6} xs={12} marginTop={"20px"}>
                                <Typography
                                  fontSize={"12px"}
                                  textAlign={"left"}
                                  variant="body2"
                                  component={"p"}
                                >
                                  QUANTITY
                                </Typography>
                                <Typography
                                  textAlign={"left"}
                                  fontSize={"18px"}
                                  variant="h6"
                                  component={"h6"}
                                >
                                  {data.quantity} {data.quantity_unit}
                                </Typography>
                              </Grid>
                              <Grid item md={6} xs={12} marginTop={"20px"}>
                                <Typography
                                  fontSize={"12px"}
                                  textAlign={"left"}
                                  variant="body2"
                                  component={"p"}
                                >
                                  WEIGHT
                                </Typography>
                                <Typography
                                  textAlign={"left"}
                                  fontSize={"18px"}
                                  variant="h6"
                                  component={"h6"}
                                >
                                  {data.weight} {data.weight_unit}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container spacing={0}>
                              <Grid item md={6} xs={12} marginTop={"20px"}>
                                <Typography
                                  fontSize={"12px"}
                                  textAlign={"left"}
                                  variant="body2"
                                  component={"p"}
                                >
                                  ARRIVAL DATE
                                </Typography>
                                <Typography
                                  textAlign={"left"}
                                  fontSize={"18px"}
                                  variant="h6"
                                  component={"h6"}
                                >
                                  {data.expected_arrival_date}
                                </Typography>
                              </Grid>
                              <Grid item md={6} xs={12} marginTop={"20px"}>
                                <Typography
                                  fontSize={"12px"}
                                  textAlign={"left"}
                                  variant="body2"
                                  component={"p"}
                                >
                                  TIME
                                </Typography>
                                <Typography
                                  textAlign={"left"}
                                  fontSize={"18px"}
                                  variant="h6"
                                  component={"h6"}
                                >
                                  {data.expected_arrival_time}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className={"ProductDis"}>
                      <Typography
                        fontWeight={600}
                        fontSize={"18px"}
                        textAlign={"left"}
                        variant="h1"
                        component={"h1"}
                        marginBottom={2}
                      >
                        Description
                      </Typography>
                      <Box className={"InfoText"}>
                        <Grid container spacing={1}>
                          <Grid item md={12} xs={12} marginTop={"10px"}>
                          <div className="textContent">
                            <Typography
                              textAlign={"left"}
                              fontSize={"16px"}
                              variant="body2"
                              component={"h6"}
                            >
                              {data.Product?.description}
                            </Typography>
                          </div>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                    <Box className={"ProductInfo"}>
                      <Grid container spacing={1}>
                        <Grid item md={6} xs={12} marginTop={"10px"}>
                          <Typography
                            fontWeight={600}
                            fontSize={"18px"}
                            textAlign={"left"}
                            variant="h1"
                            component={"h1"}
                            marginBottom={2}
                          >
                            QR Code
                          </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} marginTop={"10px"}>
                          <Link to={data.qr_code_url}>
                            <Typography
                              fontSize={"12px"}
                              textAlign={"right"}
                              variant="body2"
                              component={"p"}
                            >
                              Download QR Code
                            </Typography>
                          </Link>
                        </Grid>
                      </Grid>

                      <Box className={"ShipmenttInfo"}>
                        <Grid container spacing={3}>
                          <Grid item xs={4}>
                            <img
                              src={data.qr_code_url}
                              alt=""
                              className="img-fluid"
                              style={{
                                width: "100%",
                              }}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <Box className={"InfoText"}>
                              <Grid container spacing={2}>
                                <Grid item md={6} xs={12} marginTop={"10px"}>
                                  <Typography
                                    fontSize={"12px"}
                                    textAlign={"left"}
                                    variant="body2"
                                    component={"p"}
                                  >
                                    BLOCKCHAIN CERTIFICATE
                                  </Typography>
                                  <div className="textContent">
                                  <Typography
                                    textAlign={"left"}
                                    fontSize={"18px"}
                                    variant="h6"
                                    component={"h6"}
                                    
                                  >
                                    {data.generated_shipment_id}
                                  </Typography>
                                  </div>
                                </Grid>
                                <Grid item md={6} xs={12} marginTop={"10px"}>
                                  <Typography
                                    fontSize={"12px"}
                                    textAlign={"left"}
                                    variant="body2"
                                    component={"p"}
                                  >
                                    PRODUCT STATUS
                                  </Typography>
                                  <div className="textContent">
                                  <Typography
                                    textAlign={"left"}
                                    fontSize={"18px"}
                                    variant="h6"
                                    component={"h6"}
                                    
                                  >
                                    {data.Product?.condition.toUpperCase()}
                                  </Typography>
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2}>
                                <Grid item md={6} xs={12} marginTop={"20px"}>
                                  <Typography
                                    fontSize={"12px"}
                                    textAlign={"left"}
                                    variant="body2"
                                    component={"p"}
                                  >
                                    PRODUCED IN
                                  </Typography>
                                  <div className="textContent">
                                  <Typography
                                    textAlign={"left"}
                                    fontSize={"18px"}
                                    variant="h6"
                                    component={"h6"}
                                    
                                  >
                                    {data.Product.origin_country}
                                  </Typography>
                                  </div>
                                </Grid>
                                <Grid item md={6} xs={12} marginTop={"20px"}>
                                  <Typography
                                    fontSize={"12px"}
                                    textAlign={"left"}
                                    variant="body2"
                                    component={"p"}
                                  >
                                    PRODUCTION DATE
                                  </Typography>
                                  <div className="textContent">

                                  <Typography
                                    textAlign={"left"}
                                    fontSize={"18px"}
                                    variant="h6"
                                    component={"h6"}
                                  >
                                     {data.Product.getFormattedCreatedDate()}
                                  </Typography>
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2}>
                                <Grid item md={6} xs={12} marginTop={"20px"}>
                                  <Typography
                                    fontSize={"12px"}
                                    textAlign={"left"}
                                    variant="body2"
                                    component={"p"}
                                  >
                                    SOURCE
                                  </Typography>
                                  <div className="textContent">
                                  <Typography
                                    textAlign={"left"}
                                    fontSize={"18px"}
                                    variant="h6"
                                    component={"h6"}                                
                                  >
                                    {authData.name}
                                  </Typography>
                                  </div>
                                </Grid>
                                <Grid item md={6} xs={12} marginTop={"20px"}>
                                  <Typography
                                    fontSize={"12px"}
                                    textAlign={"left"}
                                    variant="body2"
                                    component={"p"}
                                    
                                  >
                                    DESTINATION
                                  </Typography>
                                  <div className="textContent">

                                  <Typography
                                    textAlign={"left"}
                                    fontSize={"18px"}
                                    variant="h6"
                                    component={"h6"}
                                    
                                  >
                                    {data.recipient_company_name}
                                  </Typography>
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2}>
                                <Grid item md={6} xs={12} marginTop={"20px"}>
                                  <Typography
                                    fontSize={"12px"}
                                    textAlign={"left"}
                                    variant="body2"
                                    component={"p"}
                                  >
                                    ADDRESS
                                  </Typography>
                                  <Typography
                                    textAlign={"left"}
                                    fontSize={"18px"}
                                    variant="h6"
                                    component={"h6"}
                                    
                                  >
                                    {data.Product.origin_address}
                                  </Typography>
                                </Grid>
                                <Grid item md={6} xs={12} marginTop={"20px"}>
                                  <Typography
                                    fontSize={"12px"}
                                    textAlign={"left"}
                                    variant="body2"
                                    component={"p"}
                                  >
                                    ADDRESS
                                  </Typography>
                                  <Typography
                                    textAlign={"left"}
                                    fontSize={"18px"}
                                    variant="h6"
                                    component={"h6"}
                                    
                                  >
                                    {data.recipient_address}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2}>
                                <Grid item md={6} xs={12} marginTop={"20px"}>
                                  <Typography
                                    fontSize={"12px"}
                                    textAlign={"left"}
                                    variant="body2"
                                    component={"p"}
                                  >
                                    PRODUCT STORY
                                  </Typography>
                                  <div className="textContent">
                                  <Typography
                                    textAlign={"left"}
                                    fontSize={"18px"}
                                    variant="h6"
                                    component={"h6"}
                                  >
                                    {data.Product.product_story}
                                  </Typography>
                                  </div>
                                </Grid>
                                <Grid item md={6} xs={12} marginTop={"20px"}>
                                  <Typography
                                    fontSize={"12px"}
                                    textAlign={"left"}
                                    variant="body2"
                                    component={"p"}
                                  >
                                    RECYCLE INFORMATION
                                  </Typography>
                                  <div className="textContent">

                                  <Typography
                                    textAlign={"left"}
                                    fontSize={"18px"}
                                    variant="h6"
                                    component={"h6"}
                                    
                                  >
                                    {data.Product.reuse_recycle_info}
                                  </Typography>
                                  </div>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                    {data.documents_url.length > 0 && (
                      <Box className={"ProductDis"}>
                        <Typography
                          fontWeight={600}
                          fontSize={"18px"}
                          textAlign={"left"}
                          variant="h1"
                          component={"h1"}
                          marginBottom={2}
                        >
                          Uploaded Documents
                        </Typography>
                        <Box className={"InfoDoc"}>
                          <Grid container spacing={1}>
                            <Grid item md={6} xs={12} marginTop={"10px"}>
                              <Box className={"UploadPdf"}>
                                <img
                                  src="./Assets/Image/pdf.png"
                                  alt=""
                                  className="pdnImg"
                                />
                                <Typography
                                  textAlign={"left"}
                                  fontSize={"18px"}
                                  variant="h6"
                                  component={"h6"}
                                >
                                  {getOriginalFileName(
                                    data.documents_url.length > 0
                                      ? data.documents_url[0]
                                      : ""
                                  )}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                              marginTop={"10px"}
                              sx={{
                                textAlign: "right",
                              }}
                            >
                              <Button
                                type="button"
                                variant="text"
                                sx={{
                                  fontSize: "18px",
                                  fontWeight: "bold",
                                  color: "#0B1E5B",
                                  textTransform: "capitalize",
                                  borderBottom: "2px solid #0B1E5B",
                                  borderRadius: "0!important",
                                  padding: 0,
                                }}
                                onClick={handleDownloadDoc}
                              >
                                Download
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    )}
                    {checkpointData.length > 0 && (
                      <Box
                        className={"ProductOverview ProductInfo"}
                        marginTop={"0px"}
                      >
                        <Grid container spacing={0}>
                          <Grid item md={8} xs marginTop={"20px"}>
                            <Typography
                              fontWeight={600}
                              textAlign={"left"}
                              variant="h6"
                              component={"h1"}
                            >
                              Product History
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={4}
                            xs
                            marginTop={"20px"}
                            justifyContent={"end"}
                          >
                            <MyDatePicker />
                          </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                          <Grid item xs marginTop={"0px"}>
                            <Box sx={{ maxWidth: 400 }}>
                              {checkpointData.map((step, index) => (
                                <Grid container>
                                  <Grid item md={1} xs>
                                    <img
                                      src="./Assets/Image/SideStep.png"
                                      alt=""
                                    />
                                  </Grid>
                                  <Grid item md={11} xs>
                                    <Paper
                                      className="productHistory"
                                      key={step.id}
                                      elevation={1}
                                      sx={{ p: 1, my: 0 }}
                                    >
                                      <Typography
                                        variant="body2"
                                        component={"h6"}
                                      >
                                        {step.getFormattedDate()}{"//"} {step.time}
                                      </Typography>
                                      <Typography
                                        variant="body1"
                                        component={"p"}
                                        sx={{ my: 1 }}
                                      >
                                        {step.country}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        component={"h6"}
                                      >
                                        {step.address}
                                      </Typography>
                                    </Paper>
                                  </Grid>
                                </Grid>
                              ))}
                            </Box>
                            {/* <Grid container spacing={2}>
                              <Grid item xs marginTop={"50px"}>
                                <SharedButton
                                  className="light-grey01"
                                  title="Details"
                                  value={"Details"}
                                  variant="contained"
                                  color="primary"
                                  sx={{
                                    boxShadow: "0px",
                                  }}
                                />

                                <SharedButton
                                  className="Primary-button"
                                  title="Product Details"
                                  value={"Product Details"}
                                  variant="contained"
                                  color="primary"
                                  sx={{
                                    marginLeft: "20px",
                                    boxShadow: "0px",
                                  }}
                                />
                              </Grid>
                            </Grid> */}
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};
