import React from "react";
import Button, { ButtonProps } from "@mui/material/Button"; // Import Button and its props

interface SharedButtonProps extends ButtonProps {
  // Define additional props specific to your component
  customProp?: string;
}

const SharedButton: React.FC<SharedButtonProps> = ({
  customProp,
  startIcon,
  endIcon,
  color,
  variant,
  size,
  href,
  disabled,
  fullWidth,
  value,
  ...otherProps
}) => {
  return (
    <Button
      variant={variant}
      color={color}
      size={size}
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={() => {}}
      startIcon={startIcon}
      endIcon={endIcon}
      component="button"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      {...otherProps}
      // Spread any additional props
    >
      {value}
    </Button>
  );
};

export default SharedButton;
