import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SharedButton from "../../SharedComponent/Button/SharedButton";
import { BsCloudDownload } from "react-icons/bs";
import MyDatePicker from "../../SharedComponent/DatePicker/MyDatePicker";
import { OverviewPanel } from "../../SharedComponent/Panel/OverviewPanel";
import {
  HiOutlineLocationMarker,
  HiOutlinePresentationChartLine,
} from "react-icons/hi";
import { LuScanLine, LuShip } from "react-icons/lu";
import { getDashboardOverview } from "../../networking/networkCalls";

export const DashboardOverview = () => {
  const [dashboardData, setDashboardData] = useState([
    {
      id: "DashOverViewPanel01",
      icon: <HiOutlinePresentationChartLine />,
      title: "Products",
      value: "0",
      description: "Total number of Products",
    },
    {
      id: "DashOverViewPanel02",
      icon: <LuShip />,
      title: "Shipments",
      value: "0",
      description: "Total number of Shipments",
    },
    {
      id: "DashOverViewPanel03",
      icon: <LuScanLine />,
      title: "Customer Scans",
      value: "0",
      description: "Total number of Customer Scans",
    },
    {
      id: "DashOverViewPanel04",
      icon: <HiOutlineLocationMarker />,
      title: "Shipment Locations",
      value: "0",
      description: "Total number of Shipment Locations",
    },
  ]);

  useEffect(() => {
    const getData = async () => {
      const res = await getDashboardOverview();

      if (res) {
        if (res.status === 200) {
          const { data } = res.data;
          const existingData = [...dashboardData];

          existingData[0].value = `${data.productsCount || 0}`;
          existingData[1].value = `${data.shipmentsCount || 0}`;
          existingData[2].value = `${data.productsScanCount || 0}`;
          existingData[3].value = `${data.shipmentLocationsCount || 0}`;

          setDashboardData(existingData);
        }
      }
    };

    getData();
  }, []);

  return (
    <>
      <Paper elevation={0} className={"MainBoxComponent"}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={0}
            sx={{
              alignItems: "center",
            }}
          >
            <Grid item xs>
              <Typography
                marginLeft={"40px"}
                textAlign={"left"}
                variant="h4"
                component={"h4"}
                sx={{
                  fontSize: "32px",
                  fontWeight: "semibold",
                }}
              >
                Dashboard Overview
              </Typography>
            </Grid>
          </Grid>
          <OverviewPanel data={dashboardData} />
        </Box>
      </Paper>
    </>
  );
};
