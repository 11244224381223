import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  FormGroup,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import { Container, Grid, Typography } from "@mui/material";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import SharedButton from "../../SharedComponent/Button/SharedButton";
import NumberComp from "./NumberComp";

const AboutCard = (props: any) => {
  const { header, words, image, numbers } = props;
  return (
    <Box
      className="card"
      id={`card_${numbers}`}
      sx={
        {
          // border: "1px solid red",
        }
      }
    >
      <Grid
        container
        component={"div"}
        className="card__content "
        sx={{
          boxShadow:
            "0 0.2em 1em rgba(0, 0, 0, 0.1), 0 1em 2em rgba(0, 0, 0, 0.1)",
          justifyContent: "center",
          alignItems: "center",
          // border: "1px solid green",
          maxWidth: "1200px",
          marginX: "auto",
          background: "#F4F1FD",
          // gridAutoFlow: {
          //   xs: "dense",
          //   md: "column",
          // },
          display: { xs: "flex", md: "flex" },
          flexDirection: { xs: "column-reverse", md: "row" },
          gap: {
            xs: "15px",
          },
          borderRadius: {
            xs: "24px",
            md: "51px",
          },
          paddingY: {
            xs: "75px",
            md: "75px",
          },
          paddingX: {
            xs: "5%",
            md: "5%",
          },
        }}
      >
        <Grid
          item
          sm
          sx={
            {
              // border: "1px solid red",
            }
          }
        >
          <Grid
            container
            direction={"column"}
            component={"div"}
            // spacing={2}
            sx={
              {
                // border: "1px solid blue",
              }
            }
          >
            <Grid
              item
              sm
              md={1}
              // spacing={2}
              sx={{
                // border: "1px solid yellow",
                display: {
                  xs: "none",
                  md: "block",
                },
              }}
            >
              <NumberComp number={1} active={numbers === 1 && true} />
              <NumberComp number={2} active={numbers === 2 && true} />
              <NumberComp number={3} active={numbers === 3 && true} />
              <NumberComp number={4} active={numbers === 4 && true} />
              <NumberComp number={5} active={numbers === 5 && true} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={
            {
              // border: "1px solid green",
            }
          }
        >
          <Typography
            variant="h3"
            component={"h2"}
            sx={{
              fontFamily: "Nexa",
              color: "#0B1E5B",
              padding: {
                xs: "0",
              },
              fontSize: {
                xs: "24px",
                sm: "36px",
              },

              // border: "1px solid yellow",
              textAlign: {
                xs: "center",
                md: "left",
              },

              // marginBottom: "6px ",
            }}
          >
            {header}
          </Typography>
          <Typography
            variant="body1"
            component={"p"}
            sx={{
              fontFamily: "Inter",
              color: "#0B1E5B",
              padding: {
                xs: "0",
              },
              fontSize: {
                xs: "16px",
                md: "18px",
              },
              textAlign: {
                xs: "center",
                md: "left",
              },
            }}
          >
            {words}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          // alignItems={"start"}
          sx={{
            // border: "1px solid yellow",
            position: "relative",
            textAlign: { xs: "center", md: "left" },
            width: {
              xs: "100%",
            },
          }}
        >
          <ButtonBase
            sx={{
              width: {
                xs: "100%",
                sm: "70%",
                md: "70%",
              },
              height: {
                xs: "200px",
                sm: "438px",
              },
              // border: "1px solid blue",
            }}
          >
            <img
              src={image}
              alt=""
              // className="sliderimg"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </ButtonBase>
        </Grid>

        <Box
          sx={{
            display: {
              xs: "block",
              md: "none",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Nexa",
              // fontSize: "12px",
              textAlign: "center",
            }}
          >
            Why Plychain
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
};

export default AboutCard;
