import { Box, Grid, Paper, Typography } from "@mui/material";
import SharedButton from "../../SharedComponent/Button/SharedButton";
import { OverviewPanel } from "../../SharedComponent/Panel/OverviewPanel";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { LuShip } from "react-icons/lu";
import { AiOutlinePlus } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getShipmentOverview } from "../../networking/networkCalls";

export const ShipmentOverview = () => {
  const [shipmentData, setShipmentData] = useState([
    {
      id: "OverViewPanel01",
      icon: <LuShip />,
      title: "Shipments",
      value: "0",
      description: "Total number of Shipment Locations",
    },
    {
      id: "OverViewPanel02",
      icon: <LuShip />,
      title: "New Shipments",
      value: "0",
      description: "",
    },
    {
      id: "OverViewPanel03",
      icon: <LuShip />,
      title: "Pending Shipments",
      value: "0",
      description: "",
    },
    {
      id: "OverViewPanel04",
      icon: <HiOutlineLocationMarker />,
      title: "Shipment Locations",
      value: "0",
      description: "Total number of Shipment Locations",
    },
  ]);

  useEffect(() => {
    const getData = async () => {
      const res = await getShipmentOverview();

      if (res) {
        if (res.status === 200) {
          const { data } = res.data;
          const existingData = [...shipmentData];

          existingData[0].value = `${data.shipmentsCount || 0}`;
          existingData[1].value = `${data.publishedShipmentsCount || 0}`;
          existingData[2].value = `${data.draftShipmentsCount || 0}`;
          existingData[3].value = `${data.shipmentLocationsCount || 0}`;

          setShipmentData(existingData);
        }
      }
    };

    getData();
  }, []);

  return (
    <>
      <Paper elevation={0}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0}>
            <Grid item xs={9}>
              <Typography
                marginLeft={"40px"}
                textAlign={"left"}
                variant="h3"
                component={"h4"}
                sx={{
                  fontWeight: "semibold",
                }}
              >
                Shipments
              </Typography>
              <Typography
                marginLeft={"40px"}
                textAlign={"left"}
                variant="body1"
                component={"p"}
                sx={{
                  color: "#757575",
                }}
              >
                Manage & Create Shipments
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                textAlign: "end",
              }}
            >
              <Link to={"/createShipment"}>
                <SharedButton
                  variant="contained"
                  className="Primary-button"
                  value={"Create New Shipment"}
                  startIcon={<AiOutlinePlus />}
                />
              </Link>
            </Grid>
          </Grid>
          <Box>
            <Grid xs>
              <OverviewPanel data={shipmentData} />
            </Grid>
          </Box>
        </Box>
      </Paper>
    </>
  );
};
