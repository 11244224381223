// export const BASE_URL = "http://18.188.15.126/api/v1";
export const BASE_URL = "https://plychain.io/api/v1";
// export const BASE_URL = "http://localhost:4000/api/v1";

export const BUSINESS_CATEGORIES = ["Fashion", "Food", "Other"];

export const PRODUCT_DIMENSION_UNITS = [
  "cm",
  "m",
  "mm",
  "in",
  "ft",
  "yd",
  "km",
];
export const PRODUCT_WEIGHT_UNITS = [
  "g",
  "kg",
  "mg",
  "oz",
  "lb",
  "t",
  "ct",
  "gr",
  "oz t",
];
export const PRODUCT_QUANTITY_UNITS = [
  "Each",
  "Piece",
  "Dozen",
  "Kit",
  "Pack",
  "Set",
  "Pair",
  "Bundle",
  "Meter",
  "Centimeter",
  "Millimeter",
  "Feet",
  "Inch",
  "Yard",
  "Square Meter",
  "Square Feet",
  "Square Inch",
  "Cubic Feet",
  "Cubic Inch",
  "Liter",
  "Milliliter",
  "Gallon",
  "Quart",
  "Pint",
  "Ounce",
  "Pound",
  "Milligram",
  "Microgram",
  "Kilogram",
  "Ton",
  "Carat",
  "Grain",
  "Dram",
  "Fluid Ounce",
  "Hundredweight",
  "Cubic Inch",
  "Cubic Foot",
  "Cubic Centimeter",
  "Cubic Meter",
  "Cubic Decimeter",
  "Gallon",
  "Milliliter",
  "Pint",
  "Quart",
  "Bushel",
  "Smidgen",
  "Dash",
  "Tad",
  "Pinch",
  "Teaspoon",
  "Tablespoon",
  "Drop",
  "Cup",
  "Pint",
  "Quart",
  "Gallon",
  "Fluid Ounce",
  "Barrel",
  "Cubic Yard",
  "Cubic Inch",
  "Fluid Dram",
  "Peck",
  "Bucket",
  "Drum",
  "Hogshead",
  "Pipe",
  "Butt",
  "Puncheon",
  "Teacup",
  "Wineglass",
  "Fifth",
  "Pony",
  "Bottle",
  "Fifth",
  "Magnum",
  "Jeroboam",
  "Rehoboam",
  "Methuselah",
  "Salmanazar",
  "Nebuchadnezzar",
  "Jar",
  "Scoop",
  "Scoop",
  "Spray",
  "Her",
  "Jigger",
  "Measure",
  "Spoon",
  "Thumb",
  "Plate",
  "Scoop",
  "Slicer",
  "Swab",
  "Bag",
  "Tent",
  "Pin",
  "Portion",
  "Pen",
  "Case",
  "Tray",
  "Vacuum Packed",
  "Custom",
  "Floor",
  "Piece",
  "Pack",
  "Pair",
  "Cubic Meter",
  "Sachet",
  "Bale",
  "Count",
  "Ream",
  "Bag",
  "Box",
  "Cake",
  "Can",
  "Dozen",
  "Dram",
  "Gross",
  "Box",
  "Pail",
  "Set",
  "Tube",
  "Bucket",
  "Cup",
  "Lot",
  "Sheet",
  "Bunch",
  "Foot",
  "Piece",
  "Wrap",
  "Card",
  "Roll",
  "Spool",
  "Cassette",
  "Disk",
  "Jar",
  "Sleeve",
  "Container",
  "Pack",
  "Box",
  "Tube",
  "Vial",
  "Barrel",
  "Drum",
  "Package",
  "Kit",
  "Bottle",
  "Device",
  "Piece",
  "Pre-Pack",
  "Section",
  "Tray",
  "Unit",
  "Billet",
  "Box",
  "Container",
  "Reel",
  "Stick",
  "Roll",
  "Coir",
  "Jute",
  "Rope",
  "Set",
  "Length",
  "Pack",
  "Ream",
  "Roll",
  "Sack",
  "Case",
  "Chest",
  "Container",
  "Pack",
  "Packet",
  "Bag",
  "Bale",
  "Case",
  "Dozen",
  "Gram",
  "Gross",
  "Inch",
  "Kilogram",
  "Liter",
  "Meter",
  "Metric Ton",
  "Milligram",
  "Milliliter",
  "Ounce",
  "Packet",
  "Pair",
  "Piece",
  "Pound",
  "Quart",
  "Rod",
  "Roll",
  "Set",
  "Yard",
];

export type statusType = "published" | "draft";

export type productConditionType = "new" | "used" | "";

export enum EntityStatus {
  published,
  draft,
}

export enum TrnxType {
  create_product,
  update_product,
  delete_product,
  create_shipment,
  update_shipment,
  delete_shipment,
  create_checkpoint,
  update_checkpoint,
  delete_checkpoint,
}

export const PlanOneUrl = "https://buy.stripe.com/6oE9Eicjn8cB6CAaEE";
export const PlanTwoUrl = "https://buy.stripe.com/7sIdUy6Z3fF3aSQ3cd";
export const PlanThreeUrl = "https://buy.stripe.com/bIY6s6bfj9gF0ecbIK";

export const fbUrl = "https://www.facebook.com/profile.php?id=61553122020819";
export const liUrl = "https://www.linkedin.com/company/plychain/";
export const igUrl =
  "https://instagram.com/useplychain?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr";
export const twitterUrl = "https://twitter.com/useplychain";
