import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Alert,
  AlertColor,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import ResponsiveAppBar from "../../SharedComponent/AppBar/ResponsiveAppBar";
import Footer from "./Footer";
import { InputField } from "../../SharedComponent/TextField/InputField";
import { LiaUserTieSolid, LiaMailBulkSolid } from "react-icons/lia";
import SharedButton from "../../SharedComponent/Button/SharedButton";
import { SelectChangeEvent } from "@mui/material/Select";
import MobileNumberInput from "../../SharedComponent/TextField/MobileNumberInput";
import { pink } from "@mui/material/colors";
import { moveToTop } from "../../utils/utils";
import { CountryData } from "react-phone-input-2";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const GetInTouch = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [formData, setFormData] = useState<{
    name?: string | null;
    email?: string | null;
    phone_number?: string | null;
    request_type?: string | null;
    comment?: string | null;
  }>({
    name: "",
    email: "",
    phone_number: "",
    request_type: "",
    comment: "",
  });

  const [snackBarState, setSnackBarState] = useState<{
    show: boolean;
    msg: string;
    severity: AlertColor;
  }>({
    show: false,
    msg: "",
    severity: "success",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    const data = { ...formData };

    data[`${field}`] = e.target.value;

    setFormData(data);
  };

  const handlePhoneChange = (value: string, country: {} | CountryData) => {
    // `value` will contain the formatted phone number
    // `country` will contain the selected country data
    const data = { ...formData };
    data.phone_number = value;

    setFormData(data);
  };

  const handleOnChangeRequestType = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const data = { ...formData };
    data.request_type = event.target.value;

    setFormData(data);
  };

  const isFormDataValid: () => boolean = () => {
    for (const key in formData) {
      if (
        formData[key] === null ||
        formData[key] === undefined ||
        `${formData[key]}`.trim() === ""
      ) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    // event.preventDefault();

    // if (!isFormDataValid()) {
    //   setSnackBarState({
    //     show: true,
    //     msg: "Please fill in all the required fields",
    //     severity: "error",
    //   });
    //   return;
    // }

    // if (!loading) {
    //   setLoading(true);
    // }

    // const res = await submitQuery(formData);

    // setLoading(false);

    // if (res && res.status === 201) {
    //   setSnackBarState({
    //     show: true,
    //     msg: "Content category added successfully",
    //     severity: "success",
    //   });
    // } else {
    //   const { data } = res;
    //   setSnackBarState({
    //     show: true,
    //     msg: data.msg,
    //     severity: "error",
    //   });
    // }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarState({ ...snackBarState, show: false });
  };

  return (
    <>
      <ResponsiveAppBar />
      <Snackbar
        open={snackBarState.show}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarState.severity}
          sx={{ width: "100%" }}
        >
          {snackBarState.msg}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          backgroundImage: `url('./Assets/Image/AboutUsBG.png')`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          color: "#000",
          paddingTop: 0,
          paddingRight: 3,
          paddingLeft: 3,
          paddingBottom: 2,
        }}
      >
        <Box
          sx={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="h1"
            variant="h3"
            color="#0b1e5b"
            sx={{
              fontFamily: "nexa",
              width: {
                xs: "100%",
                sm: "45%",
                md: "50%",
                lg: "60%",
                xl: "40%",
              },
              fontSize: {
                xs: "28px",
                md: "36px",
                lg: "48px",
              },
              padding: {
                xs: "0",
              },
            }}
          >
            Get In Touch
          </Typography>
          <Typography
            color="#0b1e5b"
            component="p"
            fontSize="14px"
            sx={{
              width: {
                xs: "100%",
                sm: "70%",
                md: "43%",
                lg: "43%",
                xl: "30%",
              },
              marginBottom: 1,
              marginTop: {
                sm: 0,
                md: 2,
                lg: 2,
              },
            }}
          >
            Feel free to contact us regarding any inquiries related to
            partnerships, press, investments, technical queries. Our team is
            available to assist you!
          </Typography>
          <Box
            sx={{
              mt: 1,
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
              },
            }}
          >
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid
                item
                sx={{
                  mt: 1,
                  width: {
                    xs: "100%",
                    sm: "400px",
                    md: "400px",
                    lg: "400px",
                  },
                }}
              >
                <InputField
                  type="text"
                  variant="outlined"
                  textfieldId="outlined-basic"
                  name="business_name"
                  placeholder="Name"
                  fullWidth
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => handleInputChange(e, "name")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LiaUserTieSolid />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "8px",
                      padding: "4px 12px",
                      backgroundColor: "#fff",
                      color: "#767676",
                      fontSize: "14px",
                    },
                  }}
                />
                <InputField
                  type="text"
                  variant="outlined"
                  textfieldId="outlined-basic"
                  name="business_name"
                  placeholder="Business Email"
                  fullWidth
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => handleInputChange(e, "email")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LiaMailBulkSolid />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "8px",
                      padding: "4px 12px",
                      backgroundColor: "#fff",
                      color: "#767676",
                      fontSize: "14px",
                    },
                  }}
                />
                <Box className={"MobileNumber"}>
                  <MobileNumberInput
                    value={formData.phone_number}
                    onChange={handlePhoneChange}
                  />
                </Box>
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      fontSize: "14px",
                      color: "#767676",
                    }}
                  >
                    Request Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData.request_type}
                    label="Request Type"
                    onChange={handleOnChangeRequestType}
                    sx={{
                      borderRadius: "8px",
                      backgroundColor: "#fff",
                      color: "#767676",
                      fontSize: "14px",
                      textAlign: "left",
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LiaUserTieSolid />
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem value={10}>Enquiries</MenuItem>
                    <MenuItem value={20}>Sales</MenuItem>
                    <MenuItem value={30}>Press</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  // value={formData.description}
                  id="outlined-multiline-static"
                  placeholder="Comment"
                  multiline
                  rows={6}
                  className="TextAreas"
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => handleInputChange(e, "comment")}
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      fontSize: "14px",
                      marginTop: 2,
                      width: "100%",
                      color: "#767676",
                    },
                  }}
                  sx={{
                    width: "100%",
                    marginTop: 2,
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    fontSize: "14px",
                  }}
                />
                <FormGroup
                  sx={{
                    // border: "1px solid yellow",

                    textAlign: "left",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        sx={{
                          color: "#0b1e5b",
                          "&.Mui-checked": {
                            color: "#0b1e5b ",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        component="p"
                        sx={{
                          margin: "18px 0",
                          color: "#767676",
                          // display: "flex",
                          fontWeight: "400",
                          alignItems: "center",
                          // fontSize: "14px",
                          fontSize: {
                            xs: "11.5px",
                            lg: "14px",
                          },
                          // gap: 2,
                        }}
                      >
                        I Agree to the
                        <Typography
                          component="a"
                          href="/#/terms-and-conditions"
                          onClick={() => {
                            moveToTop();
                          }}
                          sx={{
                            color: "#9F8BF0",
                            textDecoration: "none",
                            marginLeft: 1,
                            marginRight: 1,
                            fontSize: {
                              xs: "11.5px",
                              lg: "14px",
                            },
                          }}
                        >
                          Terms & Conditions
                        </Typography>
                        and
                        <Typography
                          component="a"
                          href="/#/privacy-policy"
                          onClick={() => {
                            moveToTop();
                          }}
                          sx={{
                            color: "#9F8BF0",
                            textDecoration: "none",
                            marginLeft: 1,
                            fontSize: {
                              xs: "11.5px",
                              lg: "14px",
                            },
                          }}
                        >
                          Privacy Policy
                        </Typography>
                      </Typography>
                    }
                  />
                </FormGroup>
                <SharedButton
                  value={"Get In Touch"}
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{
                    fontFamily: "Inter, sans-serif",
                    textTransform: "capitalize",
                  }}
                  fullWidth
                  className="ConnectWalletBTN"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};
