import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  FormGroup,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import { Container, Grid, Typography } from "@mui/material";
import SharedButton from "../../SharedComponent/Button/SharedButton";

const InstallNumbers = (props: any) => {
  const { ref1, ref2 } = props;
  return (
    <Box>
      {/* comp1 */}
      <Box
        sx={{
          fontFamily: "Inter",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "12px",
          marginBottom: {
            xs: "130px",
            sm: "42px",
          },
        }}
      >
        {/* number */}
        <Box
          sx={{
            borderRadius: "100%",
            width: {
              xs: "43px",
              sm: "63px",
              md: "83px",
            },
            height: { xs: "43px", sm: "63px", md: "83px" },
            border: "4px solid #9E9E9E",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "#9E9E9E",
              fontSize: {
                xs: "30px",
                sm: "48px",
              },
              fontWeight: "500",
            }}
          >
            1
          </Typography>
        </Box>
        {/* line */}
        <Box
          sx={{
            width: "6px",
            height: "163px",
            background: "#E0E0E0",
            borderRadius: "56px",
          }}
        >
          {/* inside */}
          <Box
            ref={ref1}
            sx={{
              width: "100%",
              height: "0%",
              background: "#9E9E9E",
              borderRadius: "56px",
            }}
          ></Box>
        </Box>
      </Box>

      {/* comp2 */}
      <Box
        sx={{
          fontFamily: "Inter",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "12px",
          marginBottom: {
            xs: "48px",
            sm: "28px",
          },
        }}
      >
        {/* number */}
        <Box
          sx={{
            borderRadius: "100%",
            width: {
              xs: "43px",
              sm: "63px",
              md: "83px",
            },
            height: { xs: "43px", sm: "63px", md: "83px" },
            border: "4px solid #9E9E9E",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "#9E9E9E",
              fontSize: {
                xs: "30px",
                sm: "48px",
              },
              fontWeight: "500",
            }}
          >
            2
          </Typography>
        </Box>
        {/* line */}
        <Box
          sx={{
            width: "6px",
            height: "163px",
            background: "#E0E0E0",
            borderRadius: "56px",
          }}
        >
          {/* inside */}
          <Box
            ref={ref2}
            sx={{
              height: "0%",
              width: "100%",
              background: "#9E9E9E",
              borderRadius: "56px",
            }}
          ></Box>
        </Box>
      </Box>

      {/* comp3*/}
      <Box
        sx={{
          fontFamily: "Inter",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "12px",
          // marginBottom: {
          //   xs: "130px",
          //   sm: "42px",
          // },
        }}
      >
        {/* number */}
        <Box
          sx={{
            borderRadius: "100%",
            width: {
              xs: "43px",
              sm: "63px",
              md: "83px",
            },
            height: { xs: "43px", sm: "63px", md: "83px" },
            border: "4px solid #9E9E9E",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "#9E9E9E",
              fontSize: {
                xs: "30px",
                sm: "48px",
              },
              fontWeight: "500",
            }}
          >
            3
          </Typography>
        </Box>
        {/* line */}
      </Box>
    </Box>
  );
};

export default InstallNumbers;
