import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  FormGroup,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import { Container, Grid, Typography } from "@mui/material";
import SharedButton from "../../SharedComponent/Button/SharedButton";

// images
import hero_img from "../../Assets/assets/hero-image.png";
import hero_svg from "../../Assets/assets/hero-svg.png";
import hero_gif from "../../Assets/assets/qr_mov_gif.gif";

const UnlockProduct = (props: any) => {
  const { onClick } = props;
  return (
    <Box
      sx={{
        // border: "1px solid yellow",
        position: "relative",
        marginBottom: { xs: "0", sm: "50px", md: "100px", lg: "200px" },
        paddingX: "24px",
      }}
    >
      <Grid
        container
        spacing={0}
        sx={{
          alignItems: "start",
          marginTop: {
            xs: "60px",
            sm: "",
            md: "60px",
          },
          // border: "1px solid red",
          maxWidth: "1440px",
          marginX: "auto",
        }}
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            // border: "1px solid green",
            textAlign: "left",
            marginTop: {
              xs: "630px",
              md: "100px",
            },
          }}
        >
          <Grid
            item
            component={"div"}
            sx={
              {
                // border: "1px solid brown",
              }
            }
          >
            <Typography
              // textAlign={"left"}
              variant="h1"
              component={"h2"}
              sx={{
                fontFamily: "nexa",
                color: "#0B1E5B",

                fontSize: {
                  xs: "36px",
                  sm: "48px",
                  md: "4.5rem",
                },
                textAlign: {
                  xs: "center",
                  sm: "left",
                },
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "80%",
                },
                maxWidth: "563px",
                // border: "1px solid green",
              }}
            >
              Unlock Customer Trust
            </Typography>
            <Typography
              // textAlign={"left"}
              variant="body1"
              component={"h5"}
              marginTop={0}
              sx={{
                color: "#0B1E5B",
                width: {
                  xs: "100%",
                  sm: "75%",
                  md: "75%",
                },
                marginBottom: {
                  xs: "10px",
                },
                fontSize: { xs: "16px", sm: "18px", md: "20px" },
                textAlign: {
                  xs: "center",
                  sm: "left",
                },
                // border: "1px solid red",
              }}
            >
              Provide your customers with verifiable authenticity while
              supercharging your supply chain with PlyChain’s cutting-edge
              technology.
            </Typography>
            <SharedButton
              onClick={onClick}
              className="LandingBtnHover"
              value={"Login with Metamask"}
              variant="contained"
              startIcon={
                <img
                  src="./Assets/Image/Metamasklogo.png"
                  alt="Image"
                  style={{
                    width: "20px",
                    height: "20px",
                    margin: "0px 10px",
                  }}
                />
              }
              sx={{
                // border: "1px solid blue",
                // textAlign: "left",
                marginTop: {
                  xs: "30px",
                  md: 2,
                },
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "60%",
                },
                textTransform: "none",
                fontFamily: "Inter",
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: "400",

                marginBottom: {
                  xs: "20px",
                },
                maxWidth: "400px",
                marginX: "auto",
              }}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            // border: "1px solid green",
            position: "absolute",

            width: { xs: "90%", sm: "80%", md: "45%" },
            height: "500px",
            right: 0,
            // right: { xs: "0", xl: "1%" },
          }}
        >
          <Box
            sx={{
              // border: "1px solid brown",
              width: "100%",
              height: "100%",
              position: "relative",
              zIndex: "2",
            }}
          >
            <img
              src={hero_img}
              alt=""
              style={{
                width: "100%",
                objectFit: "cover",
                height: "100%",
                borderRadius: "24px 0 0 0",
              }}
            ></img>
          </Box>
          <Box
            sx={{
              // border: "1px solid black",
              position: "absolute",
              right: "0",
              top: "0",
              height: "100%",
              marginLeft: { xs: "5%", xl: "3%" },
              paddingTop: { xs: "5%", xl: "3%" },
              zIndex: "2",
              width: { xs: "95%", xl: "97%" },
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "12px 0 0 0",
              }}
              src={hero_gif}
              alt=""
            ></img>
          </Box>
          <Box
            sx={{
              // border: "1px solid green",
              position: "absolute",
              bottom: "-15%",
              left: "-10%",
              zIndex: "1",
              width: "70%",
            }}
          >
            <img
              src={hero_svg}
              alt=""
              style={{
                width: "100%",
              }}
            ></img>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default UnlockProduct;
