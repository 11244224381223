import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ResponsiveDrawer from "../../SharedComponent/Sidebar/ResponsiveDrawer";
import Header from "../../SharedComponent/Header/Header";
import { DashboardOverview } from "./DashboardOverview";
import { useNavigate } from "react-router-dom";
import ProductModel from "../../models/productModel";
import { getAllProducts } from "../../networking/networkCalls";
import {
  LinearProgress,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Tooltip,
  IconButton,
  TablePagination,
  TableHead,
  TableSortLabel,
  Toolbar,
  Typography,
} from "@mui/material";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { ChangeEvent } from "react";
import { visuallyHidden } from "@mui/utils";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";

interface Data {
  id: string;
  name: string;
  carbs: string;
  fat: number;
  protein: number;
  tooltip: string;
}

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Product Name",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Date Created",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "tooltip",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  tableHeading: string; // Add tableHeading prop here
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  tableHeading: string; // Define the tableHeading prop here
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, tableHeading } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
          textAlign="left"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
          textAlign="left"
        >
          {tableHeading}
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const Dashboard = () => {
  const [dense, setDense] = React.useState(false);
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(0);
  const [totalDataCount, setTotalDataCount] = React.useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = React.useState<number>(5);
  const [dataList, setDataList] = React.useState<ProductModel[]>([]);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!loading) {
      setLoading(true);
    }

    const getData = async () => {
      const res = await getAllProducts({ itemsPerPage, page: page + 1 });

      if (res) {
        if (res.status === 200) {
          const { data } = res.data;

          if (data.data) {
            if ((data.data as Array<any>).length > 0) {
              const dataList: ProductModel[] = [];
              for (const d of data.data as Array<any>) {
                dataList.push(new ProductModel(d));
              }

              setDataList(dataList);
            }
          }

          setTotalDataCount(data.totalDataCount);
        }
      }

      setLoading(false);
    };

    getData();
  }, [page, itemsPerPage]);

  const handleClick = (
    event: React.MouseEvent<unknown>,
    name: string,
    data: ProductModel
  ) => {
    navigate(`/productDetail?id=${data.id}&source=normal`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleDeleteClick = (event: React.MouseEvent<unknown>, id: string) => {
    // Implement your delete action here
    event.stopPropagation(); // Prevent row click event from firing
  };

  const handleChangeItemsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditClick = (
    event: React.MouseEvent<unknown>,
    data: ProductModel
  ) => {
    // Implement your edit action here
    event.stopPropagation(); // Prevent row click event from firing

    navigate("/updateProduct", {
      state: { mode: "UPDATE", data: JSON.stringify(data) },
    });
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {};

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = dataList.map((n) => n.generated_product_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <div className="sidebars">
              <ResponsiveDrawer />
            </div>
          </Grid>
          <Grid item xs={10}>
            <Header />
            <Box className={"MainBoxComponent"}>
              <DashboardOverview />
              {/* <EnhancedTable />
               */}
              <EnhancedTableToolbar
                numSelected={selected.length}
                tableHeading="Product"
              />
              {loading && <LinearProgress />}
              {!loading && (
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={"asc"}
                      orderBy={""}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={dataList.length}
                      tableHeading=""
                    />
                    <TableBody>
                      {dataList.map((row, index) => {
                        // @ts-ignore
                        const isItemSelected = isSelected(
                          row.generated_product_id
                        );
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            // @ts-ignore
                            onClick={(event) =>
                              handleClick(event, row.generated_product_id, row)
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.generated_product_id}
                            selected={isItemSelected}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.generated_product_id}
                            </TableCell>
                            <TableCell align="right">{row.name}</TableCell>
                            <TableCell align="right">
                              {row.quantity} {row.quantity_unit}
                            </TableCell>
                            <TableCell align="right">
                              {row.getFormattedCreatedAt()}
                            </TableCell>
                            <TableCell align="right">
                              <Box
                                className={
                                  row.status === "published"
                                    ? "statusCss"
                                    : "statusCssGrey"
                                }
                              >
                                {row.status}
                              </Box>
                            </TableCell>
                            <TableCell align="right">
                              <Tooltip title="Edit">
                                <IconButton
                                  aria-label="edit"
                                  onClick={(event) =>
                                    handleEditClick(event, row)
                                  }
                                >
                                  <AiOutlineEdit />
                                </IconButton>
                              </Tooltip>
                              {/* <Tooltip title="Delete">
                                <IconButton
                                  aria-label="delete"
                                  onClick={(event) =>
                                    handleDeleteClick(
                                      event,
                                      `${row.generated_product_id}`
                                    )
                                  }
                                >
                                  <AiOutlineDelete />
                                </IconButton>
                              </Tooltip> */}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              <TablePagination
                component="div"
                count={totalDataCount}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeItemsPerPage}
                page={totalDataCount <= 0 ? 0 : page}
                rowsPerPage={itemsPerPage}
                rowsPerPageOptions={[5, 10, 15, 20]}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
