import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ResponsiveAppBar from "../../SharedComponent/AppBar/ResponsiveAppBar";
import Footer from "./Footer";
import { InputField } from "../../SharedComponent/TextField/InputField";
import { LiaUserTieSolid, LiaMailBulkSolid } from "react-icons/lia";
import SharedButton from "../../SharedComponent/Button/SharedButton";
import { SelectChangeEvent } from "@mui/material/Select";
import MobileNumberInput from "../../SharedComponent/TextField/MobileNumberInput";
import { moveToTop } from "../../utils/utils";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const RequestDemo = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [age, setAge] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  const handlePhoneChange = (value: string, country: {}) => {
    setPhoneNumber(value);
  };

  return (
    <>
      <ResponsiveAppBar />
      <Box
        sx={{
          backgroundImage: `url('./Assets/Image/AboutUsBG.png')`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          color: "#000",
          paddingTop: 0,
          paddingRight: 3,
          paddingLeft: 3,
          paddingBottom: 2,
        }}
      >
        <Box
          sx={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="h1"
            variant="h3"
            color="#0b1e5b"
            sx={{
              fontFamily: "nexa",
              width: {
                xs: "100%",
                sm: "45%",
                md: "50%",
                // lg: "50%",
                xl: "40%",
              },
              fontSize: {
                xs: "28px",
                md: "36px",
                lg: "48px",
              },
              padding: {
                xs: "0",
              },
            }}
          >
            Discover the benefits PlyChain can offer you.
          </Typography>
          <Typography
            color="#0b1e5b"
            component="p"
            sx={{
              width: "50%",
              marginBottom: 1,
              marginTop: {
                sm: 1,
                md: 2,
                lg: 2,
              },
              fontSize: "14px",
            }}
          >
            Book A Demo
          </Typography>
          <Box
            sx={{
              mt: 1,
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
              },
            }}
          >
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid
                item
                sx={{
                  mt: 1,
                  width: {
                    xs: "100%",
                    sm: "400px",
                    md: "400px",
                    lg: "400px",
                  },
                }}
              >
                <InputField
                  type="text"
                  variant="outlined"
                  textfieldId="outlined-basic"
                  name="business_name"
                  placeholder="Name"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LiaUserTieSolid />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "8px",
                      padding: "4px 12px",
                      backgroundColor: "#fff",
                      color: "#767676",
                      fontSize: "14px",
                    },
                  }}
                />
                <InputField
                  type="text"
                  variant="outlined"
                  textfieldId="outlined-basic"
                  name="business_name"
                  placeholder="Business Email"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LiaMailBulkSolid />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "8px",
                      padding: "4px 12px",
                      backgroundColor: "#fff",
                      color: "#767676",
                      fontSize: "14px",
                    },
                  }}
                />
                <Box className={"MobileNumber"}>
                  <MobileNumberInput
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                  />
                </Box>

                <TextField
                  // value={formData.description}
                  id="outlined-multiline-static"
                  placeholder="Comment"
                  multiline
                  rows={6}
                  className="TextAreas"
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      fontSize: "14px",
                      marginTop: 0,
                      width: "100%",
                      color: "#767676",
                    },
                  }}
                  sx={{
                    width: "100%",
                    marginTop: 0,
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    fontSize: "14px",
                  }}
                />
                <FormGroup
                  sx={{
                    // border: "1px solid yellow",

                    textAlign: "left",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        sx={{
                          color: "#0b1e5b",
                          "&.Mui-checked": {
                            color: "#0b1e5b ",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        component="p"
                        sx={{
                          margin: "18px 0",
                          color: "#767676",
                          // display: "flex",
                          fontWeight: "400",
                          alignItems: "center",
                          // fontSize: "14px",
                          fontSize: {
                            xs: "11.5px",
                            lg: "14px",
                          },
                          // gap: 2,
                        }}
                      >
                        I Agree to the
                        <Typography
                          component="a"
                          href="/#/terms-and-conditions"
                          onClick={() => {
                            moveToTop();
                          }}
                          sx={{
                            color: "#9F8BF0",
                            textDecoration: "none",
                            marginLeft: 1,
                            marginRight: 1,
                            fontSize: {
                              xs: "11.5px",
                              lg: "14px",
                            },
                          }}
                        >
                          Terms & Conditions
                        </Typography>
                        and
                        <Typography
                          component="a"
                          href="/#/privacy-policy"
                          onClick={() => {
                            moveToTop();
                          }}
                          sx={{
                            color: "#9F8BF0",
                            textDecoration: "none",
                            marginLeft: 1,
                            fontSize: {
                              xs: "11.5px",
                              lg: "14px",
                            },
                          }}
                        >
                          Privacy Policy
                        </Typography>
                      </Typography>
                    }
                  />
                </FormGroup>
                <SharedButton
                  value={"Get In Touch"}
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{
                    fontFamily: "Inter, sans-serif",
                    textTransform: "capitalize",
                  }}
                  fullWidth
                  className="ConnectWalletBTN"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};
