// import * as React from 'react';
// import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
// import Grid from '@mui/material/Grid';
// import ResponsiveDrawer from '../../SharedComponent/Sidebar/ResponsiveDrawer';
// import Header from '../../SharedComponent/Header/Header';

// import EnhancedTable from '../../SharedComponent/DataTable/Table';
// import { DashboardOverview } from '../DashboardPages/DashboardOverview';
// import { ShipmentOverview } from './ShipmentOverview';

// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
// }));

// export const Shipments = () => {
//     return (
//         <>
//             <Box sx={{ flexGrow: 1 }}>
//                 <Grid container spacing={1}>
//                     <Grid item xs={2}>
//                         <ResponsiveDrawer />
//                     </Grid>
//                     <Grid item xs={10}>
//                         <Header />
//                         <Box className={"MainBoxComponent"}>
//                             <ShipmentOverview />
//                             <EnhancedTable />
//                         </Box>
//                     </Grid>

//                 </Grid>
//             </Box>
//         </>
//     )
// }

import * as React from "react";
import { alpha } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import ResponsiveDrawer from "../../SharedComponent/Sidebar/ResponsiveDrawer";
import Header from "../../SharedComponent/Header/Header";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Grid,
  LinearProgress,
  Alert,
  Snackbar,
  AlertColor,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { ChangeEvent, useEffect } from "react";
import { ShipmentModel } from "../../models/shipmentModel";
import { ShipmentOverview } from "./ShipmentOverview";
import { useNavigate } from "react-router-dom";
import {
  createBlockTransaction,
  deleteShipment,
  getAllShipments,
} from "../../networking/networkCalls";
import { sendTransaction } from "../../utils/web3";

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "source",
    numeric: false,
    disablePadding: false,
    label: "Source",
  },
  {
    id: "destination",
    numeric: true,
    disablePadding: false,
    label: "Destination",
  },
  {
    id: "productName",
    numeric: true,
    disablePadding: false,
    label: "Product Name",
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "dateCreated",
    numeric: false,
    disablePadding: false,
    label: "Date Created",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  tableHeading: string; // Add tableHeading prop here
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  tableHeading: string; // Define the tableHeading prop here
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, tableHeading } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
          textAlign="left"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
          textAlign="left"
        >
          {tableHeading}
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const Shipments = () => {
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [dense, setDense] = React.useState(false);

  const [loading, setLoading] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(0);
  const [totalDataCount, setTotalDataCount] = React.useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = React.useState<number>(5);
  const [dataList, setDataList] = React.useState<ShipmentModel[]>([]);

  const [snackBarState, setSnackBarState] = React.useState<{
    show: boolean;
    msg: string;
    severity: AlertColor;
  }>({
    show: false,
    msg: "",
    severity: "success",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }

    const getData = async () => {
      const res = await getAllShipments({ itemsPerPage, page: page + 1 });

      if (res) {
        if (res.status === 200) {
          const { data } = res.data;

          if (data.data) {
            if ((data.data as Array<any>).length > 0) {
              const dataList: ShipmentModel[] = [];
              for (const d of data.data as Array<any>) {
                dataList.push(new ShipmentModel(d));
              }

              setDataList(dataList);
            }
          }

          setTotalDataCount(data.totalDataCount);
        }
      }

      setLoading(false);
    };

    getData();
  }, [page, itemsPerPage]);

  const handleEditClick = (
    event: React.MouseEvent<unknown>,
    data: ShipmentModel
  ) => {
    // Implement your edit action here
    event.stopPropagation(); // Prevent row click event from firing

    navigate("/updateShipment", {
      state: { mode: "UPDATE", data: JSON.stringify(data) },
    });
  };

  const handleChangeItemsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {};

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = dataList.map((n) => n.generated_shipment_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    data: ShipmentModel
  ) => {
    // const selectedIndex = selected.indexOf(name);
    // let newSelected: readonly string[] = [];

    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, name);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1)
    //   );
    // }

    // setSelected(newSelected);

    navigate("/shipmentDetail", { state: data.id });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleDeleteClick = async (
    event: React.MouseEvent<unknown>,
    id: string
  ) => {
    // Implement your delete action here
    event.stopPropagation(); // Prevent row click event from firing

    setSnackBarState({
      show: true,
      msg: "Processing",
      severity: "info",
    });

    const res = await deleteShipment(parseInt(id));

    if (res) {
      if (res.status === 200) {
        const { logId, hashedData } = res.data.data;
        // send transaction on blockchain
        const { success, txReceipt, error } = await sendTransaction(hashedData);

        if (success === true) {
          // transaction was successfully sent to blockchain
          // hit createBlockTransaction API
          const trnxRes = await createBlockTransaction(logId, {
            hash_data: hashedData,
            trnx_data: txReceipt.toJSON(),
          });

          if (trnxRes) {
            if (trnxRes.status === 201) {
              setSnackBarState({
                show: true,
                msg: "Shipment has been deleted",
                severity: "success",
              });

              const prevData = [...dataList];

              const newData = prevData.filter((v, i) => v.id !== parseInt(id));
              setDataList(newData);
            } else {
              // createBlockTransaction API failed
              setSnackBarState({
                show: true,
                msg: trnxRes.data.msg,
                severity: "error",
              });
            }
          } else {
            // createBlockTransaction API failed
            setSnackBarState({
              show: true,
              msg: "Something went wrong, please try again later",
              severity: "error",
            });
          }
        } else {
          // transaction got failed on blockchain
          if (error && error.code && error.code === "INSUFFICIENT_FUND") {
            setSnackBarState({
              show: true,
              msg: error.msg,
              severity: "error",
            });
          } else {
            setSnackBarState({
              show: true,
              msg: "Something went wrong, please try again later",
              severity: "error",
            });
          }
        }
      } else {
        // createProduct API failed
        setSnackBarState({
          show: true,
          msg: res.data.msg,
          severity: "error",
        });
      }
    } else {
      // createProduct API failed
      setSnackBarState({
        show: true,
        msg: "Something went wrong, please try again later",
        severity: "error",
      });
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarState({ ...snackBarState, show: false });
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Snackbar
          open={snackBarState.show}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity={snackBarState.severity}
            sx={{ width: "100%" }}
          >
            {snackBarState.msg}
          </Alert>
        </Snackbar>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <ResponsiveDrawer />
          </Grid>
          <Grid item xs={10}>
            <Header />
            <Box className={"MainBoxComponent"}>
              <ShipmentOverview />
              <Box sx={{ width: "100%" }}>
                <Paper sx={{ width: "100%", mb: 2 }}>
                  <EnhancedTableToolbar
                    numSelected={selected.length}
                    tableHeading="Shipments"
                  />
                  {loading && <LinearProgress />}
                  {!loading && (
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                      >
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={"asc"}
                          orderBy={""}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={dataList.length}
                          tableHeading=""
                        />
                        <TableBody>
                          {dataList.map((row, index) => {
                            // @ts-ignore
                            const isItemSelected = isSelected(row.productId);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                // @ts-ignore
                                onClick={(event) => handleClick(event, row)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                                sx={{ cursor: "pointer" }}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  {row.generated_shipment_id}
                                </TableCell>
                                <TableCell align="right">
                                  {row.User.name}
                                </TableCell>
                                <TableCell align="right">
                                  {row.recipient_company_name}
                                </TableCell>
                                <TableCell align="right">
                                  {row.Product?.name}
                                </TableCell>
                                <TableCell align="right">
                                  {row.quantity} {row.quantity_unit}
                                </TableCell>
                                <TableCell align="right">
                                  {row.getFormattedCreatedAt()}
                                </TableCell>
                                <TableCell align="right">
                                  <Box
                                    className={
                                      row.status === "published"
                                        ? "statusCss"
                                        : "statusCssGrey"
                                    }
                                  >
                                    {row.status}
                                  </Box>
                                </TableCell>
                                <TableCell align="right">
                                  <Tooltip title="Edit">
                                    <IconButton
                                      aria-label="edit"
                                      onClick={(event) =>
                                        handleEditClick(event, row)
                                      }
                                    >
                                      <AiOutlineEdit />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Delete">
                                    <IconButton
                                      aria-label="delete"
                                      onClick={(event) =>
                                        handleDeleteClick(event, `${row.id}`)
                                      }
                                    >
                                      <AiOutlineDelete />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  <TablePagination
                    component="div"
                    count={totalDataCount}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeItemsPerPage}
                    page={totalDataCount <= 0 ? 0 : page}
                    rowsPerPage={itemsPerPage}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                  />
                </Paper>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
