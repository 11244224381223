import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import SharedButton from "../../SharedComponent/Button/SharedButton";
import {
  Alert,
  AlertColor,
  CircularProgress,
  Grid,
  Snackbar,
} from "@mui/material";
import { InputField } from "../../SharedComponent/TextField/InputField";
import CheckpointModel from "../../models/checkpointModel";
import {
  createBlockTransaction,
  createCheckpoint,
} from "../../networking/networkCalls";
import { sendTransaction } from "../../utils/web3";
import { TrnxType } from "../../constants/constants";
import { useState } from "react";
import { convertFrom24HrsTo12Hrs } from "../../utils/utils";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface ShipmentCheckPointProps {
  checkpointCallback: (
    value: CheckpointModel | undefined,
    alertData: {
      show: boolean;
      msg: string;
      severity: AlertColor;
    }
  ) => void;
  id: number;
}

export default function ShipmentCheckPoint(props: ShipmentCheckPointProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<{
    shipment_id: number;
    address: string;
    country: string;
    city: string;
    date: string;
    time: string;
  }>({
    shipment_id: props.id,
    address: "",
    country: "",
    city: "",
    date: "",
    time: "",
  });

  const [snackBarState, setSnackBarState] = useState<{
    show: boolean;
    msg: string;
    severity: AlertColor;
  }>({
    show: false,
    msg: "",
    severity: "success",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const hitAPI = async () => {
    const res = await createCheckpoint(formData);

    if (res) {
      if (res.status === 201) {
        const { logId, hashedData } = res.data.data;
        // const createdData: CheckpointModel = new CheckpointModel(data);
        // send transaction on blockchain
        const { success, txReceipt, error } = await sendTransaction(hashedData);

        if (success === true) {
          // transaction was successfully sent to blockchain
          // hit createBlockTransaction API
          const trnxRes = await createBlockTransaction(logId, {
            hash_data: hashedData,
            trnx_data: txReceipt.toJSON(),
          });

          if (trnxRes) {
            if (trnxRes.status === 201) {
              const { data } = trnxRes.data;

              const createdData: CheckpointModel = new CheckpointModel(data);
              props.checkpointCallback(createdData, {
                show: true,
                msg: "Checkpoint has been created",
                severity: "success",
              });

              handleClose();
            } else {
              // createBlockTransaction API failed
              props.checkpointCallback(undefined, {
                show: true,
                msg: trnxRes.data.msg,
                severity: "error",
              });
            }
          } else {
            // createBlockTransaction API failed
            props.checkpointCallback(undefined, {
              show: true,
              msg: "Something went wrong, please try again later",
              severity: "error",
            });
          }
        } else {
          // transaction got failed on blockchain
          if (error && error.code && error.code === "INSUFFICIENT_FUND") {
            props.checkpointCallback(undefined, {
              show: true,
              msg: error.msg,
              severity: "error",
            });
          } else {
            props.checkpointCallback(undefined, {
              show: true,
              msg: "Something went wrong, please try again later",
              severity: "error",
            });
          }
        }
      } else {
        // createShipment API failed
        props.checkpointCallback(undefined, {
          show: true,
          msg: res.data.msg,
          severity: "error",
        });
      }
    } else {
      // createShipment API failed
      props.checkpointCallback(undefined, {
        show: true,
        msg: "Something went wrong, please try again later",
        severity: "error",
      });
    }
  };

  const onSaveHandler = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (
      formData.country.trim() !== "" &&
      formData.address.trim() !== "" &&
      formData.city.trim() !== "" &&
      formData.date.trim() !== "" &&
      formData.time.trim() !== ""
    ) {
      setLoading(true);

      await hitAPI();

      setLoading(false);
    } else {
      setSnackBarState({
        show: true,
        msg: "All the fields are required",
        severity: "error",
      });
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    const data = { ...formData };

    if (field === "time") {
      data[`${field}`] = convertFrom24HrsTo12Hrs(e.target.value);
    } else {
      data[`${field}`] = e.target.value;
    }

    setFormData(data);
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarState({ ...snackBarState, show: false });
  };

  return (
    <div>
      <Snackbar
        open={snackBarState.show}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackBarState.severity}
          sx={{ width: "100%" }}
        >
          {snackBarState.msg}
        </Alert>
      </Snackbar>
      <SharedButton
        onClick={handleOpen}
        value={"Update Checkpoints"}
        className="purpleOutlinedButton"
        variant="outlined"
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={"CheckPointModal"} width={"50%"} margin={"120px auto"}>
          <Grid container>
            <Grid item xs>
              <Typography textAlign={"left"} variant="h6" component={"h6"}>
                Update Checkpoint
              </Typography>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <InputField
                type="text"
                label={"Address"}
                placeholder="Address"
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => handleInputChange(e, "address")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <InputField
                type="text"
                label={"Country"}
                placeholder="Country"
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => handleInputChange(e, "country")}
              />
            </Grid>
            <Grid item xs>
              <InputField
                type="text"
                label={"City"}
                placeholder="Select City"
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => handleInputChange(e, "city")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <InputField
                type="date"
                label={"Checkpoint Date"}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => handleInputChange(e, "date")}
              />
            </Grid>
            <Grid item xs>
              <InputField
                type="time"
                label={"Checkpoint Time"}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => handleInputChange(e, "time")}
              />
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button color="inherit" sx={{ mr: 1 }} onClick={handleClose}>
              Back
            </Button>
            {loading && <CircularProgress></CircularProgress>}
            {!loading && (
              <Button
                variant="contained"
                className="Primary-button"
                onClick={onSaveHandler}
              >
                Save
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
