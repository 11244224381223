import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MetaMaskProvider } from "@metamask/sdk-react";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter, HashRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <MetaMaskProvider
          debug={true}
          sdkOptions={{
            checkInstallationOnAllCalls: true,
            extensionOnly: false,
            logging: {
              developerMode: true,
              sdk: true,
              plaintext: true,
            },
            communicationServerUrl: process.env.REACT_APP_COMM_SERVER_URL,
            checkInstallationImmediately: false, // This will automatically connect to MetaMask on page load
            dappMetadata: {
              name: "PlyChain",
              url: window.location.host,
            },
          }}
        >
          <App />
        </MetaMaskProvider>
      </HashRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
