import { productConditionType, statusType } from "../constants/constants";
import { AuthModel } from "./authModel";
import BlockchainTransactionModel from "./blockTransactionModel";
import { ShipmentModel } from "./shipmentModel";
import moment from "moment";

export default class ProductModel {
  id: number;
  user_id: number;
  generated_product_id: string;
  name: string | null;
  quantity: number | null;
  quantity_unit: string | null;
  condition: productConditionType | null;
  description: string | null;
  product_story: string | null;
  reuse_recycle_info: string | null;
  origin_country: string | null;
  origin_address: string | null;
  sku: string | null;
  weight: number | null;
  weight_unit: string | null;
  color: string | null;
  length: number | null;
  length_unit: string | null;
  width: number | null;
  width_unit: string | null;
  height: number | null;
  height_unit: string | null;
  thumbnails_url: string[];
  hash_data: string;
  status: statusType;
  createdAt: Date;
  updatedAt: Date;
  User: AuthModel | null;
  Shipment: ShipmentModel[];
  BlockchainTransaction: BlockchainTransactionModel[];

  constructor(data: Partial<ProductModel> = {}) {
    Object.assign(this, data);

    this.Shipment = [];

    if (Object.hasOwn(data, "Shipment") && data.Shipment.length > 0) {
      const shipmentData = data.Shipment as Array<any>;

      for (const d of shipmentData) {
        this.Shipment.push(new ShipmentModel(d));
      }
    }
  }

  getFormattedCreatedAt = () => {
    return moment(this.createdAt).format("DD MMM YYYY hh:mm a");
  };

  getFormattedCreatedDate = () => {
    return moment(this.createdAt).format("DD MMM YYYY");
  };

  getFormattedCreatedTime = () => {
    return moment(this.createdAt).format("hh:mm a");
  };
}
