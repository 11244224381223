import React, { useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  FormGroup,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import { Container, Grid, Typography } from "@mui/material";
import SharedButton from "../../SharedComponent/Button/SharedButton";
import GifComp from "./GifComp";
const UnlockTransparency = (props: any) => {
  const { onClick } = props;
  const ref = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  const scroll = useRef<HTMLDivElement>(null);
  const [dark, setDark] = useState(false);
  // var getBoundingClientRec

  // var top = ref.current?.getBoundingClientRect();
  var top = ref.current ? ref.current.offsetTop : 5554;

  // var rec = ref.current?.getBoundingClientRect();

  window.addEventListener("scroll", (e) => {
    // console.log("top-G", top);
    // console.log("rec", rec);
    console.log("scroll y", window.scrollY);
    if (top && scroll.current && ref.current) {
      let percentage = ((window.scrollY - top) / window.innerHeight) * 100;
      percentage = percentage < 0 ? 0 : percentage > 35 ? 35 : percentage;
      // console.log("percentage", percentage);

      if (window.screen.width > 900) {
        scroll.current.style.transform = `translate3d(${-percentage}vw,0,0)`;
      }

      if (window.scrollY > top && window.scrollY < 6600) {
        // console.log("scroll y greater than top");
        ref.current.style.background = "#262332";
        setDark(true);
      } else if (window.scrollY > 10600) {
        // console.log("scroll y greater than bottom");
        ref.current.style.background = "none";
        setDark(false);
      } else {
        ref.current.style.background = "none";
        setDark(false);
      }
    }
  });

  return (
    <Box
      className="sticky_parent"
      sx={{
        // border: "1px solid brown",
        marginTop: { xs: "150px", md: "100px" },
        paddingX: { xs: "16px", md: "0" },
        paddingY: { xs: "50px", md: "100px" },
      }}
      ref={ref}
    >
      <Box
        className={"sticky no_scroll_bar "}
        sx={{
          // border: "1px solid blue",
          overflowX: { xs: "hidden", md: "auto" },
        }}
      >
        <Box
          className={"scroll_section"}
          sx={
            {
              // border: "1px solid black",
            }
          }
          ref={scroll}
        >
          <Grid
            item
            className=""
            sx={{
              // border: "1px solid yellow",

              marginBottom: { xs: "20px", md: "50px", lg: "0" },
            }}
          >
            <Typography
              variant="h2"
              component={"h2"}
              sx={{
                fontFamily: "Nexa",
                // border: "1px solid green",
                color: dark ? "white" : "#0B1E5B",
                textAlign: {
                  xs: "center",
                },

                fontWeight: "bold",
                fontSize: {
                  xs: "1.5rem",
                  sm: "3rem",
                  md: "3.75rem",
                },
                maxWidth: "1000px",
                marginX: "auto",
                paddingX: "24px",
              }}
            >
              Unlock the Power of Transparency
            </Typography>
          </Grid>
          <Box
            // ref={ref}
            sx={{
              // border: "1px solid green",
              // overflowX: "auto",
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              rowGap: "36px",
              alignItems: "center",
            }}
          >
            <Grid
              sx={{
                // border: "1px solid orange",
                textAlign: { xs: "center", md: "left" },

                width: { xs: "100%", md: "30%" },
              }}
            >
              <ButtonBase
                sx={{
                  // border: "1px solid green",
                  width: { xs: "95%", sm: "50%", md: "95%" },
                  maxWidth: { xs: "550px", xl: "700px" },
                  // minHeight: { lg: "396px" },
                }}
              >
                <img
                  src="./Assets/Image/LandingImage04.png"
                  alt=""
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    height: "100%",
                  }}
                />
              </ButtonBase>
            </Grid>
            <Grid
              sx={{
                // border: "1px solid yellow",

                width: { xs: "100%", md: "70%" },
                textAlign: { xs: "center", md: "left" },
                // paddingX: {
                //   xs: "24px",
                //   md: "0",
                // },
              }}
            >
              <Typography
                // textAlign={"left"}
                variant="body1"
                component={"h5"}
                sx={{
                  color: dark ? "white" : "#0B1E5B",
                  width: {
                    xs: "100%",
                    sm: "65%",
                    md: "65%",
                  },
                  maxWidth: "600px",
                  textAlign: { xs: "center", md: "left" },
                  marginX: {
                    xs: "auto",
                    md: "0",
                  },
                  // border: "1px solid black",
                }}
              >
                Are you ready to transform your supply chain into a beacon of
                transparency and trust? Join PlyChain today and take the first
                step towards a brighter, more sustainable future.
              </Typography>
              <SharedButton
                onClick={onClick}
                className="LandingBtnHover"
                value={"Login with Metamask"}
                variant="contained"
                startIcon={
                  <img
                    src="./Assets/Image/Metamasklogo.png"
                    alt="Image"
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "0px 10px",
                    }}
                  />
                }
                sx={{
                  textAlign: { xs: "center", md: "left" },
                  marginTop: 5,
                  width: {
                    xs: "100%",
                  },
                  maxWidth: "400px",
                  // border: "1px solid blue",
                  marginX: {
                    xs: "auto",
                    md: "0",
                  },
                  fontFamily: "Inter",
                  fontSize: { xs: "14px", md: "16px" },
                  textTransform: "none",
                }}
              />
              <Box
                className="no_scroll_bar"
                sx={{
                  overflowX: { xs: "auto" },
                  width: { xs: "100%", md: "1500px" },
                  // border: "1px solid yellow",
                }}
              >
                <Box
                  // className={"dragSlider"}
                  sx={{
                    // border: "1px solid green",
                    display: "flex",
                    gap: "32px",
                    marginTop: "38px",
                    width: "1500px",
                  }}
                >
                  <GifComp
                    image={"./Assets/Image/Location.gif"}
                    word={"Product Traceability"}
                    dark={dark}
                  />
                  <GifComp
                    image={"./Assets/Image/Plant.gif"}
                    word={"Sustainability, ESG + Compliance"}
                    dark={dark}
                  />
                  <GifComp
                    image={"./Assets/Image/Magnet.gif"}
                    word={"Customer Engagement"}
                    dark={dark}
                  />
                  <GifComp
                    image={"./Assets/Image/Lock.gif"}
                    word={" Resale + New Models"}
                    dark={dark}
                  />

                  <GifComp
                    image={"./Assets/Image/Sale.gif"}
                    word={" Brand Protection"}
                    dark={dark}
                  />
                </Box>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UnlockTransparency;
