import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  IconButton,
  TextField,
  AlertColor,
  Snackbar,
  Alert,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import ResponsiveDrawer from "../../SharedComponent/Sidebar/ResponsiveDrawer";
import Header from "../../SharedComponent/Header/Header";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SharedButton from "../../SharedComponent/Button/SharedButton";
import { InputField } from "../../SharedComponent/TextField/InputField";
import RadioButtonsGroup from "../../SharedComponent/RadioButton/Radio";
import InputFileUpload from "../../SharedComponent/TextField/InputFileUpload";
import { BsCardImage } from "react-icons/bs";
import { AiFillCloseCircle, AiOutlineArrowLeft } from "react-icons/ai";
import { generateUniqueId } from "../../utils/utils";
import { useSelector } from "react-redux";
import { AuthModel } from "../../models/authModel";
import { sendTransaction } from "../../utils/web3";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import {
  EntityStatus,
  PRODUCT_DIMENSION_UNITS,
  PRODUCT_QUANTITY_UNITS,
  PRODUCT_WEIGHT_UNITS,
  productConditionType,
} from "../../constants/constants";
import {
  createBlockTransaction,
  createProduct,
  updateProduct,
} from "../../networking/networkCalls";
import ProductModel from "../../models/productModel";
import { AxiosResponse } from "axios";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const CreateUpdateProduct = () => {
  const location = useLocation();

  const [publishLoading, setPublishLoading] = useState<boolean>(false);
  const [draftLoading, setDraftLoading] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [thumbnailSlots, setThumbnailSlots] = useState(1); // Initial number of slots
  const [videoSlots, setvideoSlots] = useState(1); // Initial number of slots
  const [toBeUpdatedProduct] = useState<ProductModel>(
    JSON.parse(location.state?.data || "{}")
  );

  const [thumbnails, setThumbnails] = useState<any[][]>([
    [undefined, undefined, undefined, undefined],
  ]);

  const [formData, setFormData] = useState<{
    generated_product_id: string;
    name?: string | null;
    quantity?: number | null;
    quantity_unit?: string | null;
    condition?: productConditionType | null;
    description?: string | null;
    product_story?: string | null;
    reuse_recycle_info?: string | null;
    origin_country?: string | null;
    origin_address?: string | null;
    sku?: string | null;
    weight?: number | null;
    weight_unit?: string | null;
    color?: string | null;
    length?: number | null;
    length_unit?: string | null;
    width?: number | null;
    width_unit?: string | null;
    height?: number | null;
    height_unit?: string | null;
  }>({
    generated_product_id: "",
    name: "",
    quantity: null,
    quantity_unit: "",
    condition: "",
    description: "",
    product_story: "",
    reuse_recycle_info: "",
    sku: "",
    weight: null,
    weight_unit: "",
    color: "",
    length: null,
    length_unit: "",
    width: null,
    width_unit: "",
    height: null,
    height_unit: "",
  });

  const [snackBarState, setSnackBarState] = useState<{
    show: boolean;
    msg: string;
    severity: AlertColor;
  }>({
    show: false,
    msg: "",
    severity: "success",
  });

  const navigate: NavigateFunction = useNavigate();
  // @ts-ignore
  const authData: AuthModel = useSelector((state) => state.auth.authData);

  const isUpdateMode: boolean =
    location.state && location.state.mode && location.state.mode === "UPDATE";

  useEffect(() => {
    if (isUpdateMode) {
      // update mode
      const existignData = {
        generated_product_id: toBeUpdatedProduct.generated_product_id,
        name: toBeUpdatedProduct.name,
        quantity: toBeUpdatedProduct.quantity,
        quantity_unit: toBeUpdatedProduct.quantity_unit,
        condition: toBeUpdatedProduct.condition,
        description: toBeUpdatedProduct.description,
        product_story: toBeUpdatedProduct.product_story,
        reuse_recycle_info: toBeUpdatedProduct.reuse_recycle_info,
        origin_country: toBeUpdatedProduct.origin_country,
        origin_address: toBeUpdatedProduct.origin_address,
        sku: toBeUpdatedProduct.sku,
        weight: toBeUpdatedProduct.weight,
        weight_unit: toBeUpdatedProduct.weight_unit,
        color: toBeUpdatedProduct.color,
        length: toBeUpdatedProduct.length,
        length_unit: toBeUpdatedProduct.length_unit,
        width: toBeUpdatedProduct.width,
        width_unit: toBeUpdatedProduct.width_unit,
        height: toBeUpdatedProduct.height,
        height_unit: toBeUpdatedProduct.height_unit,
      };

      setFormData(existignData);
    } else {
      // create mode
      if (formData.generated_product_id === "") {
        const data = { ...formData };
        data.generated_product_id = generateUniqueId();
        setFormData(data);
      }
    }
  }, []);

  useEffect(() => {
    const chunkArray = (arr: string[], size: number): string[][] => {
      const chunkedArray: string[][] = [];
      for (let i = 0; i < arr.length; i += size) {
        chunkedArray.push(arr.slice(i, i + size));
      }
      return chunkedArray;
    };

    const setupThumbnail = async () => {
      if (isUpdateMode) {
        // update mode
        const thumbnailLength: number = Math.ceil(
          toBeUpdatedProduct.thumbnails_url.length / 4
        );

        setThumbnailSlots(thumbnailLength === 0 ? 1 : thumbnailLength);

        const thumbnailData: any[][] = chunkArray(
          toBeUpdatedProduct.thumbnails_url,
          4
        );

        if (thumbnailData.length !== 0) {
          setThumbnails(thumbnailData);
        }
      } else {
        // create mode
        const thumbnailData: any[][] = [...thumbnails];

        for (let i: number = 0; i < thumbnailSlots; i++) {
          if (thumbnailData[i] === undefined) {
            thumbnailData.push([undefined, undefined, undefined, undefined]);
          }
        }

        setThumbnails(thumbnailData);
      }
    };

    setupThumbnail();
  }, [thumbnailSlots]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleOnChangeProductCondition = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: productConditionType
  ) => {
    const data = { ...formData };

    data.condition = value;

    setFormData(data);
  };

  const productConditions: {
    value: productConditionType;
    label: string;
  }[] = [
    { value: "new", label: "New" },
    { value: "used", label: "Already Used" },
  ];

  const handleCopyClick = () => {
    navigator.clipboard.writeText(formData.generated_product_id);
  };

  const addThumbnailSlot = () => {
    setThumbnailSlots(thumbnailSlots + 1);
  };
  const addVideoSlot = () => {
    setvideoSlots(videoSlots + 1);
  };
  const handleGoBack = () => {
    window.history.back(); // This will navigate back to the previous page in the browser's history.
  };

  const onThumbnailFileChangeHandler = (
    e: React.SyntheticEvent<HTMLInputElement, Event>,
    rowIndex: number,
    itemIndex: number
  ) => {
    const target = e.target as HTMLInputElement;
    const selectedFile: File = (target.files as FileList)[0];

    if (selectedFile) {
      if (selectedFile.type.startsWith("image/")) {
        const data: any[][] = [...thumbnails];
        data[rowIndex][itemIndex] = selectedFile;
        setThumbnails(data);
      } else {
        setSnackBarState({
          show: true,
          msg: "Only image file is allowed as thumbnail",
          severity: "error",
        });
      }
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarState({ ...snackBarState, show: false });
  };

  const isFormDataValid: () => boolean = () => {
    for (const key in formData) {
      if (
        formData[key] === null ||
        formData[key] === undefined ||
        `${formData[key]}`.trim() === ""
      ) {
        return false;
      }
    }
    return true;
  };

  const onPublishHandler = async () => {
    if (!isFormDataValid()) {
      setSnackBarState({
        show: true,
        msg: "Please fill in all the required fields",
        severity: "error",
      });
      return;
    }

    const doesNotHaveThumbnail: boolean = thumbnails.every((v, i) => {
      return v.every((val, index) => {
        return val === undefined;
      });
    });

    if (doesNotHaveThumbnail) {
      setSnackBarState({
        show: true,
        msg: "Please select at least one thumbnail",
        severity: "error",
      });

      return;
    }

    setPublishLoading(true);

    const thumbnailFiles: any[] = [];

    // in case of update
    // the existing thumbnail url to be saved
    const existingThumbnail = [];

    for (let i: number = 0; i < thumbnails.length; i++) {
      for (let file of thumbnails[i]) {
        if (file !== undefined) {
          if (typeof file === "string") {
            // update mode
            existingThumbnail.push(file);
          } else {
            thumbnailFiles.push(file);
          }
        }
      }
    }

    const data: {
      user_id: number;
      generated_product_id: string;
      name?: string | null;
      quantity?: number | null;
      quantity_unit?: string | null;
      condition?: productConditionType | null;
      description?: string | null;
      product_story?: string | null;
      reuse_recycle_info?: string | null;
      origin_country?: string | null;
      origin_address?: string | null;
      sku?: string | null;
      weight?: number | null;
      weight_unit?: string | null;
      color?: string | null;
      length?: number | null;
      length_unit?: string | null;
      width?: number | null;
      width_unit?: string | null;
      height?: number | null;
      height_unit?: string | null;
      thumbnails?: File[];
      status: string;
      existing_thumbnails_url?: string;
    } = {
      user_id: authData.id,
      ...formData,
      thumbnails: thumbnailFiles,
      status: EntityStatus[EntityStatus.published],
    };

    if (isUpdateMode) {
      // update mode
      data.existing_thumbnails_url = existingThumbnail.join(",");
    }

    // hit createProduct API to create/update product on DB
    await hitAPI(data);

    setPublishLoading(false);
  };

  const onDraftHandler = async () => {
    setDraftLoading(true);

    const thumbnailFiles: any[] = [];
    // in case of update
    // the existing thumbnail url to be saved
    const existingThumbnail = [];

    for (let i: number = 0; i < thumbnails.length; i++) {
      for (let file of thumbnails[i]) {
        if (file !== undefined) {
          if (typeof file === "string") {
            // update mode
            existingThumbnail.push(file);
          } else {
            thumbnailFiles.push(file);
          }
        }
      }
    }

    const data: {
      user_id: number;
      generated_product_id: string;
      name?: string | null;
      quantity?: number | null;
      quantity_unit?: string | null;
      condition?: productConditionType | null;
      description?: string | null;
      product_story?: string | null;
      reuse_recycle_info?: string | null;
      origin_country?: string | null;
      origin_address?: string | null;
      sku?: string | null;
      weight?: number | null;
      weight_unit?: string | null;
      color?: string | null;
      length?: number | null;
      length_unit?: string | null;
      width?: number | null;
      width_unit?: string | null;
      height?: number | null;
      height_unit?: string | null;
      thumbnails?: File[];
      status: string;
      existing_thumbnails_url?: string;
    } = {
      user_id: authData.id,
      ...formData,
      thumbnails: thumbnailFiles,
      status: EntityStatus[EntityStatus.draft],
    };

    if (isUpdateMode) {
      // update mode
      data.existing_thumbnails_url = existingThumbnail.join(",");
    }

    // hit createProduct API to create/update product on DB
    await hitAPI(data);

    setDraftLoading(false);
  };

  const hitAPI = async (data) => {
    let res: AxiosResponse<any, any>;

    if (isUpdateMode) {
      // update mode
      res = await updateProduct(data, toBeUpdatedProduct.id);
    } else {
      // create mode
      res = await createProduct(data);
    }

    if (res) {
      if (res.status === 201) {
        const { logId, hashedData } = res.data.data;
        // send transaction on blockchain
        const { success, txReceipt, error } = await sendTransaction(hashedData);

        if (success === true) {
          // transaction was successfully sent to blockchain
          // hit createBlockTransaction API
          const trnxRes = await createBlockTransaction(logId, {
            hash_data: hashedData,
            trnx_data: txReceipt.toJSON(),
          });

          if (trnxRes) {
            if (trnxRes.status === 201) {
              setSnackBarState({
                show: true,
                msg:
                  data.status === "published"
                    ? isUpdateMode
                      ? "Product has been updated"
                      : "Product has been created"
                    : "Product has been saved as draft",
                severity: "success",
              });

              setTimeout(() => {
                navigate("/products", { replace: true });
              }, 2000);
            } else {
              // createBlockTransaction API failed
              setSnackBarState({
                show: true,
                msg: trnxRes.data.msg,
                severity: "error",
              });
            }
          } else {
            // createBlockTransaction API failed
            setSnackBarState({
              show: true,
              msg: "Something went wrong, please try again later",
              severity: "error",
            });
          }
        } else {
          // transaction got failed on blockchain
          if (error && error.code && error.code === "INSUFFICIENT_FUND") {
            setSnackBarState({
              show: true,
              msg: error.msg,
              severity: "error",
            });
          } else {
            setSnackBarState({
              show: true,
              msg: "Something went wrong, please try again later",
              severity: "error",
            });
          }
        }
      } else {
        // createProduct API failed
        setSnackBarState({
          show: true,
          msg: res.data.msg,
          severity: "error",
        });
      }
    } else {
      // createProduct API failed
      setSnackBarState({
        show: true,
        msg: "Something went wrong, please try again later",
        severity: "error",
      });
    }
  };

  const onRemoveThumbnailHandler = (e, rowIndex: number, itemIndex: number) => {
    const data: any[][] = [...thumbnails];

    const items: any[] = data[rowIndex];

    items[itemIndex] = undefined;
    data[rowIndex] = items;

    setThumbnails(data);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    const data = { ...formData };

    data[`${field}`] = e.target.value;

    setFormData(data);
  };

  const handleOnUnitChange = (e: SelectChangeEvent, field: string) => {
    const data = { ...formData };
    data[`${field}`] = e.target.value;

    setFormData(data);
  };

  const buildThumbnailsComponent = () => {
    return (
      <Box>
        {Array.from({ length: thumbnailSlots }).map((_, index) => (
          <Grid container spacing={1} key={index}>
            {Array.from({ length: 4 }).map((_, subIndex) => (
              <Grid item xs key={subIndex} textAlign="left">
                {thumbnails[index][subIndex] !== undefined && (
                  <Box className="ProductImg">
                    <AiFillCloseCircle
                      className="closeIcon"
                      onClick={(e) =>
                        onRemoveThumbnailHandler(e, index, subIndex)
                      }
                    />
                    <img
                      id={`${index}${subIndex}`}
                      src={
                        `${thumbnails[index][subIndex]}`.startsWith("http")
                          ? thumbnails[index][subIndex]
                          : URL.createObjectURL(thumbnails[index][subIndex])
                      }
                      alt=""
                    />
                  </Box>
                )}

                {thumbnails[index][subIndex] === undefined && (
                  <InputFileUpload
                    text="Upload image click here or Drag & Drop"
                    icon={<BsCardImage />}
                    className="FileUpload"
                    component="label"
                    variant="contained"
                    accept="image/*"
                    onChange={(e) =>
                      onThumbnailFileChangeHandler(e, index, subIndex)
                    }
                  />
                )}
              </Grid>
            ))}
          </Grid>
        ))}
        <Grid container spacing={0}>
          <Grid item xs>
            {/* <SharedButton
                                              title="Add More Thumbnail Slots"
                                              value="Add More Thumbnail Slots"
                                              variant="outlined"
                                              fullWidth
                                              startIcon={<AiOutlinePlusCircle />}
                                              sx={{
                                                  margin: '10px 0px',
                                                  cursor: 'pointer',
                                              }}
                                              onClick={addThumbnailSlot}
                                          /> */}
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Snackbar
          open={snackBarState.show}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity={snackBarState.severity}
            sx={{ width: "100%" }}
          >
            {snackBarState.msg}
          </Alert>
        </Snackbar>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <ResponsiveDrawer />
          </Grid>
          <Grid item xs={10}>
            <Header />
            <Box className={"MainBoxComponent"}>
              <Grid
                container
                spacing={0}
                sx={{
                  mb: 2,
                }}
              >
                <Grid item xs marginTop={"5px"}>
                  <Typography textAlign={"left"} variant="h4" component={"h3"}>
                    <IconButton onClick={() => handleGoBack()}>
                      <AiOutlineArrowLeft />
                    </IconButton>
                    {isUpdateMode ? "Edit Product" : "Create New Product"}
                  </Typography>
                </Grid>
                {/* <Grid item xs marginTop={"5px"}></Grid> */}
                <Grid item xs marginTop={"5px"}>
                  {draftLoading && <CircularProgress />}
                  {!draftLoading && (
                    <SharedButton
                      className="light-grey02"
                      title="Save as Draft"
                      value={"Save as Draft"}
                      variant="outlined"
                      onClick={onDraftHandler}
                    />
                  )}
                  {publishLoading && <CircularProgress />}
                  {!publishLoading && (
                    <SharedButton
                      className="light-grey01"
                      title="Save as Draft"
                      value={"Publish Product"}
                      variant="contained"
                      onClick={onPublishHandler}
                    />
                  )}
                </Grid>
              </Grid>
              <Box className={"ProductInfo"}>
                <Grid container spacing={0}>
                  <Grid item xs={8} marginTop={"5px"}>
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                      elevation={0}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="AccordionHeader"
                      >
                        <Typography component={"h6"} variant={"h5"}>
                          Product Information
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box>
                          <Grid container spacing={0}>
                            <Grid item xs>
                              <Typography
                                textAlign={"right"}
                                component={"p"}
                                variant={"body2"}
                                sx={{
                                  color: "darkgrey",
                                }}
                              >
                                0/19
                              </Typography>
                              <InputField
                                value={formData.name}
                                type="text"
                                label={"Product Name"}
                                placeholder="ex. Product Type - Product Material..."
                                onChange={(
                                  e: React.ChangeEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                  >
                                ) => handleInputChange(e, "name")}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <SharedButton
                                title="Copy"
                                className="purpleColor"
                                value={"Copy"}
                                onClick={handleCopyClick}
                              />
                              <InputField
                                value={formData.generated_product_id}
                                type="text"
                                label={"Product ID"}
                                placeholder="ex. Product ID"
                              />
                            </Grid>
                            <Grid item xs={6} sx={{}}>
                              {/* <SharedButton
                                title="Copy"
                                className="purpleColor"
                                value={"Copy"}
                                onClick={handleCopyClick}
                              /> */}
                              <Grid
                                container
                                spacing={1}
                                sx={{
                                  pt: 2.6,
                                }}
                              >
                                <Grid item xs>
                                  <Grid container spacing={0}>
                                    <Grid item xs={8}>
                                      <InputField
                                        value={formData.quantity}
                                        type="number"
                                        label={"Quantity"}
                                        placeholder="Enter Product stock"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start"></InputAdornment>
                                          ),
                                        }}
                                        onChange={(
                                          e: React.ChangeEvent<
                                            | HTMLInputElement
                                            | HTMLTextAreaElement
                                          >
                                        ) => handleInputChange(e, "quantity")}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      sx={{
                                        marginTop: "40px",
                                      }}
                                    >
                                      <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                          Unit
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select-unit"
                                          value={formData.quantity_unit}
                                          label={"Unit"}
                                          onChange={(e: SelectChangeEvent) =>
                                            handleOnUnitChange(
                                              e,
                                              "quantity_unit"
                                            )
                                          }
                                        >
                                          {PRODUCT_QUANTITY_UNITS.map(
                                            (v, i) => (
                                              <MenuItem key={v} value={v}>
                                                {v}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={true}
                      onChange={handleChange("panel2")}
                      elevation={0}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        className="AccordionHeader"
                      >
                        <Typography component={"h6"} variant="h6">
                          Product Details
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box>
                          <Grid container spacing={0}>
                            <Grid item xs textAlign={"left"}>
                              <RadioButtonsGroup
                                heading="Product Condition"
                                options={productConditions}
                                selectedProductCondition={formData.condition}
                                onChange={handleOnChangeProductCondition}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={0}>
                            <Grid
                              item
                              md={12}
                              xs
                              textAlign={"left"}
                              sx={{
                                mt: 1,
                                mb: 1,
                              }}
                            >
                              <TextField
                                value={formData.description}
                                id="outlined-multiline-static"
                                label="Product Description"
                                multiline
                                rows={4}
                                className="TextAreas"
                                sx={{
                                  width: "100%",
                                }}
                                onChange={(
                                  e: React.ChangeEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                  >
                                ) => handleInputChange(e, "description")}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={0}>
                            <Grid
                              item
                              md={12}
                              xs
                              textAlign={"left"}
                              sx={{
                                mt: 1,
                                mb: 1,
                              }}
                            >
                              <TextField
                                value={formData.product_story}
                                id="outlined-multiline-static"
                                label="Product Story"
                                placeholder="Describe how the product was sourced, materials or ingredients used, how the product was made, and the inspiration behind the product."
                                multiline
                                rows={4}
                                className="TextAreas"
                                sx={{
                                  width: "100%",
                                }}
                                onChange={(
                                  e: React.ChangeEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                  >
                                ) => handleInputChange(e, "product_story")}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={0}>
                            <Grid
                              item
                              md={12}
                              xs
                              textAlign={"left"}
                              sx={{
                                mt: 1,
                                mb: 1,
                              }}
                            >
                              <TextField
                                value={formData.reuse_recycle_info}
                                id="outlined-multiline-static"
                                label="Reuse or Recycle information"
                                multiline
                                rows={4}
                                className="TextAreas"
                                sx={{
                                  width: "100%",
                                }}
                                onChange={(
                                  e: React.ChangeEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                  >
                                ) => handleInputChange(e, "reuse_recycle_info")}
                              />
                            </Grid>
                          </Grid>
                          <InputField
                            value={formData.origin_country}
                            type="text"
                            label={"Country of Product Origin"}
                            onChange={(
                              e: React.ChangeEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >
                            ) => handleInputChange(e, "origin_country")}
                          />
                          <InputField
                            value={formData.origin_address}
                            type="text"
                            label={"Address of Product Origin"}
                            onChange={(
                              e: React.ChangeEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >
                            ) => handleInputChange(e, "origin_address")}
                          />
                          <Grid container spacing={1}>
                            <Grid item xs={4}>
                              <InputField
                                value={formData.sku}
                                type="text"
                                label={"Stock Keeping Unit Code (SKU)"}
                                placeholder="Enter SKU Code..."
                                onChange={(
                                  e: React.ChangeEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                  >
                                ) => handleInputChange(e, "sku")}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Grid container spacing={0}>
                                <Grid
                                  item
                                  xs={4}
                                  sx={{
                                    padding: 0,
                                    marginTop: "40px",
                                  }}
                                >
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Unit
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={formData.weight_unit}
                                      label={"Unit"}
                                      onChange={(e: SelectChangeEvent) =>
                                        handleOnUnitChange(e, "weight_unit")
                                      }
                                    >
                                      {PRODUCT_WEIGHT_UNITS.map((v, i) => (
                                        <MenuItem key={v} value={v}>
                                          {v}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8} marginTop={3}>
                                  <InputField
                                    value={formData.weight}
                                    type="number"
                                    placeholder="Enter Weight"
                                    onChange={(
                                      e: React.ChangeEvent<
                                        HTMLInputElement | HTMLTextAreaElement
                                      >
                                    ) => handleInputChange(e, "weight")}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={4}>
                              <InputField
                                value={formData.color}
                                type="text"
                                label={"Product Color"}
                                placeholder="Ex White, Black"
                                onChange={(
                                  e: React.ChangeEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                  >
                                ) => handleInputChange(e, "color")}
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={1}>
                            <Grid item xs>
                              <Typography
                                component={"p"}
                                variant="body2"
                                textAlign={"left"}
                              >
                                Product Dimension
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item xs={4}>
                              <Grid container spacing={0}>
                                <Grid
                                  item
                                  xs={4}
                                  sx={{
                                    marginTop: "17px",
                                  }}
                                >
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Unit
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={formData.length_unit}
                                      label={"Unit"}
                                      onChange={(e: SelectChangeEvent) =>
                                        handleOnUnitChange(e, "length_unit")
                                      }
                                    >
                                      {PRODUCT_DIMENSION_UNITS.map((v, i) => (
                                        <MenuItem key={v} value={v}>
                                          {v}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                  <InputField
                                    value={formData.length}
                                    type="number"
                                    placeholder="Length"
                                    onChange={(
                                      e: React.ChangeEvent<
                                        HTMLInputElement | HTMLTextAreaElement
                                      >
                                    ) => handleInputChange(e, "length")}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={4}>
                              <Grid container spacing={0}>
                                <Grid
                                  item
                                  xs={4}
                                  sx={{
                                    marginTop: "17px",
                                  }}
                                >
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Unit
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={formData.width_unit}
                                      label={"Unit"}
                                      onChange={(e: SelectChangeEvent) =>
                                        handleOnUnitChange(e, "width_unit")
                                      }
                                    >
                                      {PRODUCT_DIMENSION_UNITS.map((v, i) => (
                                        <MenuItem key={v} value={v}>
                                          {v}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                  <InputField
                                    value={formData.width}
                                    type="number"
                                    placeholder="Width"
                                    onChange={(
                                      e: React.ChangeEvent<
                                        HTMLInputElement | HTMLTextAreaElement
                                      >
                                    ) => handleInputChange(e, "width")}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={4}>
                              <Grid container spacing={0}>
                                <Grid
                                  item
                                  xs={4}
                                  sx={{
                                    marginTop: "17px",
                                  }}
                                >
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Unit
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={formData.height_unit}
                                      label={"Unit"}
                                      onChange={(e: SelectChangeEvent) =>
                                        handleOnUnitChange(e, "height_unit")
                                      }
                                    >
                                      {PRODUCT_DIMENSION_UNITS.map((v, i) => (
                                        <MenuItem key={v} value={v}>
                                          {v}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                  <InputField
                                    value={formData.height}
                                    type="number"
                                    placeholder="Height"
                                    onChange={(
                                      e: React.ChangeEvent<
                                        HTMLInputElement | HTMLTextAreaElement
                                      >
                                    ) => handleInputChange(e, "height")}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={true}
                      onChange={handleChange("panel2")}
                      elevation={0}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        className="AccordionHeader"
                      >
                        <Typography component={"h6"} variant="h6">
                          Product Media
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box>
                          <Grid container spacing={0}>
                            <Grid item xs textAlign={"left"}>
                              <Typography component={"h6"} variant="h6">
                                Product Thumbnails
                              </Typography>
                              <Typography component={"p"} variant="body2">
                                Upload image below with jpg or png format,
                                minimum 1000 x 1000 px (Square Dimension with
                                1:1 Ratio)
                              </Typography>
                            </Grid>
                          </Grid>
                          {buildThumbnailsComponent()}
                          {/* <Box>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs textAlign={"left"}>
                                                            <Typography component={"h6"} variant="h6">Product Video<span className="GreenBtn">option</span> </Typography>
                                                            <Typography component={"p"} variant="body2">Upload video below with mp4 or mov format, minimum 180 seconds (Max. 50mb video size)</Typography>
                                                        </Grid>
                                                    </Grid>

                                                        {Array.from({ length: videoSlots }).map((_, index) => (
                                                            <Grid container spacing={1} key={index}>
                                                                {Array.from({ length: 2 }).map((_, subIndex) => (
                                                                    <Grid item xs key={subIndex} textAlign="left">
                                                                        <InputFileUpload
                                                                            icon={<MdSlowMotionVideo />}
                                                                            className="FileUpload"
                                                                            component="label"
                                                                            variant="contained"
                                                                        />
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        ))}
                                                        <Grid container spacing={0}>
                                                            <Grid item xs>
                                                                <SharedButton
                                                                    title="Add More Video Slots"
                                                                    value="Add More Video Slots"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    startIcon={<AiOutlinePlusCircle />}
                                                                    sx={{
                                                                        margin: '10px 0px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={addVideoSlot}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                    </Box> */}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
