import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Grid, Paper, Typography } from "@mui/material";
import ResponsiveAppBar from "../../SharedComponent/AppBar/ResponsiveAppBar";
import Footer from "./Footer";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const AboutUs = () => {
  return (
    <>
      <ResponsiveAppBar />
      <Box
        sx={{
          backgroundImage: `url('./Assets/Image/AboutUsBG.png')`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          color: "#000",
          // border: "1px solid red",
          paddingX: "24px",
        }}
      >
        <Box
          sx={{
            paddingTop: { xs: "151px", md: "181px" },
            // paddingRight: 5,
            // paddingLeft: 5,
            paddingBottom: 2,
            maxWidth: "1440px",
            marginX: "auto",
            // border: "1px solid green",
          }}
        >
          <Grid container>
            <Grid item>
              <Typography
                textAlign="left"
                variant="h3"
                component={"p"}
                sx={{
                  color: "#0B1E5B",
                  padding: {
                    xs: "1",
                  },
                  fontSize: {
                    xs: "20px",

                    md: "24px",
                  },
                  marginBottom: {
                    xs: 2,
                    md: 4,
                  },
                }}
              >
                We are
              </Typography>
              <Typography
                textAlign="left"
                variant="h1"
                component={"p"}
                sx={{
                  fontFamily: "nexa",
                  color: "#0B1E5B",
                  padding: {
                    xs: "2",
                  },
                  marginTop: {
                    xs: 2,
                    sm: 1,
                    lg: 1,
                  },
                  fontSize: {
                    xs: "2.25rem",
                    md: "6rem",
                  },
                  lineHeight: 0.6,
                }}
              >
                Revolutionizing
              </Typography>
              <Typography
                textAlign="left"
                variant="h5"
                component={"h5"}
                sx={{
                  color: "#0B1E5B",
                  padding: {
                    xs: "2",
                  },
                  marginTop: {
                    xs: 2,
                    sm: 1,
                    lg: 1,
                  },
                  fontSize: {
                    xs: "20px",

                    md: "24px",
                  },
                }}
              >
                the supply chain management
              </Typography>
              <Typography
                textAlign="left"
                variant="h6"
                component={"p"}
                sx={{
                  color: "#0B1E5B",
                  padding: {
                    xs: "2",
                  },
                  marginTop: 2,
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "60%",
                  },
                  fontSize: {
                    xs: "16px",

                    md: "18px",
                  },
                }}
              >
                We understand the complexities and challenges that businesses
                face in today's global market. That's why we've developed a
                cutting-edge solution designed to bring transparency and trust
                to every step of your supply chain.
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              sx={{
                textAlign: "center",
                marginTop: 8,
                marginBottom: 8,
              }}
            >
              <Typography
                variant="h1"
                component={"p"}
                sx={{
                  color: "#0B1E5B",
                  padding: {
                    xs: "2",
                  },
                  fontFamily: "nexa",
                  fontSize: {
                    xs: "1.875rem",

                    md: "3rem",
                  },
                }}
              >
                Our Mission
              </Typography>
              <Typography
                variant="h6"
                component={"p"}
                sx={{
                  color: "#0B1E5B",
                  padding: {
                    xs: "2",
                  },
                  marginTop: 2,
                  fontSize: {
                    xs: "16px",

                    md: "18px",
                  },
                }}
              >
                At PlyChain, our mission is clear: to enable businesses like
                yours to operate with integrity and accountability. We believe
                that supply chain transparency is not just a trend; it's a
                necessity. With our blockchain-powered solution, you can gain
                real-time visibility into your supply chain, ensuring ethical
                sourcing practices and sustainability at every turn.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
};
