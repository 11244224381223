import { statusType } from "../constants/constants";
import { AuthModel } from "./authModel";
import ProductModel from "./productModel";
import BlockchainTransactionModel from "./blockTransactionModel";
import moment from "moment";
import CheckpointModel from "./checkpointModel";

export class ShipmentModel {
  id: number;
  user_id: number;
  generated_shipment_id: string;
  recipient_company_name: string | null;
  recipient_address: string | null;
  recipient_country: string | null;
  recipient_city: string | null;
  expected_arrival_date: string | null;
  expected_arrival_time: string | null;
  product_id: number | null;
  quantity: number | null;
  quantity_unit: string | null;
  weight: number | null;
  weight_unit: string | null;
  documents_url: string[];
  labels: string | null;
  further_information: string | null;
  hash_data: string;
  qr_code_url: string | null;
  status: statusType;
  is_deleted: boolean;
  createdAt: Date;
  updatedAt: Date;
  User: AuthModel | null;
  Product: ProductModel | null;
  checkpoints: CheckpointModel[];
  BlockchainTransaction: BlockchainTransactionModel[];

  constructor(data: Partial<ShipmentModel> = {}) {
    Object.assign(this, data);

    this.Product = null;
    this.checkpoints = [];

    if (Object.hasOwn(data, "checkpoints") && data.checkpoints.length > 0) {
      const checkpointData = data.checkpoints as Array<any>;

      for (const d of checkpointData) {
        this.checkpoints.push(new CheckpointModel(d));
      }
    }

    if (Object.hasOwn(data, "Product") && data.Product) {
      this.Product = new ProductModel(data.Product);
    }
  }

  getFormattedCreatedAt = () => {
    return moment(this.createdAt).format("DD MMM YYYY hh:mm a");
  };

  getFormattedCreatedDate = () => {
    return moment(this.createdAt).format("DD MMM YYYY");
  };

  getFormattedCreatedTime = () => {
    return moment(this.createdAt).format("hh:mm a");
  };
}
