import React, { useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Paper,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import ResponsiveDrawer from "../../SharedComponent/Sidebar/ResponsiveDrawer";
import Header from "../../SharedComponent/Header/Header";
import SharedButton from "../../SharedComponent/Button/SharedButton";
import ShipmentStepForm, {
  ShipmentStepFormRefHandler,
} from "./ShipmentStepForm";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useLocation } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const CreateUpdateShipment = () => {
  const [expanded, setExpanded] = useState<string | false>("panel1");

  const [draftLoading, setDraftLoading] = useState<boolean>(false);

  const shipmentStepFormRef = useRef<ShipmentStepFormRefHandler | null>(null);

  const location = useLocation();
  const isUpdateMode: boolean =
    location.state && location.state.mode && location.state.mode === "UPDATE";

  const onSaveDraftHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    shipmentStepFormRef.current?.onSaveDraftClicked(e);
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleGoBack = () => {
    window.history.back(); // This will navigate back to the previous page in the browser's history.
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <ResponsiveDrawer />
          </Grid>
          <Grid item xs={10}>
            <Header />
            <Box className={"MainBoxComponent"}>
              <Grid container spacing={0}>
                <Grid item xs={6} marginTop={"5px"}>
                  <Typography
                    fontWeight={600}
                    textAlign={"left"}
                    variant="h5"
                    component={"h6"}
                  >
                    <IconButton onClick={() => handleGoBack()}>
                      <AiOutlineArrowLeft />
                    </IconButton>
                    {isUpdateMode === true
                      ? "Edit Shipment"
                      : "Create New Shipment"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  marginTop={"5px"}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {draftLoading && <CircularProgress />}
                  {!draftLoading && (
                    <SharedButton
                      className="purpleOutlinedButton"
                      title="Save as Draft"
                      value={"Save as Draft"}
                      variant="outlined"
                      onClick={onSaveDraftHandler}
                    />
                  )}
                </Grid>
                <Grid item xs marginTop={"5px"}></Grid>
              </Grid>
              <Box className={"MainShipmentStepForm"}>
                <ShipmentStepForm
                  ref={shipmentStepFormRef}
                  setDraftLoading={setDraftLoading}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
