import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  FormGroup,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import { Container, Grid, Typography } from "@mui/material";

const GifComp = (props: any) => {
  const { image, word, dark } = props;
  return (
    <Box
      sx={{
        // border: "1px solid blue",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <Paper
        // elevation={0}
        className="dragSliderItem"
        sx={
          {
            // border: "1px solid red",
          }
        }
      >
        <img
          src={image}
          alt=""
          style={{
            width: "100%",
          }}
        />
      </Paper>
      <Typography
        textAlign={"left"}
        variant="h5"
        component={"p"}
        sx={{
          fontFamily: "Inter",
          color: dark ? "white" : "#0B1E5B",
          fontWeight: "600",
          textAlign: "left",
          fontSize: {
            xs: "18px",
            md: "20px",
          },
        }}
      >
        {word}
      </Typography>
    </Box>
  );
};

export default GifComp;
